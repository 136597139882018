import { useEffect, PropsWithChildren } from "react";
import { env } from "@/lib/utils";

export default function MonitoringProvider({ children }: PropsWithChildren) {
  useEffect(() => {
    if (env("VITE_ENVIRONMENT") !== "prod") return;
    (async () => {
      const Sentry = await import("@sentry/react");
      if (Sentry.isInitialized()) return;
      Sentry.init({
        dsn: env("VITE_SENTRY_DSN"),
        tunnel: `${env("VITE_API_BASE")}/api/sentryTunnel`,
        integrations: [
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
          Sentry.browserTracingIntegration(),
        ],
        // Session Replay
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 1.0,
      });
    })();
  }, []);
  return children;
}
