import styles from "./SelectRow.module.scss";

interface SelectRowProps {
  dark?: boolean;
  label: string;
  hideLabel?: boolean;
  name: string;
  value: string | number | boolean;
  options?: {
    value: string | number | boolean;
    label: string;
    default?: boolean;
  }[];
  disabled?: boolean;
  onChange: (value: string | number | boolean) => void;
}

export function SelectRow({
  dark,
  label,
  hideLabel,
  name,
  value,
  options,
  disabled,
  onChange,
}: SelectRowProps) {
  return (
    <fieldset disabled={disabled}>
      <legend className={hideLabel ? "sr-only" : ""}>{label}</legend>
      {options && (
        <div className={`${styles.container} ${dark && styles.dark}`}>
          {options.map((opt) => (
            <label
              key={opt.value.toString()}
              className={value === opt.value ? styles.selected : ""}
            >
              <input
                type="radio"
                name={name}
                value={
                  typeof opt.value === "boolean"
                    ? opt.value.toString()
                    : opt.value
                }
                checked={value === opt.value}
                defaultChecked={opt.default}
                onChange={(e) => {
                  e.preventDefault();
                  onChange(opt.value);
                }}
              />
              {opt.label}
            </label>
          ))}
        </div>
      )}
    </fieldset>
  );
}
