import {
  InputHTMLAttributes,
  ReactNode,
  KeyboardEvent,
  ChangeEvent,
  FocusEvent,
  MouseEventHandler,
  forwardRef,
  useRef,
  CSSProperties,
} from "react";
import "./../../globals.scss";
import styles from "./InputField.module.scss";
import { Icon } from "../Icon";
import { StatusText } from "../StatusText";

export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  autoComplete?: string;
  label?: string | ReactNode;
  appendLabel?: string | ReactNode;
  hideLabel?: boolean;
  value?: string;
  className?: string;
  customStyles?: {
    scrollContainer?: CSSProperties;
  };
  maxLength?: number;
  accept?: string;
  type?: string;
  icon?: string;
  prepend?: ReactNode;
  append?: React.ReactElement;
  placeholder?: string;
  disabled?: boolean;
  focus?: boolean;
  autoFocus?: boolean;
  large?: boolean;
  danger?: boolean;
  success?: boolean;
  dark?: boolean;
  required?: boolean;
  compact?: boolean;
  /**
   * Background color of the input field (any CSS color value)
   * WARNING: If "onClear" or "append" props are used, the color must be a HEX value
   */
  inputBgColor?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement, Element>) => void;
  dangerText?: string;
  successText?: string;
  infoText?: string;
}

export const InputField = forwardRef<HTMLDivElement, InputFieldProps>(
  function InputField(
    {
      autoComplete,
      label,
      appendLabel,
      hideLabel,
      value = "",
      className,
      customStyles,
      maxLength,
      type,
      icon,
      prepend,
      append,
      placeholder,
      disabled,
      focus,
      autoFocus,
      large,
      danger,
      success,
      dark,
      required,
      compact,
      accept,
      inputBgColor,
      onChange,
      onClear,
      onKeyDown,
      onKeyUp,
      onBlur,
      onFocus,
      dangerText,
      successText,
      infoText,
      id,
    }: InputFieldProps,
    ref,
  ) {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e);
    };

    const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (
        ((type === "number" && !/^[0-9]+$/.test(e.key)) ||
          (type === "tel" && !/^[0-9+()]+$/.test(e.key))) &&
        !(e.metaKey && e.key === "v") &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "ArrowLeft" &&
        e.key !== "ArrowRight" &&
        e.key !== "ArrowUp" &&
        e.key !== "ArrowDown" &&
        e.key !== "Tab" &&
        e.key !== "Spacebar"
      ) {
        e.preventDefault();
      }
      if (onKeyDown) onKeyDown(e);
    };

    const handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
      if (onKeyUp) onKeyUp(e);
    };

    const handleOnBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
      if (onBlur) onBlur(e);
    };

    const handleOnFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
      if (onFocus) onFocus(e);
    };

    const clearInputField: MouseEventHandler<HTMLButtonElement> = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onClear) onClear(e);
    };

    return (
      <div
        className={`
    ${styles.inputContainer} 
   
    ${dark ? styles.dark : ""}`}
      >
        {!!label && (
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "end",
              justifyContent: "flex-start",
            }}
          >
            <label
              htmlFor={`${label}_INPUT`}
              className={hideLabel || (compact && hideLabel) ? "sr-only" : ""}
            >
              {label}
              {required && typeof label === "string" && (
                <>
                  {" "}
                  <strong aria-hidden>*</strong>
                  <span className="sr-only">(required)</span>
                </>
              )}
            </label>
            {!!appendLabel && appendLabel}
          </div>
        )}
        <div
          ref={ref}
          className={`
        ${className ? className : ""} 
        ${styles.inputField} 
        ${focus ? styles.focus : ""}
        ${large ? styles.large : ""}
        ${danger || (dangerText && dangerText.length > 0) ? styles.danger : ""}
        ${
          success || (successText && successText.length > 0)
            ? styles.success
            : ""
        }
        ${dark ? styles.dark : ""}
        ${disabled ? styles.disabled : ""}`}
          {...(inputBgColor
            ? { style: { backgroundColor: inputBgColor } }
            : {})}
          onClick={() => inputRef.current?.focus()}
        >
          {icon && <Icon className={styles.icon} name={icon} />}
          <div
            className={styles.scrollContainer}
            style={customStyles?.scrollContainer}
          >
            {!!prepend && prepend}
            <input
              ref={inputRef}
              id={id || `${label}_INPUT`}
              autoComplete={autoComplete ? autoComplete : "on"}
              autoFocus={autoFocus}
              className={styles.input}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              maxLength={maxLength}
              required={required}
              value={value}
              accept={accept}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onKeyUp={handleOnKeyUp}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
            />
          </div>
          {(!!onClear || !!append) && (
            <div
              className={styles.appendContainer}
              {...(inputBgColor
                ? {
                    style: {
                      background: `linear-gradient(to right, ${inputBgColor}00, ${inputBgColor}FF, ${inputBgColor}FF, ${inputBgColor}FF)`,
                    },
                  }
                : {})}
            >
              {!!onClear && (
                <button
                  className={styles.clearBtn}
                  onClick={(e) => {
                    if (!value) return;
                    clearInputField(e);
                  }}
                  style={{
                    ...(value ? { display: "flex" } : { display: "none" }),
                    ...(dark ? { color: "white" } : {}),
                  }}
                >
                  <Icon name="close" />
                  <span className="sr-only">Clear</span>
                </button>
              )}
              {!!append && append}
            </div>
          )}
        </div>
        <div className={styles.bottomContainer}>
          {!dangerText && !successText && !infoText && !compact ? (
            <div style={{ height: "24px" }}></div>
          ) : dangerText ? (
            <StatusText text={dangerText} fail dark={dark} />
          ) : successText ? (
            <StatusText text={successText} success dark={dark} />
          ) : (
            !!infoText && <StatusText text={infoText} dark={dark} />
          )}
          {!!maxLength && (
            <span className={styles.maxLength}>
              {value ? value.length : 0} / {maxLength}
            </span>
          )}
        </div>
      </div>
    );
  },
);
