import styles from "@/styles/Bookings.module.scss";
import MeetingRoomCard from "./MeetingRoomCard";
import { useMeetingRooms } from "@/api";
import { LoadingSpinner } from "@app-components";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function AllRooms() {
  const { t } = useTranslation();
  const { data: meetingRooms, isLoading } = useMeetingRooms();

  return (
    <div className={styles.cardsGrid}>
      <AnimatePresence initial={false} mode="wait">
        {isLoading ? (
          <motion.div
            className="center"
            style={{ margin: "var(--wlcm-spacing-xl) 0" }}
            key="loading"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
          >
            <LoadingSpinner />
          </motion.div>
        ) : meetingRooms?.length ? (
          <motion.div
            style={{ display: "contents" }}
            key="rooms"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
          >
            {meetingRooms.map((room) => (
              <MeetingRoomCard key={room.id} room={room} />
            ))}
          </motion.div>
        ) : (
          <motion.div
            style={{
              margin: "var(--wlcm-spacing-xl) auto",
              textAlign: "center",
              gridColumn: "1 / -1",
            }}
            key="noRooms"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
          >
            <h3>{t("BOOKINGS.NO_ROOMS.HEADER")}</h3>
            <p>{t("BOOKINGS.NO_ROOMS.BODY")}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
