import { updateBuildingSettings, useBuilding, useBuildingAdmins } from "@/api";
import AddAdminModal from "@/components/AddAdminModal";
import Table from "@/components/table/Table.tsx";
import styles from "@/styles/BuildingSettings.module.scss";
import { Mode, ExtendedUser } from "@/types";
import { Button, Icon } from "@app-components";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getAuth } from "firebase/auth";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import BuildingAdminNotificationsSelector from "./BuildingAdminNotificationsSelector";

const columnHelper = createColumnHelper<ExtendedUser>();

export default function AdminsSection() {
  const { t } = useTranslation();
  const auth = getAuth();
  const user = auth.currentUser;
  const queryClient = useQueryClient();
  const { data: building } = useBuilding();
  const { data: buildingAdminsFull } = useBuildingAdmins(
    building?.buildingAdmins,
  );
  const [addAdminOpen, setAddAdminOpen] = useState(false);

  const tableColumns = useMemo(
    // memoize so columns don't need to be "re-rendered" when component changes
    () => [
      ...(building.accessMode === Mode.ENABLED
        ? [
            columnHelper.accessor((row) => row, {
              id: "buildingAdminNotifications",
              cell: (info) => (
                <BuildingAdminNotificationsSelector user={info.getValue()} />
              ),
              header: () => (
                <div className="sr-only">
                  {t("USERS.TABLE_HEADERS.BUILDING_ADMIN_NOTIFICATOINS")}
                </div>
              ),
            }),
          ]
        : []),
      columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
        id: "userName",
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.NAME")}</div>,
      }),
      columnHelper.accessor("email", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.EMAIL")}</div>,
      }),
      columnHelper.accessor("phone", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.PHONE")}</div>,
      }),
      columnHelper.accessor("buildingTenantName", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.COMPANY")}</div>,
      }),
    ],
    [],
  );
  const table = useReactTable({
    data: buildingAdminsFull || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <section style={{ display: "contents" }}>
        <div className={`${styles.fullWidth} ${styles.sectionWithButton}`}>
          <h2>{t("BUILDING_SETTINGS.ADMIN_SETTINGS")}</h2>
          <p className={styles.description}>
            {t("BUILDING_SETTINGS.ADMIN_INFO")}
          </p>
          <Button
            icon="add"
            onClick={(e) => {
              e.preventDefault();
              setAddAdminOpen(true);
            }}
          >
            {t("BUILDING_SETTINGS.ADD_ADMIN")}
          </Button>
        </div>
        <Table table={table} className={styles.fullWidth} />
        {building.accessMode === Mode.ENABLED && (
          <p className={`${styles.fullWidth} ${styles.descriptionText}`}>
            <span className="sr-only">notifications-bell-icon</span>
            <Icon
              name="notifications_filled"
              className={styles.descriptionTextIcon}
            />{" "}
            <span>=</span> {t("BUILDING_SETTINGS.NOTIFICATIONS.DESCRIPTION")}
          </p>
        )}
      </section>
      {/* createPortal is REQUIRED so the modal form is not rendered inside the <BuildingSettings /> form */}
      {addAdminOpen &&
        buildingAdminsFull &&
        createPortal(
          <AddAdminModal
            header={t("BUILDING_SETTINGS.ADD_ADMIN_MODAL.HEADER")}
            body={t("BUILDING_SETTINGS.ADD_ADMIN_MODAL.BODY")}
            noResultsText={t(
              "BUILDING_SETTINGS.ADD_ADMIN_MODAL.NO_RESULTS.BODY",
            )}
            existingAdmins={buildingAdminsFull}
            onClose={() => setAddAdminOpen(false)}
            onAdd={async (userToAdd) => {
              if (!user) throw new Error("User is unauthenticated");
              await updateBuildingSettings({
                buildingAdmins: [...building!.buildingAdmins, userToAdd.userId],
              });
              return queryClient.invalidateQueries({
                queryKey: ["building"],
              });
            }}
          />,
          document.querySelector("main") || document.body,
        )}
    </>
  );
}
