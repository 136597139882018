import { useUploadUsers } from "@/hooks/uploadUsers";
import { useContextStore } from "@/store/zustandStore";
import { Button, InputField, PopupCard, Toggle } from "@app-components";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useTranslation } from "react-i18next";
import styles from "@/styles/Users.module.scss";
import { NAME_REGEX, PHONE_REGEX } from "@/lib/constants";
import { z } from "zod";
import { checkExistingUser, useCompany } from "@/api";
import { Tooltip } from "react-tooltip";
import { useState } from "react";

export default function AddUserModal({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const { submitUsers } = useUploadUsers(onClose);
  const { selectedCompany } = useContextStore();
  const { data: company } = useCompany(selectedCompany?.value);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  if (!selectedCompany)
    throw new Error(
      "No company selected, you should not be able to get here without one",
    );

  const handleEmailBlur = async (email: string) => {
    setEmailError(null);
    setIsCheckingEmail(true);   
     try {
      const emailExists = await checkExistingUser(email);
      if (emailExists) {
        setEmailError(t("VALIDATION.EMAIL_USED"));
      }
    } catch (error) {
      console.error("Error checking email:", error);
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      customEmployeeId: "",
      visibleInVisit: true,
      shouldBeAdmin: false,
      sendEmail: true,
    },
    onSubmit: async ({ value }) => {
      const userToAdd = {
        firstName: value.firstName,
        lastName: value.lastName,
        buildingTenantId: selectedCompany.value,
        adminInCompanies: value.shouldBeAdmin ? [selectedCompany.value] : [],
        entranceIds: company?.entranceIds || [],
        email: value.email,
        phone: value.phone,
        customEmployeeId: value.customEmployeeId,
        visibleInVisit: value.visibleInVisit,
        sendEmail: value.sendEmail,
      };
      await submitUsers([userToAdd]);
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <PopupCard onClose={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <h3>{t("ADD_USER.ADD_USER")}</h3>
        <div className={styles.formGrid}>
          <form.Field
            name="firstName"
            validators={{
              onBlur: z
                .string()
                .min(
                  1,
                  t("VALIDATION.REQUIRED", { field: t("ADD_USER.FIRST_NAME") }),
                )
                .min(
                  2,
                  t("VALIDATION.MIN_LENGTH", {
                    field: t("ADD_USER.FIRST_NAME"),
                    length: 2,
                    type: t("CHARACTERS"),
                  }),
                )
                .max(
                  30,
                  t("VALIDATION.MAX_LENGTH", {
                    field: t("ADD_USER.FIRST_NAME"),
                    length: "30",
                    type: t("CHARACTERS"),
                  }),
                )
                .trim()
                .refine((value) => NAME_REGEX.test(value), {
                  message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                }),
            }}
          >
            {(field) => (
              <InputField
                autoFocus
                required
                dark
                label={t("ADD_USER.FIRST_NAME")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <form.Field
            name="lastName"
            validators={{
              onBlur: z
                .string()
                .min(
                  1,
                  t("VALIDATION.REQUIRED", { field: t("ADD_USER.LAST_NAME") }),
                )
                .min(
                  2,
                  t("VALIDATION.MIN_LENGTH", {
                    field: t("ADD_USER.LAST_NAME"),
                    length: 2,
                    type: t("CHARACTERS"),
                  }),
                )
                .max(
                  30,
                  t("VALIDATION.MAX_LENGTH", {
                    field: t("ADD_USER.LAST_NAME"),
                    length: "30",
                    type: t("CHARACTERS"),
                  }),
                )
                .trim()
                .refine((value) => NAME_REGEX.test(value), {
                  message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                }),
            }}
          >
            {(field) => (
              <InputField
                required
                dark
                label={t("ADD_USER.LAST_NAME")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>

          <form.Field
            name="email"
            validators={{
              onBlur: z
                .string()
                .min(
                  1,
                  t("VALIDATION.REQUIRED", { field: t("ADD_USER.EMAIL") }),
                )
                .email(t("VALIDATION.INVALID", { field: t("ADD_USER.EMAIL") }))
                .trim(),
            }}
          >
            {(field) => (
              <InputField
                required
                dark
                label={t("ADD_USER.EMAIL")}
                value={field.state.value}
                onBlur={() => handleEmailBlur(field.state.value)}
                onChange={(e) => {
                  setEmailError(null); // Clear error while typing
                  setIsCheckingEmail(true);
                  field.handleChange(e.target.value.toLowerCase());
                }}
                dangerText={emailError || field.state.meta.errors.toString()}
              />
            )}
          </form.Field>

          <form.Field
            name="phone"
            validators={{
              onBlur: z
                .string()
                .trim()
                .refine(
                  (value) => !value || (value && PHONE_REGEX.test(value)),
                  {
                    message: t("VALIDATION.INVALID_PHONE"),
                  },
                ),
            }}
          >
            {(field) => (
              <InputField
                dark
                type="tel"
                label={t("ADD_USER.PHONE")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onFocus={() => {
                  if (!field.state.value) field.handleChange("+47");
                }}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <form.Field
            name="customEmployeeId"
            validators={{
              onBlur: z.string().trim(),
            }}
          >
            {(field) => (
              <>
                <InputField
                  dark
                  className="custom-employee-id-input"
                  type="text"
                  label={t("ADD_USER.CUSTOM_EMPLOYEE_ID")}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                  dangerText={field.state.meta.errors.toString()}
                />
                <Tooltip
                  id="custom-employee-id-tooltip"
                  anchorSelect=".custom-employee-id-input"
                  content={t("ADD_USER.CUSTOM_EMPLOYEE_ID_DESCRIPTION")}
                  style={{ maxWidth: "300px" }}
                />
              </>
            )}
          </form.Field>
          {company.visitMode === "enabled" && (
            <form.Field name="visibleInVisit">
              {(field) => (
                <Toggle
                  title={t("ADD_USER.VISIBLE")}
                  checked={field.state.value}
                  dark
                  onChange={(checked) => field.handleChange(checked)}
                />
              )}
            </form.Field>
          )}
          <form.Field name="sendEmail">
            {(field) => (
              <>
                <Toggle
                  className="send-email-invite-toggle"
                  title={t("USERS.SEND_EMAIL")}
                  checked={field.state.value}
                  dark
                  onChange={(checked) => field.handleChange(checked)}
                />
                <Tooltip
                  anchorSelect=".send-email-invite-toggle"
                  content={t("USERS.SEND_EMAIL_OFF")}
                />
              </>
            )}
          </form.Field>
          <form.Field name="shouldBeAdmin">
            {(field) => (
              <Toggle
                title={t("ADD_USER.ADMIN", {
                  companyName: selectedCompany.label,
                })}
                checked={field.state.value}
                dark
                onChange={(checked) => field.handleChange(checked)}
              />
            )}
          </form.Field>
          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <Button
                type="submit"
                disabled={
                  isSubmitting || !canSubmit || !!emailError || isCheckingEmail
                }
                isLoading={isSubmitting}
              >
                {t("ADD_USER.SUBMIT")}
              </Button>
            )}
          />
        </div>
      </form>
    </PopupCard>
  );
}
