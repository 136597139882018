import { NotificationType, Timestamp, Visit, Visitor } from "@/types";
import { Button, PopupCard, TextEditor } from "@app-components";
import { useTranslation } from "react-i18next";
import styles from "@/styles/VisitorDetails.module.scss";
import { formatTimestampToHoursAndMinutes } from "@/lib/utils";
import { useState } from "react";
import { sendSMSMessage, updateVisitorsMessageHistory } from "@/api";
import { useSnackbarStore } from "@/store/zustandStore";
import { useQueryClient } from "@tanstack/react-query";
import { useLoggedInUser } from "@/providers/AuthProvider";

interface VisitorDetailsProps {
  visitor: Visitor;
  onClose: () => void;
  companyReception?: boolean;
}
const isVisit = (visitor: Visitor): visitor is Visit => {
  return "hostId" in visitor;
};

export default function VisitorDetails({
  visitor,
  onClose,
  companyReception,
}: VisitorDetailsProps) {
  const { t } = useTranslation();
  const [messageToVisitor, setMessageToVisitor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useSnackbarStore();
  const queryClient = useQueryClient();
  const curerntUser = useLoggedInUser();

  const getVisitorEvents = (visitor: Visitor) => {
    const events: {
      type: string;
      timestamp: Timestamp;
      details: string | JSX.Element;
    }[] = [];

    if (isVisit(visitor)) {
      // Add message history
      if (visitor.messageHistory) {
        events.push(
          ...visitor.messageHistory.map((message) => ({
            type: "messageHistory",
            timestamp: message.timestamp,
            details: (
              <>
                {t("VISIT_DETAILS.SMS_FROM_HOST")}{" "}
                {message.senderName ?? t("BUILDING_VISITORS.HOST")}:{" "}
                <i>{message.body}</i>
              </>
            ),
          })),
        );
      }

      // Add letInAt
      if (visitor.letInAt) {
        events.push({
          type: "letIn",
          timestamp: visitor.letInAt,
          details: t("VISIT_DETAILS.ACCESS_GRANTED"),
        });
      }
      // Add visit creation
      events.push({
        type: "createdAt",
        timestamp: visitor.createdAt,
        details: t("VISIT_DETAILS.VISIT_REQUEST"),
      });
    }

    // Add arrivedAt for preregistered visitors
    if (!isVisit(visitor) && visitor.arrivedAt) {
      events.push({
        type: "arrivedAt",
        timestamp: visitor.arrivedAt,
        details: t("VISIT_DETAILS.ACCESS_GRANTED"),
      });
    }

    // Sort events in reverse chronological order
    events.sort((a, b) => b.timestamp._seconds - a.timestamp._seconds);

    return events;
  };

  const visitorEvents = getVisitorEvents(visitor);

  const sendSms = async () => {
    setIsLoading(true);
    if (isVisit(visitor)) {
      const isCompanyVisit = visitor.hostId === "";
      try {
        await sendSMSMessage(visitor.visitorPhone, messageToVisitor);

        await updateVisitorsMessageHistory(
          visitor.id,
          curerntUser.userId,
          `${curerntUser.firstName} ${curerntUser.lastName}`,
          messageToVisitor,
          isCompanyVisit,
        );

        queryClient.invalidateQueries({
          queryKey: ["company-visitors"],
        });
        setMessageToVisitor("");
        addNotification(t("SMS_SEND"), NotificationType.SUCCESS);
        onClose();
      } catch (error) {
        console.error(error);
        addNotification("Failed to send SMS");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <PopupCard onClose={onClose}>
      <h3>{t("VISIT_DETAILS.VISIT_DETAILS")}</h3>
      {!isVisit(visitor) && (
        <div className={styles.preregDetails}>
          <p>
            <span>{t("VISIT_DETAILS.EVENT_NAME")}:</span> {visitor.eventName}
          </p>
          <p>
            <span>{t("VISIT_DETAILS.EVENT_DESCRIPTION")}: </span>
            {visitor.eventDescription || "\u2014"}
          </p>
          <p>
            <span>{t("VISIT_DETAILS.START_TIME")}: </span>
            {formatTimestampToHoursAndMinutes(visitor.startTime)}
          </p>
        </div>
      )}
      <div className={styles.detailsColumns}>
        <div>
          <h4>{t("VISIT_DETAILS.VISITOR_DETAILS")}</h4>
          <p>
            <span>{t("USERS.TABLE_HEADERS.NAME")}: </span>
            {isVisit(visitor) ? visitor.visitorName : visitor.name}
          </p>
          <p>
            <span>{t("USERS.TABLE_HEADERS.PHONE")}: </span>
            {isVisit(visitor) ? visitor.visitorPhone : visitor.phone}
          </p>
          {isVisit(visitor) && (
            <>
              <p>
                <span>{t("USERS.TABLE_HEADERS.COMPANY")}: </span>
                {visitor.visitorCompanyName || "\u2014"}
              </p>
              <p>
                <span>{t("VISIT_DETAILS.MESSAGE_TO_HOST")}: </span>
                {visitor.messageFromVisitor || "\u2014"}
              </p>
            </>
          )}
        </div>

        <div>
          <h4>{t("VISIT_DETAILS.HOST_DETAILS")}</h4>
          <p>
            <span>{t("USERS.TABLE_HEADERS.NAME")}: </span>{" "}
            {visitor.hostName || "\u2014"}
          </p>
          <p>
            <span>{t("USERS.TABLE_HEADERS.PHONE")}: </span>
            {visitor.hostPhone || "\u2014"}
          </p>
          <p>
            <span>{t("USERS.TABLE_HEADERS.COMPANY")}: </span>
            {isVisit(visitor)
              ? visitor.buildingTenantName
              : visitor.companyName}
          </p>
          <p>
            <span>{t("USERS.TABLE_HEADERS.EMAIL")}: </span>{" "}
            {visitor.hostEmail || "\u2014"}
          </p>
        </div>
      </div>

      {companyReception && isVisit(visitor) && (
        <div className={styles.sendSmsContainer}>
          <h4>{t("BUILDING_VISITORS.SEND_SMS")}</h4>
          <p>{t("BUILDING_VISITORS.SEND_SMS_DESCRIPTION")}</p>
          <TextEditor
            placeholder={t("BUILDING_VISITORS.SEND_SMS_PLACEHOLDER")}
            onChange={(e) => setMessageToVisitor(e.target.value)}
            value={messageToVisitor}
            label={t("BUILDING_VISITORS.SEND_SMS")}
            hideLabel
            dark
          />
          <Button
            onClick={sendSms}
            icon="send"
            isLoading={isLoading}
            disabled={isLoading || !messageToVisitor}
          >
            {t("SEND")}
          </Button>
        </div>
      )}

      {visitorEvents.length > 0 && (
        <>
          <h4 style={{ fontSize: "var(--wlcm-text-sm)" }}>
            {t("ACCESS_CARD.DETAILS_MODAL.HISTORY")}
          </h4>
          <ol className={styles.historyList}>
            {visitorEvents.map((event, index) => (
              <li key={`${event.type}-${index}`}>
                <span>{formatTimestampToHoursAndMinutes(event.timestamp)}</span>{" "}
                {event.details}
              </li>
            ))}
          </ol>
        </>
      )}
    </PopupCard>
  );
}
