import {
  companyOptions,
  getBlobFromUrl,
  updateAccessCard,
  useAccessCardHistory,
  useUserOptions,
  getImageOptions,
} from "@/api";
import { downloadFile, formatTimestampToTimeAgo } from "@/lib/utils";
import { AccessCardType, CardStatus } from "@/types";
import {
  Button,
  Icon,
  InputField,
  LoadingSpinner,
  PopupCard,
} from "@app-components";
import { useTranslation } from "react-i18next";
import styles from "@/styles/Access.module.scss";
import { ActivateCardForm } from "./ActivateCardForm";
import DeactivateCardForm from "./DeactivateCardForm";
import { useState } from "react";
import { useSnackbarStore } from "@/store/zustandStore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import i18n from "@/i18n/config";
import { useLoggedInUser } from "@/providers/AuthProvider";

interface AccessCardDetailsModalProps {
  accessCard: AccessCardType;
  userMode?: boolean;
  buildingAdminMode?: boolean;
  onClose: () => void;
}

export default function AccessCardDetailsModal({
  accessCard,
  userMode,
  buildingAdminMode,
  onClose,
}: AccessCardDetailsModalProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const currUser = useLoggedInUser();
  const { addNotification } = useSnackbarStore();
  const { data: cardHistory, isLoading: historyLoading } = useAccessCardHistory(
    accessCard.id,
  );
  const { data: recipient } = useQuery({
    ...useUserOptions(accessCard.userId),
    enabled: !!accessCard.userId,
  });
  const { data: recipientCompany } = useQuery({
    ...companyOptions(recipient?.buildingTenantId),
    enabled: !!recipient?.buildingTenantId,
  });
  const { data: profileImage } = useQuery({
    ...getImageOptions(recipient?.userProfileImgUrl),
    enabled: !!recipient?.userProfileImgUrl,
  });

  const [note, setNote] = useState(accessCard.note || "");
  const [editNote, setEditNote] = useState(false);
  const [saving, setSaving] = useState(false);
  const [pictureDownloading, setPictureDownloading] = useState(false);

  const handleUpdateCard = async (
    e: React.KeyboardEvent<HTMLInputElement>,
    updateData: Partial<AccessCardType>,
  ) => {
    e.stopPropagation();
    setSaving(true);
    const isUpdatingStatus = updateData.status;
    try {
      await updateAccessCard(
        accessCard.id,
        updateData,
        isUpdatingStatus && currUser.userId, // If updating the status attach current recipient's ID for accountability
      );
      queryClient.invalidateQueries({
        queryKey: ["access-cards", accessCard.userId],
      });
      if (isUpdatingStatus) {
        queryClient.invalidateQueries({
          queryKey: ["extended-access-cards", true],
        });
        queryClient.invalidateQueries({
          queryKey: ["access-card-history", accessCard.id],
        });
      }
    } catch (error) {
      console.error(error);
      addNotification("Failed to update access card! Please try again later.");
    } finally {
      setSaving(false);
      setEditNote(false);
      if (buildingAdminMode) onClose();
    }
  };

  const downloadPicture = async () => {
    if (!recipient?.userProfileImgUrl) return;
    try {
      setPictureDownloading(true);
      const result = await getBlobFromUrl(recipient.userProfileImgUrl);
      if (result?.blob) {
        downloadFile(
          result.blob,
          `${recipient.firstName}-${recipient.lastName}.${result.fileExtension}`,
        );
      }
    } catch (error) {
      console.error(error);
      addNotification(
        "Failed to download profile picture! Please try again later.",
      );
    } finally {
      setPictureDownloading(false);
    }
  };

  return (
    <PopupCard onClose={onClose}>
      <div onClick={() => setEditNote(false)}>
        <h3>{t("ACCESS_CARD.DETAILS_MODAL.HEADER")}</h3>
        <div className={styles.modalCardDetails}>
          <div className={styles.cardDetails}>
            <p>
              <span>{t("ACCESS_CARD.NUMBER")}:</span>{" "}
              {accessCard.cardNumber ? (
                <strong className={styles.monospace}>
                  {accessCard.cardNumber}
                </strong>
              ) : (
                <span style={{ opacity: 0.5 }}>{"\u2014"}</span>
              )}
            </p>
            <p>
              <span>Type: </span>
              <strong>
                {t(
                  `ACCESS_CARD.ARIA_DESCRIPTION.${accessCard.type.toUpperCase()}`,
                )}
              </strong>
            </p>
          </div>
          {!userMode && (
            <div>
              {!recipient ? (
                <>
                  <strong>{t("ACCESS_CARD.TABLE_HEADERS.RECIPIENT")}</strong>{" "}
                  <span>-</span>
                </>
              ) : (
                <div className={styles.userInfo}>
                  {profileImage ? (
                    <div className={styles.userProfileImg}>
                      <img
                        src={profileImage}
                        alt={t("USERS.TABLE_HEADERS.PROFILE_PIC")}
                      />
                      <div className={styles.overlay} onClick={downloadPicture}>
                        <div className={styles.overlayInnerContainer}>
                          {pictureDownloading ? (
                            <LoadingSpinner />
                          ) : (
                            <>
                              <Icon
                                name="download"
                                className={styles.downloadIcon}
                              />
                              <p>
                                {t("ACCESS_CARD.DETAILS_MODAL.DOWNLOAD_PIC")}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.userProfilePlaceholder}>
                      <Icon name="person" />
                      <p>{t("IMAGE.PLACEHOLDER.PROFILE")}</p>
                    </div>
                  )}
                  <div className={styles.userProfileDetails}>
                    <p>
                      <span>{t("USERS.TABLE_HEADERS.FIRST_NAME")}:</span>{" "}
                      <strong>{recipient.firstName}</strong>
                    </p>
                    <p>
                      <span>{t("USERS.TABLE_HEADERS.LAST_NAME")}:</span>{" "}
                      <strong> {recipient.lastName}</strong>
                    </p>
                    <p>
                      <span>{t("USERS.TABLE_HEADERS.EMAIL")}:</span>{" "}
                      <strong>{recipient.email}</strong>
                    </p>
                    <p>
                      <span>{t("USERS.TABLE_HEADERS.PHONE")}:</span>{" "}
                      {recipient.phone ? (
                        <strong>{recipient.phone}</strong>
                      ) : (
                        <span>{"\u2014"}</span>
                      )}
                    </p>
                    <p>
                      <span>{t("EMPLOYEE_ID")}:</span>{" "}
                      {recipient.customEmployeeId ? (
                        <strong>{recipient.customEmployeeId}</strong>
                      ) : (
                        <span>{"\u2014"}</span>
                      )}
                    </p>

                    {recipientCompany?.buildingTenantName && (
                      <p>
                        <span>{t("ACCESS_CARD.TABLE_HEADERS.COMPANY")}:</span>{" "}
                        <strong>{recipientCompany.buildingTenantName}</strong>
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {accessCard.note || editNote ? (
          <div
            style={{ width: "100%", marginBottom: "var(--wlcm-spacing-sm)" }}
            onClick={(e) => e.stopPropagation()}
          >
            {editNote ? (
              <InputField
                autoFocus
                dark
                compact
                hideLabel
                icon="comment"
                label={t("ACCESS_CARD.NOTE")}
                value={note}
                append={
                  <Button
                    isLoading={saving}
                    disabled={saving}
                    onClick={(e) => handleUpdateCard(e, { note })}
                    style={{ marginRight: -8 }}
                  >
                    {t("EDIT_USER.SAVE")}
                  </Button>
                }
                onKeyDown={(e) =>
                  e.key == "Escape"
                    ? setEditNote(false)
                    : e.key == "Enter"
                      ? handleUpdateCard(e, { note })
                      : null
                }
                onChange={(e) => setNote(e.target.value)}
              />
            ) : (
              <div className={`${styles.centerAligned} ${styles.cardNote}`}>
                <Icon name="comment" />
                <p>
                  <strong>{t("ACCESS_CARD.NOTE")}:</strong> {accessCard.note}
                </p>
                {userMode && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditNote(true);
                    }}
                  >
                    {t("EDIT")}
                  </button>
                )}
              </div>
            )}
          </div>
        ) : (
          userMode && (
            <Button
              secondary
              icon="comment"
              onClick={(e) => {
                e.stopPropagation();
                setEditNote(true);
              }}
              style={{
                marginBottom: "var(--wlcm-spacing-sm)",
                borderColor: "var(--wlcm-color-blue)",
                outlineColor: "var(--wlcm-color-blue)",
                color: "var(--wlcm-color-blue)",
              }}
            >
              {t("ACCESS_CARD.ACTIONS.ADD_NOTE")}
            </Button>
          )
        )}

        {!userMode &&
          accessCard.orderNote &&
          accessCard.status === CardStatus.ORDERED && (
            <div
              className={`${styles.centerAligned} ${styles.cardNote}`}
              style={{ marginBottom: "var(--wlcm-spacing-sm)" }}
            >
              <Icon name="comment" />
              <p>
                <strong>{t("ACCESS_CARD.ORDER_MESSAGE.TEXT")}:</strong> "
                {accessCard.orderNote}"
              </p>
            </div>
          )}

        {buildingAdminMode &&
          (accessCard.status === CardStatus.ORDERED ||
            accessCard.status === CardStatus.INACTIVE) && (
            <div
              style={{
                width: "100%",
                padding: "var(--wlcm-spacing-sm)",
                borderRadius: "var(--wlcm-border-radius-sm)",
                backgroundColor: "rgba(0,0,0,0.2)",
                display: "flex",
                flexDirection: "column",
                gap: "var(--wlcm-spacing-xs)",
                marginBottom: "var(--wlcm-spacing-sm)",
              }}
            >
              {accessCard.status === CardStatus.ORDERED && (
                <>
                  <h4 style={{ fontSize: "var(--wlcm-text-sm)" }}>
                    {t("ACCESS_CARD.ACTIONS.ACTIVATE")}
                  </h4>
                  <ActivateCardForm
                    cardId={accessCard.id}
                    recipient={recipient}
                    onClose={onClose}
                  />
                </>
              )}
              {accessCard.status === CardStatus.INACTIVE && (
                <>
                  <h4 style={{ fontSize: "var(--wlcm-text-sm)" }}>
                    {t("ACCESS_CARD.ACTIONS.DEACTIVATE")}
                  </h4>
                  <DeactivateCardForm
                    cardId={accessCard.id}
                    cardNumber={accessCard.cardNumber || ""}
                    recipient={recipient}
                    onClose={onClose}
                  />
                </>
              )}
            </div>
          )}

        {!userMode && accessCard.status === CardStatus.ORDERED && (
          <Button
            danger
            secondary
            style={{ marginBottom: "var(--wlcm-spacing-sm)" }}
            isLoading={saving}
            onClick={(e) =>
              handleUpdateCard(e, { status: CardStatus.CANCELLED })
            }
          >
            {t("ACCESS_CARD.DETAILS_MODAL.CANCEL_CARD")}
          </Button>
        )}

        <h4 style={{ fontSize: "var(--wlcm-text-sm)" }}>
          {t("ACCESS_CARD.DETAILS_MODAL.HISTORY")}
        </h4>
        {historyLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "var(--wlcm-spacing-sm)",
            }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          cardHistory && (
            <ol className={styles.historyList}>
              {cardHistory.map((item) => (
                <li key={item.timestamp._nanoseconds}>
                  <span style={{ opacity: 0.5, marginRight: 10 }}>
                    {formatTimestampToTimeAgo(
                      t,
                      item.timestamp,
                      i18n.language,
                      true,
                    )}
                  </span>
                  {item.status === CardStatus.ORDERED ? (
                    <>
                      {t("ACCESS_CARD.DETAILS_MODAL.CARD_ORDERED_BY", {
                        name: accessCard.orderedBy.name,
                        email: accessCard.orderedBy.email,
                        company: accessCard.orderedBy.company,
                      })}{" "}
                      {accessCard.orderNote &&
                        t("ACCESS_CARD.DETAILS_MODAL.WITH_ORDER_MESSAGE", {
                          message: accessCard.orderNote,
                        })}
                    </>
                  ) : item.status === CardStatus.ACTIVE ? (
                    <>
                      {t("ACCESS_CARD.DETAILS_MODAL.CARD_ACTIVATED")}{" "}
                      {accessCard.pickupInstructions &&
                        t("ACCESS_CARD.DETAILS_MODAL.WITH_MESSAGE", {
                          message: accessCard.pickupInstructions,
                        })}
                    </>
                  ) : item.status === CardStatus.INACTIVE ? (
                    t("ACCESS_CARD.DETAILS_MODAL.CARD_INACTIVATED")
                  ) : item.status === CardStatus.DEACTIVATED ? (
                    <>
                      {t("ACCESS_CARD.DETAILS_MODAL.CARD_DEACTIVATED")}
                      {accessCard.reasonForDeactivation &&
                        t("ACCESS_CARD.DETAILS_MODAL.WITH_REASON", {
                          reason: accessCard.reasonForDeactivation,
                        })}
                    </>
                  ) : item.status === CardStatus.CANCELLED ? (
                    t("ACCESS_CARD.DETAILS_MODAL.ORDER_CANCELLED")
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </ol>
          )
        )}
      </div>
    </PopupCard>
  );
}
