import { Trans, useTranslation } from "react-i18next";
import { WELCOME_VISITORS_URL } from "@/lib/constants";
import i18n from "@/i18n/config";
import { createFileRoute } from "@tanstack/react-router";
import { useAllEntrances, useBuildingVisitors } from "@/api";
import { useBuildingPreregistrations } from "@/api/functions/pre-registration";
import { ReceptionView } from "@/components/ReceptionView";
import { LoadingSpinner } from "@app-components";
import { Suspense } from "react";

export const Route = createFileRoute("/building/visitors/")({
  component: BuildingVisitors,
});

function BuildingVisitors() {
  const { t } = useTranslation();
  const { data: buildingVisitors } = useBuildingVisitors();
  const { data: entrances } = useAllEntrances();
  const { data: preregistrationGroups } = useBuildingPreregistrations();

  return (
    <>
      <div className="pageHeader">
        <h1>{t("PAGES.BUILDING_VISITORS.HEADER")}</h1>
        <p>
          <Trans i18nKey="PAGES.BUILDING_VISITORS.SUBHEADER">
            Text
            <a href={WELCOME_VISITORS_URL(i18n.language)} target="_blank"></a>.
          </Trans>
        </p>
      </div>
      <Suspense
        fallback={
          <div className="center">
            <LoadingSpinner />
          </div>
        }
      >
        <ReceptionView
          visitors={buildingVisitors}
          entrances={entrances}
          preregistrationGroups={preregistrationGroups}
          navigationPath="/building/visitors/preregistration"
        />
      </Suspense>
    </>
  );
}
