import { useMemo, useState } from "react";
import { MeetingRoomBooking, NotificationType } from "@/types";
import styles from "@/styles/Bookings.module.scss";
import { Link } from "@tanstack/react-router";
import { cancelMeetingRoomBooking, useMeetingRooms } from "@/api";
import { BOOKING_DATE_LOCALE, dateToTimeInputString } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "@app-components";
import { useSnackbarStore } from "@/store/zustandStore";
import { useQueryClient } from "@tanstack/react-query";
import { useLoggedInUser } from "@/providers/AuthProvider";

interface RoomBookingCardProps {
  booking: MeetingRoomBooking;
}

export default function RoomBookingCard({ booking }: RoomBookingCardProps) {
  const { t, i18n } = useTranslation();
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  const { addNotification } = useSnackbarStore();
  const { data: rooms, isError } = useMeetingRooms();
  const room = useMemo(
    () => rooms?.find((r) => r.name === booking.roomName), // TODO: switch to using ID to avoid errors if room name is changed
    [rooms, booking],
  );
  const [isCancelling, setIsCancelling] = useState(false);

  if (isError || (rooms && !room))
    throw new Error("Failed to load meeting rooms");

  const handleCancelBooking = async () => {
    setIsCancelling(true);
    try {
      await cancelMeetingRoomBooking({
        id: booking.id,
        userId: user.userId,
        userFirstName: user.firstName,
        userLanguage: user.language,
      });
      queryClient.invalidateQueries({ queryKey: ["user-bookings"] });
      queryClient.invalidateQueries({ queryKey: ["booked-slots"] });
      queryClient.invalidateQueries({ queryKey: ["meeting-room-search"] });
      addNotification(
        `Cancelled booking "${booking.event.title}"`,
        NotificationType.SUCCESS,
      );
    } catch (e) {
      console.error(e);
      addNotification(
        `Failed to cancel booking "${booking.event.title}". Try again later!`,
      );
    } finally {
      setIsCancelling(false);
    }
  };

  return !room ? null : (
    <article className={styles.bookingCardContainer}>
      <div className={styles.cardInfoContainer}>
        <h3>"{booking.event.title}"</h3>
        <h4>
          {BOOKING_DATE_LOCALE(i18n.language).format(
            new Date(booking.event.start),
          )}
          , {dateToTimeInputString(new Date(booking.event.start))} -{" "}
          {dateToTimeInputString(new Date(booking.event.end))}
        </h4>
        <div>
          <p>
            <Icon name="location_on" />{" "}
            <span>
              <strong>{room.name}</strong>
              <br />
              {room.floor}. {t("FLOOR").toLowerCase()}
              {room.location && room.location[i18n.language as "en" | "no"]
                ? `, ${room.location[i18n.language as "en" | "no"]}`
                : ""}
            </span>
          </p>
          <Link to="/bookings/room/$roomId" params={{ roomId: room.id }}>
            {t("BOOKINGS.MORE_DETAILS")}{" "}
            <Icon name="arrow_forward" style={{ fontSize: "1em" }} />
          </Link>
        </div>
        <Button
          dark
          onClick={handleCancelBooking}
          isLoading={isCancelling}
          disabled={isCancelling}
        >
          {t("BOOKINGS.CANCEL")}
        </Button>
      </div>
      <div aria-hidden className={styles.cardThumbnailContainer}>
        {room.thumbnail ? (
          <img src={room.thumbnail} alt={room.name} />
        ) : (
          <Icon name="meeting_room" />
        )}
      </div>
    </article>
  );
}
