import { CSSProperties } from "react";
import { Icon } from "../Icon";
import styles from "./Checkbox.module.scss";

type CheckboxProps = {
  onClick?: (e: React.MouseEvent) => void;
  onChange?: (newValue: boolean) => void;
  nativeOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  label: string | React.ReactElement;
  hideLabel?: boolean;
  dark?: boolean;
  className?: string;
  style?: {
    container?: CSSProperties;
    checkbox?: CSSProperties;
  };
  disabled?: boolean;
};

export function Checkbox({
  onClick,
  onChange,
  nativeOnChange,
  checked,
  label,
  hideLabel,
  dark,
  className,
  style,
  disabled,
}: CheckboxProps) {
  return (
    <label
      className={`${styles.checkboxContainer} ${dark && styles.dark} ${disabled && styles.disabled} ${className}`}
      onClick={(e) => !!onClick && onClick(e)}
      style={style?.container}
    >
      <span className={hideLabel ? "sr-only" : ""}>{label}</span>
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) onChange(e.target.checked);
          if (nativeOnChange) nativeOnChange(e);
        }}
      />
      <span className={styles.customCheckbox} style={style?.checkbox}>
        <Icon name="done" className={styles.checkIcon} aria-hidden />
      </span>
    </label>
  );
}
