import {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  KeyboardEvent,
  FocusEvent as ReactFocusEvent,
  useState,
} from "react";
import { Icon } from "../Icon";
import { styles } from "../InputField";
import { StatusText } from "../StatusText";

export interface StrictNumberInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode;
  hideLabel?: boolean;
  value?: number;
  className?: string;
  min?: number;
  max?: number;
  /**
   * A number specifying the steps of incrementing or decrementing
   * Note: Will cause the input value to snap to the nearest step
   * @Default 1
   */
  step?: number;
  icon?: string;
  placeholder?: string;
  disabled?: boolean;
  focus?: boolean;
  autoFocus?: boolean;
  large?: boolean;
  danger?: boolean;
  success?: boolean;
  dark?: boolean;
  required?: boolean;
  compact?: boolean;
  inputBgColor?: string;
  append?: ReactNode;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: ReactFocusEvent<HTMLInputElement, Element>) => void;
  onFocus?: (e: ReactFocusEvent<HTMLInputElement, Element>) => void;
  dangerText?: string;
  successText?: string;
  infoText?: string;
}

export const StrictNumberInput = ({
  id,
  label,
  hideLabel,
  value = 0,
  className,
  min,
  max,
  step = 1,
  icon,
  placeholder,
  disabled,
  focus,
  autoFocus,
  large,
  danger,
  success,
  dark,
  required,
  compact,
  inputBgColor,
  append,
  onChange,
  onKeyDown,
  onKeyUp,
  onBlur,
  onFocus,
  dangerText,
  successText,
  infoText,
  ...rest
}: StrictNumberInputProps) => {
  const [internalValue, setInternalValue] = useState(value.toString());

  const canDecrement = () => {
    if (!min) return true;
    return value > min;
  };

  const canIncrement = () => {
    if (!max) return true;
    return value < max;
  };

  const handleDecrement = () => {
    if (!canDecrement()) return;
    const newVal = value - step;
    if (onChange)
      onChange({
        target: { value: newVal },
      } as unknown as ChangeEvent<HTMLInputElement>);
    setInternalValue(newVal.toString());
  };

  const handleIncrement = () => {
    if (!canIncrement()) return;
    const newVal = value + step;
    if (onChange)
      onChange({
        target: { value: newVal },
      } as unknown as ChangeEvent<HTMLInputElement>);
    setInternalValue(newVal.toString());
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      let val = Number(internalValue);

      if (min && val < min) {
        val = min;
      } else if (max && val > max) {
        val = max;
      }

      if (step) {
        val = Math.round(val / step) * step;
      }

      setInternalValue(val.toString());
      onChange({ ...e, target: { ...e.target, value: val.toString() } });
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) onKeyDown(e);
  };

  const handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) onKeyUp(e);
  };

  const handleOnBlur = (e: ReactFocusEvent<HTMLInputElement, Element>) => {
    if (onBlur) onBlur(e);
    handleOnChange(e);
  };

  const handleOnFocus = (e: ReactFocusEvent<HTMLInputElement, Element>) => {
    if (onFocus) onFocus(e);
  };

  return (
    <div
      className={`
    ${styles.inputContainer} 
   
    ${dark ? styles.dark : ""}`}
      {...rest}
    >
      {!!label && (
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "end",
            justifyContent: "flex-start",
          }}
        >
          <label
            htmlFor={`${label}_INPUT`}
            className={hideLabel || (compact && hideLabel) ? "sr-only" : ""}
          >
            {label}
            {required && typeof label === "string" && (
              <>
                {" "}
                <strong aria-hidden>*</strong>
                <span className="sr-only">(required)</span>
              </>
            )}
          </label>
        </div>
      )}
      <div
        className={`
        ${className ? className : ""} 
        ${styles.inputField} 
        ${focus ? styles.focus : ""}
        ${large ? styles.large : ""}
        ${danger || (dangerText && dangerText.length > 0) ? styles.danger : ""}
        ${
          success || (successText && successText.length > 0)
            ? styles.success
            : ""
        }
        ${dark ? styles.dark : ""}
        ${disabled ? styles.disabled : ""}`}
        {...(inputBgColor ? { style: { backgroundColor: inputBgColor } } : {})}
      >
        <button
          className={styles.incrementDecrementBtn}
          disabled={!canDecrement()}
          onClick={handleDecrement}
          style={{ marginLeft: -10 }}
        >
          <Icon name="subtract" />
          <span className="sr-only">Decrement</span>
        </button>
        {icon && <Icon className={styles.icon} name={icon} />}
        <input
          id={id || `${label}_INPUT`}
          autoFocus={autoFocus}
          className={styles.input}
          type="number"
          placeholder={placeholder}
          disabled={disabled}
          min={min}
          max={max}
          required={required}
          value={internalValue}
          onChange={(e) => setInternalValue(e.target.value)}
          onKeyDown={handleOnKeyDown}
          onKeyUp={handleOnKeyUp}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          style={{ marginRight: 0 }}
        />
        {append && append}
        <button
          className={styles.incrementDecrementBtn}
          disabled={!canIncrement()}
          onClick={handleIncrement}
          style={{ marginRight: 10 }}
        >
          <Icon name="add" />
          <span className="sr-only">Increment</span>
        </button>
      </div>
      <div className={styles.bottomContainer}>
        {!dangerText && !successText && !infoText && !compact ? (
          <div style={{ height: "24px" }}></div>
        ) : dangerText ? (
          <StatusText text={dangerText} fail dark={dark} />
        ) : successText ? (
          <StatusText text={successText} success dark={dark} />
        ) : (
          !!infoText && <StatusText text={infoText} dark={dark} />
        )}
      </div>
    </div>
  );
};
