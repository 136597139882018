import { useState, useEffect, createContext, useContext } from "react";
import { auth, logAnalyticsEvent } from "@/lib/firebase";
import {
  OAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  onAuthStateChanged,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { env } from "@/lib/utils";
import styles from "@/styles/Auth.module.scss";
import whiteLogoFull from "@/assets/whiteLogoFull.svg";
import { Button, Icon, InputField } from "@app-components";
import LoadingScreen from "@/components/LoadingScreen";
import {
  getUser,
  checkUserLogin,
  sendMagicLink,
  usePublicBuildingData,
} from "@/api";
import { User } from "@/types";
import { Trans, useTranslation } from "react-i18next";
import { EMAIL_REGEX } from "@/lib/constants";
import { SignInError } from "@/components/SignInError";

const Login = () => {
  const params = new URLSearchParams(window.location.search);
  const providedEmail = params.get("email") || "";
  const { data: building } = usePublicBuildingData();
  const [email, setEmail] = useState(providedEmail);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showMagicHelpText, setShowMagicHelpText] = useState(false);
  const [serviceDown, setServiceDown] = useState(false);
  const { t } = useTranslation();

  const loginWithMagic = async () => {
    if (!auth) return;
    logAnalyticsEvent("login_with_magic_link");
    try {
      window.localStorage.setItem("emailForSignIn", email.toLowerCase());
      const language = window.localStorage.getItem("i18nextLng");

      await sendMagicLink(email.toLowerCase(), language ?? "no");
      setShowMagicHelpText(true);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      setIsLoggingIn(false);
    }
  };

  const logInWithSSO = async () => {
    if (!auth) return;
    const provider = new OAuthProvider("oidc.keycloak");
    provider.setCustomParameters({
      login_hint: email.toLowerCase(),
    });
    logAnalyticsEvent("login_with_sso");
    try {
      if (env("VITE_ENVIRONMENT") === "dev") {
        await signInWithPopup(auth, provider);
        window.location.reload();
      } else {
        await signInWithRedirect(auth, provider);
      }
    } catch (error) {
      throw new Error("Login error", { cause: error });
    }
  };

  const logIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoggingIn(true);
    try {
      const res = await checkUserLogin(email.toLowerCase());
      const json = await res.json();
      if (json.login === "sso") {
        await logInWithSSO();
      } else {
        await loginWithMagic();
      }
    } catch (err) {
      console.error(err);
      setServiceDown(true);
    } finally {
      setIsLoggingIn(false);
    }
  };

  const showHelpText = showMagicHelpText || serviceDown;

  return (
    <>
      <div
        className={styles.backgroundBanner}
        style={
          building && building.buildingBannerImgUrl
            ? { background: `url(${building.buildingBannerImgUrl})` }
            : {}
        }
      ></div>
      <div className={styles.contentContainer}>
        <div>
          <h1>
            <span>Welcome Workdays</span>
            <br />
            {building && building.displayName}
          </h1>
          {building && (
            <p>{t("LOGIN_TAGLINE", { buildingName: building.displayName })}</p>
          )}
          <div className={styles.magicContainer}>
            {!showHelpText && (
              <form onSubmit={logIn}>
                <InputField
                  compact
                  hideLabel
                  className={styles.emailInput}
                  label={t("USERS.TABLE_HEADERS.EMAIL")}
                  placeholder={t("USERS.TABLE_HEADERS.EMAIL")}
                  type="email"
                  value={email}
                  inputBgColor="white"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  type="submit"
                  isLoading={isLoggingIn}
                  disabled={
                    isLoggingIn ||
                    email.length === 0 ||
                    !EMAIL_REGEX.test(email)
                  }
                >
                  {t("LOGIN.BUTTON")}
                </Button>
              </form>
            )}
            {showMagicHelpText && (
              <>
                <Icon name="mail" className={styles.helpText} />
                <p className={styles.helpText}>
                  <Trans
                    i18nKey="LOGIN.MAGIC_HELP"
                    components={{
                      linebreak: <br />,
                      h2: <h2></h2>,
                      b: <strong></strong>,
                    }}
                  />
                </p>
              </>
            )}
            {serviceDown && (
              <p className={styles.errorText}>{t("LOGIN.NO_ACCESS")}</p>
            )}
          </div>
        </div>
        <div className={styles.logos}>
          {building && (
            <>
              <img
                className={styles.buildingOwnerLogo}
                src={building.ownerLogoImgUrl}
                alt={`${building.owner}`}
              />
              <p className="no-select" aria-hidden>
                +
              </p>
            </>
          )}
          <img src={whiteLogoFull} alt="Welcome Workdays" height="30" />
        </div>
      </div>
    </>
  );
};

type AuthContextType = {
  user: User | null;
  refetchUser: () => void;
};
const AuthContext = createContext<AuthContextType>({
  user: null,
  refetchUser: () => null,
});

export const useLoggedInUser = () => {
  const context = useContext(AuthContext);
  if (!context.user) throw new Error("Auth not initialized");
  return context.user;
};

export const useRefetchUser = () => {
  const context = useContext(AuthContext);
  if (!context.user) throw new Error("Auth not initialized");
  return context.refetchUser;
};

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [authStateChecked, setAuthStateChecked] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [signInError, setSignInError] = useState(false);

  const refetchUser = async () => {
    if (!user) return;
    const fetchedUser = await getUser("userId", user.userId);
    if (!fetchedUser)
      throw new Error("Couldn't find user with id " + user.userId);
    setUser(fetchedUser);
  };

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const emailFromLocalStorage =
        window.localStorage.getItem("emailForSignIn");
      const queryParams = new URLSearchParams(window.location.search);
      const emailFromQueryParam = queryParams.get("email");

      const email = emailFromQueryParam || emailFromLocalStorage;

      if (email) {
        signInWithEmailLink(auth, email, window.location.href)
          .then(() => {
            // Clear the local storage email
            window.localStorage.removeItem("emailForSignIn");
          })
          .catch((error) => {
            console.error("Sign-in failed:", error);
            setSignInError(true);
          });
      } else {
        console.error("Could not get email. Please try again.");
      }
    }

    onAuthStateChanged(auth, (u) => {
      setAuthStateChecked(true);
      if (!u) return;
      (async () => {
        const user = await getUser("userId", u.uid);
        if (!user) throw new Error("Couldn't find user with id " + u.uid);
        setUser(user);
        if (env("VITE_ENVIRONMENT") === "prod") {
          const Sentry = await import("@sentry/react");
          Sentry.setUser({
            id: user.userId,
            username: `${user.firstName} ${user.lastName}`,
            email: user.email,
          });
          Sentry.setTags({
            buildingTenantId: user.buildingTenantId,
            realEstate: env("VITE_REAL_ESTATE"),
          });
        }
      })();
    });
  }, []);

  if (!authStateChecked) return <LoadingScreen />;
  if (!auth.currentUser && !isSignInWithEmailLink(auth, window.location.href)) {
    return <Login />;
  }
  if (signInError) return <SignInError />;
  if (!user) return <LoadingScreen />;

  return (
    <AuthContext.Provider value={{ user, refetchUser }}>
      {children}
    </AuthContext.Provider>
  );
}
