import { ExtendedCompany } from "@/types";
import { Button, Icon, LoadingSpinner } from "@app-components";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "@/styles/Companies.module.scss";
import Table from "@/components/table/Table.tsx";
import AddCompanyModal from "@/components/building_admin/building_companies/AddCompanyModal";
import { createFileRoute } from "@tanstack/react-router";
import {
  getExportedBuildingUsers,
  getExportedCompanyUsers,
  useBuilding,
  useExtendedCompanies,
} from "@/api";
import { Tooltip } from "react-tooltip";
import CompanyDetailsModal from "@/components/building_admin/building_companies/CompanyDetailsModal";
import SortableTableHeader from "@/components/table/SortableTableHeader";
import { totalUsersSortFn } from "@/lib/utils";

export const Route = createFileRoute("/building/companies")({
  component: Companies,
});

const columnHelper = createColumnHelper<ExtendedCompany>();

function Companies() {
  const { t } = useTranslation();
  const { data: building } = useBuilding();
  const { data: companies } = useExtendedCompanies();
  const [addCompanyOpen, setAddCompanyOpen] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const [loadingAllUsers, setLoadingAllUsers] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<ExtendedCompany>();
  const [detailsOpen, setDetailsOpen] = useState(false);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "users-count",
      desc: true,
    },
  ]);

  const exportCompanyUsers = async (
    companyId: string,
    companyName: string,
    index: number,
  ) => {
    setLoadingIndex(index);
    try {
      await getExportedCompanyUsers(companyId, companyName);
    } finally {
      setLoadingIndex(null);
    }
  };

  const exportAllBuildingUsers = async () => {
    setLoadingAllUsers(true);
    try {
      await getExportedBuildingUsers(building.displayName);
    } finally {
      setLoadingAllUsers(false);
    }
  };

  const columns = useMemo(
    // memoize so columns don't need to be "re-rendered" when component changes
    () => [
      columnHelper.accessor("buildingTenantName", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("COMPANY_SETTINGS.COMPANY_NAME")}</div>,
      }),
      columnHelper.accessor("visitMode", {
        cell: (info) => {
          const mode = info.getValue().toUpperCase();
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "var(--wlcm-spacing-xs)",
              }}
            >
              <div
                aria-hidden
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 20,
                  background:
                    mode === "ENABLED"
                      ? "var(--wlcm-color-success-green)"
                      : mode === "DISABLED"
                        ? "var(--wlcm-color-danger-red)"
                        : "var(--wlcm-color-warning-yellow)",
                }}
              ></div>
              <span>{t(`COMPANY_SETTINGS.VISIT_MODE.${mode}.LABEL`)}</span>
            </div>
          );
        },
        header: () => <div>{t("COMPANY_SETTINGS.VISIT_MODE.LABEL")}</div>,
      }),
      columnHelper.accessor((row) => [row.activeUsers, row.totalUsers], {
        id: "users-count",
        cell: (info) => (
          <>
            <span style={{ color: "var(--wlcm-color-success-green)" }}>
              {info.getValue()[0]}
            </span>{" "}
            / {info.getValue()[1]}
          </>
        ),
        // header: () => <div>{t("COMPANIES_TABLE.USERS")}</div>,
        header: (info) => (
          <SortableTableHeader
            info={info}
            tableHeaderId="users-count"
            label={t("COMPANIES_TABLE.USERS")}

          />
        ),
        sortingFn: totalUsersSortFn,
      }),
      columnHelper.display({
        id: "export",
        cell: ({ row }) => {
          const isLoading = loadingIndex === row.index;
          return row.original.totalUsers > 0 ? (
            <div>
              {isLoading ? (
                <div className={styles.loadingExport}>
                  <LoadingSpinner />
                </div>
              ) : (
                <button
                  className={styles.exportButton}
                  aria-label="Export Data"
                  onClick={(e) => {
                    e.stopPropagation();
                    exportCompanyUsers(
                      row.original.buildingTenantId,
                      row.original.buildingTenantName,
                      row.index,
                    );
                  }}
                >
                  <Icon name="file_export" className="file-export-icon" />
                  <Tooltip
                    anchorSelect=".file-export-icon"
                    content={t("COMPANIES_TABLE.EXPORT_USERS")}
                  />
                  <span className="sr-only">
                    {t("COMPANIES_TABLE.EXPORT_USERS")}
                  </span>
                </button>
              )}
            </div>
          ) : null;
        },
        header: () => (
          <div className="sr-only">{t("COMPANIES_TABLE.EXPORT_USERS")}</div>
        ),
      }),
    ],
    [loadingIndex],
  );

  const table = useReactTable({
    data: (companies as ExtendedCompany[]) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: Number(localStorage.getItem("companiesPerPage") || 15),
      },
    },
  });

  return (
    <>
      <div className="pageHeader">
        <h1>{t("PAGES.COMPANIES.HEADER")}</h1>
        <p>{t("PAGES.COMPANIES.SUBHEADER")}</p>
      </div>
      <div>
        <Button
          className={styles.addButton}
          icon="add"
          onClick={() => setAddCompanyOpen(true)}
        >
          {t("PAGES.COMPANIES.ADD")}
        </Button>
      </div>
      <Table
        table={table}
        onRowClick={(company) => {
          setSelectedCompany(company);
          setDetailsOpen(true);
        }}
        totalItems={
          companies && companies.length >= 15 ? companies.length : undefined
        }
        paginationKey="companiesPerPage"
      />
      <div>
        <Button
          className={styles.exportAllUsersButton}
          onClick={exportAllBuildingUsers}
          isLoading={loadingAllUsers}
          disabled={loadingAllUsers}
          dark
          secondary
          icon="file_export"
        >
          {t("COMPANIES_TABLE.EXPORT_ALL_USERS")}
        </Button>
      </div>
      {addCompanyOpen && (
        <AddCompanyModal onClose={() => setAddCompanyOpen(false)} />
      )}
      {detailsOpen && (
        <CompanyDetailsModal
          onClose={() => setDetailsOpen(false)}
          company={selectedCompany as ExtendedCompany}
        />
      )}
    </>
  );
}
