import {
  DEFAULT_BOOKING_SETTINGS,
  MEETING_ROOM_FEATURE_OPTIONS,
} from "@/lib/constants";
import {
  calculateEndTime,
  dateInputStringToDate,
  dateToDateInputString,
  dateToTimeInputString,
  formatDateToTimezonedString,
  timeInputStringToDate,
} from "@/lib/utils";
import styles from "@/styles/Bookings.module.scss";
import {
  DateString,
  DropdownOption,
  MeetingRoom,
  MeetingRoomFeature,
  TimeString,
} from "@/types";
import {
  DateInput,
  Dropdown,
  LoadingSpinner,
  StrictNumberInput,
  TimeInput,
} from "@app-components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MeetingRoomCard from "./MeetingRoomCard";
import { useMeetingRoomSearch } from "@/api";
import BookingConfirmationModal from "./BookingConfirmationModal";
import { AnimatePresence, motion } from "framer-motion";
import { differenceInMinutes, isAfter, isBefore } from "date-fns";

const getNextFullHour = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  if (minutes > 0) {
    now.setHours(now.getHours() + 1);
  }
  now.setMinutes(0, 0, 0);
  return now;
};

const getNextFullHalfHour = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const roundedMinutes = minutes >= 45 ? 60 : 30; // Round up if 45 minutes or more, otherwise round down to 30
  const closestHour = new Date(now.setMinutes(roundedMinutes, 0, 0));
  return closestHour;
};

export default function SearchForRooms() {
  const { t, i18n } = useTranslation();
  const { maxDuration, minTime, maxTime } = DEFAULT_BOOKING_SETTINGS;

  const now = useMemo(() => new Date(), []);
  const currDate = useMemo(() => dateToDateInputString(now), [now]);
  const oneMonthFromNow = useMemo(
    () => new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    [],
  );
  const oneMonthFromNowDateString = useMemo(
    () => dateToDateInputString(oneMonthFromNow),
    [oneMonthFromNow],
  );
  const capacityOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({
        label: `${i} ${t("PEOPLE", { count: i }).toLowerCase()}`,
        value: i.toString(),
      });
    }
    return options;
  }, []);

  const [date, setDate] = useState(currDate);
  const [time, setTime] = useState(dateToTimeInputString(getNextFullHour()));
  const [duration, setDuration] = useState(1);
  const [capacity, setCapacity] = useState<DropdownOption>();
  const [features, setFeatures] = useState<MeetingRoomFeature[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<MeetingRoom>();
  const [filtersError, setFiltersError] = useState("");

  const {
    data: results,
    isLoading,
    isFetching,
  } = useMeetingRoomSearch({
    dateTime: formatDateToTimezonedString(new Date(`${date}T${time}`)),
    duration: duration * 60,
    capacity: capacity?.value as number | undefined,
    features,
  });

  const handleDateInput = (date: DateString) => {
    const inputAsDate = dateInputStringToDate(date);
    if (inputAsDate > oneMonthFromNow) {
      setDate(oneMonthFromNowDateString);
    } else if (inputAsDate < now) {
      setDate(currDate);
    } else {
      setDate(date);
    }
  };

  useEffect(() => {
    const startTimeAsDate = new Date(`${date}T${time}`);
    const endTimeAsDate = calculateEndTime(time, duration, date);
    if (
      differenceInMinutes(endTimeAsDate, startTimeAsDate) / 60 >
      maxDuration
    ) {
      setFiltersError(
        t("BOOKINGS.VALIDATION.OVER_MAX_DURATION", { duration: maxDuration }),
      );
    } else if (isBefore(startTimeAsDate, now)) {
      setFiltersError(t("BOOKINGS.VALIDATION.STARTING_IN_PAST"));
    } else if (
      isBefore(startTimeAsDate, timeInputStringToDate(minTime, startTimeAsDate))
    ) {
      setFiltersError(
        t("BOOKINGS.VALIDATION.START_BEFORE_MIN_TIME", { time: minTime }),
      );
    } else if (
      isAfter(endTimeAsDate, timeInputStringToDate(maxTime, startTimeAsDate))
    ) {
      setFiltersError(
        t("BOOKINGS.VALIDATION.END_AFTER_MAX_TIME", { time: maxTime }),
      );
    } else {
      setFiltersError("");
    }
  }, [date, time, duration]);

  useEffect(() => {
    if (!location.hash) return;
    const childElement = document.getElementById("room-search-filters");
    const parentElement = document.getElementsByTagName("main")[0];

    if (childElement && parentElement) {
      const parentRect = parentElement.getBoundingClientRect();
      const childRect = childElement.getBoundingClientRect();

      const offsetTop =
        childRect.top - parentRect.top + parentElement.scrollTop;

      parentElement.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  }, [results]);

  return (
    <>
      <div className={styles.searchFilters} id="room-search-filters">
        <DateInput
          label={t("BOOKINGS.FILTERS.DATE")}
          value={date}
          onChange={(e) => handleDateInput(e.target.value as DateString)}
          minDate={currDate}
          maxDate={oneMonthFromNowDateString}
          compact
          lang={i18n.language}
        />
        <TimeInput
          label={t("BOOKINGS.FILTERS.START_TIME")}
          value={time}
          onChange={(e) => setTime(e.target.value as TimeString)}
          minTime={
            date === currDate &&
            now > timeInputStringToDate(DEFAULT_BOOKING_SETTINGS.minTime)
              ? dateToTimeInputString(getNextFullHalfHour())
              : DEFAULT_BOOKING_SETTINGS.minTime
          }
          maxTime={DEFAULT_BOOKING_SETTINGS.maxTime}
          compact
          step={1800} // only half hours
        />
        <StrictNumberInput
          label={t("BOOKINGS.FILTERS.DURATION")}
          value={duration}
          onChange={(e) => setDuration(Number(e.target.value))}
          append={
            <span style={{ marginLeft: -14, marginRight: 12, fontSize: 18 }}>
              {t("HOURS", { count: duration <= 1 ? 1 : 2 }).toLowerCase()}
            </span>
          }
          min={0.5}
          max={DEFAULT_BOOKING_SETTINGS.maxDuration}
          compact
          step={0.5}
          style={{ minWidth: 200 }}
        />
        <div style={{ width: "20%", minWidth: 250 }}>
          <Dropdown
            label={t("BOOKINGS.FILTERS.CAPACITY.LABEL")}
            placeholder={t("BOOKINGS.FILTERS.CAPACITY.PLACEHOLDER")}
            value={capacity}
            onChange={setCapacity}
            options={capacityOptions}
            icon="group"
            compact
            clearable
            maxMenuHeight={235}
            inputBgColor="#E9E9E9"
          />
        </div>
        <div style={{ width: "20%", minWidth: 250 }}>
          <Dropdown
            isMulti
            label={t("BOOKINGS.FILTERS.FEATURES.LABEL")}
            appendLabel={
              features.length && <strong>({features.length})</strong>
            }
            placeholder={t("BOOKINGS.FILTERS.FEATURES.PLACEHOLDER")}
            value={features}
            onChange={(feat) =>
              setFeatures(feat ? (feat as MeetingRoomFeature[]) : [])
            }
            options={MEETING_ROOM_FEATURE_OPTIONS.map((feat) => ({
              ...feat,
              label: t(`BOOKINGS.ROOM_FEATURES.${feat.label}`),
            }))}
            compact
            clearable
            maxMenuHeight={235}
            inputBgColor="#E9E9E9"
            selectChip={{
              style: { background: "var(--wlcm-color-inactive-grey)" },
              replaceLabelsWithIcons: true,
            }}
          />
        </div>
      </div>
      <AnimatePresence initial={false} mode="wait">
        {filtersError ? (
          <motion.div
            className={styles.noResults}
            key="error"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
          >
            <h3>{t("BOOKINGS.INVALID_FILTERS")}</h3>
            <p>{filtersError}</p>
          </motion.div>
        ) : isLoading || isFetching ? (
          <motion.div
            className={styles.cardsGrid}
            key="loading"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
            style={{ paddingBottom: "calc(var(--wlcm-spacing-xl) * 4)" }}
          >
            <div className="center">
              <LoadingSpinner />
            </div>
          </motion.div>
        ) : results?.length ? (
          <motion.div
            key="results"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
          >
            <h3
              style={{
                margin: "var(--wlcm-spacing-sm) 0 var(--wlcm-spacing-xs)",
              }}
            >
              {t("BOOKINGS.RESULTS")}{" "}
              <span>{t("RESULTS", { count: results.length })}</span>
            </h3>
            <div className={styles.cardsGrid}>
              {results.map((room) => (
                <MeetingRoomCard
                  key={room.id}
                  room={room}
                  onBookRoom={(room) => setSelectedRoom(room)}
                />
              ))}
            </div>
          </motion.div>
        ) : (
          <motion.div
            className={styles.noResults}
            key="noResults"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
          >
            <h3>{t("BOOKINGS.NO_RESULTS.HEADER")}</h3>
            <p>{t("BOOKINGS.NO_RESULTS.BODY")}</p>
          </motion.div>
        )}
      </AnimatePresence>

      {selectedRoom && (
        <BookingConfirmationModal
          room={selectedRoom}
          date={date}
          startTime={time}
          duration={duration}
          onClose={() => setSelectedRoom(undefined)}
        />
      )}
    </>
  );
}
