import { useBookedSlots, useCompany, useMeetingRooms } from "@/api";
import { Breadcrumbs, Button, Icon } from "@app-components";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ReactNode, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "@/styles/Bookings.module.scss";
import { MEETING_ROOM_FEATURES } from "@/lib/constants";
import Calendar from "@/components/bookings/calendar/Calendar";
import { SlotInfo } from "react-big-calendar";
import BookingConfirmationModal from "@/components/bookings/BookingConfirmationModal";
import { dateToDateInputString, dateToTimeInputString } from "@/lib/utils";
import { useLoggedInUser } from "@/providers/AuthProvider";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const customCarouselArrowPrev = (
  clickHandler: () => void,
  _hasPrev: boolean,
  label: string,
): ReactNode => (
  <button
    onClick={clickHandler}
    className={`${styles.carouselArrow} ${styles.carouselArrowPrev}`}
  >
    <Icon name="arrow_back" />
    <span className="sr-only">{label}</span>
  </button>
);
const customCarouselArrowNext = (
  clickHandler: () => void,
  _hasNext: boolean,
  label: string,
): ReactNode => (
  <button
    onClick={clickHandler}
    className={`${styles.carouselArrow} ${styles.carouselArrowNext}`}
  >
    <Icon name="arrow_forward" />
    <span className="sr-only">{label}</span>
  </button>
);

export const Route = createFileRoute("/bookings/room/$roomId")({
  component: MeetingRoomDetails,
});

function MeetingRoomDetails() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useLoggedInUser();
  const { data: company } = useCompany(user.buildingTenantId);
  const { roomId } = Route.useParams();
  const { data: rooms, isLoading, isError } = useMeetingRooms();
  const room = useMemo(
    () => rooms?.find((r) => r.id === roomId),
    [rooms, roomId],
  );
  const images = useMemo(
    () => [room?.thumbnail, room?.images].flat().filter(Boolean),
    [room],
  );
  const breadcrumbs = [
    { label: t("SIDEBAR.BOOKINGS"), path: "/bookings" },
    {
      label: room?.name || roomId,
      path: `/bookings/room/${roomId}`,
    },
  ];
  const [selectedSlot, setSelectedSlot] = useState<SlotInfo>();
  const { data: bookedSlots } = useBookedSlots({
    roomName: room?.name || "",
    userId: user.email || "",
    companyId: company.buildingTenantName,
  });

  if (isError || (rooms && !room))
    throw new Error("Failed to load meeting rooms");

  return isLoading ? (
    <div
      className="center"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        gap: "var(--wlcm-spacing-sm)",
        opacity: 0.5,
      }}
    >
      <Icon name="meeting_room" style={{ fontSize: "6rem" }} />
      <h1>
        {t("PAGE_LOADING.LOADING")} {t("SIDEBAR.BOOKINGS").toLowerCase()}
        ...
      </h1>
    </div>
  ) : (
    <div className={`pageWithoutBottomSpacing ${styles.container}`}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px var(--wlcm-spacing-sm)",
          flexWrap: "wrap",
          marginBottom: "var(--wlcm-spacing-sm)",
        }}
      >
        <Button
          secondary
          size="sm"
          icon="arrow_back"
          onClick={() => navigate({ to: "/bookings" })}
        >
          {t("BACK")}
        </Button>
        <Breadcrumbs
          levels={breadcrumbs}
          onNavigate={(path) => navigate({ to: path })}
        />
      </div>
      <h1>{room?.name}</h1>
      <div className={styles.roomInfoContainer}>
        {!!(room?.thumbnail || room?.images?.length) && (
          <div className={styles.imageCarouselContainer}>
            {images.length > 1 ? (
              <Carousel
                autoPlay
                infiniteLoop
                interval={5000}
                showThumbs={false}
                showStatus={false}
                renderArrowPrev={customCarouselArrowPrev}
                renderArrowNext={customCarouselArrowNext}
              >
                {images.map((image) => (
                  <div>
                    <img key={image} src={image} alt={room?.name} />
                  </div>
                ))}
              </Carousel>
            ) : (
              <img src={images[0]} alt={room?.name} />
            )}
          </div>
        )}
        <div className={styles.textContainer}>
          <div className={styles.quickInfo}>
            <p>
              <Icon name="location_on" /> {room?.floor}.{" "}
              {t("FLOOR").toLowerCase()}
              {room?.location && room.location[i18n.language as "en" | "no"]
                ? `, ${room.location[i18n.language as "en" | "no"]}`
                : ""}
            </p>
            <p>
              <Icon name="group" /> {room?.capacity}{" "}
              {t("PEOPLE", { count: room?.capacity }).toLowerCase()}
            </p>
          </div>
          <div>
            <h2 style={{ fontSize: "var(--wlcm-text-sm)" }}>
              {t("BOOKINGS.DESCRIPTION")}
            </h2>
            <p>{room?.description[i18n.language as "en" | "no"]}</p>
          </div>
          <div className={styles.largeFeaturesContainer}>
            <h2 className="sr-only">Features</h2>
            {room?.features.map((feature) => (
              <p className={styles.largeFeatureChip} key={feature}>
                <Icon name={MEETING_ROOM_FEATURES[feature]?.icon} />
                {t(
                  `BOOKINGS.ROOM_FEATURES.${MEETING_ROOM_FEATURES[feature]?.label}`,
                )}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.calendarContainer} id="availability">
        <h2>{t("BOOKINGS.AVAILABILITY.HEADER")}</h2>
        <p>
          <Trans
            i18nKey="BOOKINGS.AVAILABILITY.DESCRIPTION"
            components={{ bold: <strong></strong> }}
          />
        </p>
        <Calendar events={bookedSlots || []} onSlotSelected={setSelectedSlot} />
      </div>
      {selectedSlot && room && (
        <BookingConfirmationModal
          room={room}
          date={dateToDateInputString(selectedSlot.start)}
          startTime={dateToTimeInputString(selectedSlot.start)}
          endTime={dateToTimeInputString(selectedSlot.end)}
          onClose={() => setSelectedSlot(undefined)}
          editableTimes
        />
      )}
    </div>
  );
}
