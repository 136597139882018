import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import client, { throwCaughtError } from "../client";

export const uploadImage = async (image: string, userName?: string) => {
  try {
    const response = await client.post("/uploadImage", {
      image,
      ...(userName ? { userName } : {}),
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const uploadFile = async (file: string, companyName: string) => {
  try {
    const response = await client.post("/uploadFile", {
      file,
      companyName,
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const sendSMSMessage = async (
  recipientsPhoneNumber: string,
  messageBody: string,
  recipientLanguage?: string,
  senderName?: string,
  quickMessage?: boolean,
) => {
  try {
    const response = await client.post("/sendSms", {
      recipientsPhoneNumber,
      messageBody,
      recipientLanguage,
      senderName,
      quickMessage,
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const getIntercomHMAC = async (): Promise<string | undefined> => {
  try {
    const response = await client.post("/createHmac");
    return response.data.hash;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const getImageFunction = async (path: string) => {
  try {
    const response = await client.get(`/getImage?path=${path}`);
    return response.data.url;
  } catch (error) {
    console.error(error); //If issues with image, no need to stop everything else
    return "";
  }
};

export const getImageOptions = (path: string) => {
  return {
    queryKey: ["image", path],
    queryFn: () => getImageFunction(path),
  };
};

export const useImage = (path: string): UseSuspenseQueryResult<string> => {
  return useSuspenseQuery(getImageOptions(path));
};

export const getBlobFromUrl = async (url: string) => {
  try {
    const response = await client.get(`/getBlobFromUrl?url=${url}`, {
      responseType: "blob",
    });

    let fileExtension = "jpeg";
    const contentType = response.headers["content-type"];
    if (contentType.split("/")[0] === "image") {
      fileExtension = contentType.split("/")[1];
      if (fileExtension.includes("+")) {
        fileExtension = fileExtension.split("+")[0];
      }
    }
    const blob = new Blob([response.data], { type: contentType });
    return { blob, fileExtension };
  } catch (error) {
    throwCaughtError(error);
  }
};
