import {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  KeyboardEvent,
  FocusEvent as ReactFocusEvent,
} from "react";
import { Icon } from "../Icon";
import { styles } from "../InputField";
import { StatusText } from "../StatusText";

type DateString = string & { __brand: "DateString" };

const isDateString = (value: string): value is DateString => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(value);
};

const dateToDateInputString = (date: Date): DateString => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}` as DateString;
};

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export interface DateInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode;
  hideLabel?: boolean;
  value?: DateString;
  className?: string;
  /**
   * A date string in the format "YYYY-mm-dd".
   * Example: "2025-01-08"
   */
  minDate?: DateString;
  /**
   * A date string in the format "YYYY-mm-dd".
   * Example: "2025-01-08"
   */
  maxDate?: DateString;
  icon?: string;
  placeholder?: string;
  disabled?: boolean;
  focus?: boolean;
  autoFocus?: boolean;
  large?: boolean;
  danger?: boolean;
  success?: boolean;
  dark?: boolean;
  required?: boolean;
  compact?: boolean;
  inputBgColor?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: ReactFocusEvent<HTMLInputElement, Element>) => void;
  onFocus?: (e: ReactFocusEvent<HTMLInputElement, Element>) => void;
  dangerText?: string;
  successText?: string;
  infoText?: string;
  lang?: string;
}

export const DateInput = ({
  id,
  label,
  hideLabel,
  value,
  className,
  minDate,
  maxDate,
  icon = "date_range",
  placeholder,
  disabled,
  focus,
  autoFocus,
  large,
  danger,
  success,
  dark,
  required,
  compact,
  inputBgColor,
  onChange,
  onKeyDown,
  onKeyUp,
  onBlur,
  onFocus,
  dangerText,
  successText,
  infoText,
  lang = "no",
}: DateInputProps) => {
  if (minDate && !isDateString(minDate)) {
    throw new Error(
      "minDate must be empty or a date string in the format 'YYYY-mm-dd'",
    );
  }
  if (maxDate && !isDateString(maxDate)) {
    throw new Error(
      "maxDate must be empty or a date string in the format 'YYYY-mm-dd'",
    );
  }

  const weekDayOfValue = !value
    ? ""
    : capitalize(
        new Date(value).toLocaleDateString(lang === "no" ? "nb-NO" : "en-GB", {
          weekday: "short",
        }),
      );

  const canGoBack = () => {
    if (!minDate) return true;
    if (!value) return false;
    const selectedDate = new Date(value);
    const minDateAsDate = new Date(minDate);
    return selectedDate > minDateAsDate;
  };
  const canGoForward = () => {
    if (!maxDate) return true;
    if (!value) return false;
    const selectedDate = new Date(value);
    const maxDateAsDate = new Date(maxDate);
    return selectedDate < maxDateAsDate;
  };

  const handleGoBack = () => {
    if (!value || !canGoBack() || !onChange) return;
    const selectedDate = new Date(value);
    selectedDate.setDate(selectedDate.getDate() - 1);
    onChange({
      target: { value: dateToDateInputString(selectedDate) },
    } as unknown as ChangeEvent<HTMLInputElement>);
  };

  const handleGoForward = () => {
    if (!value || !canGoForward() || !onChange) return;
    const selectedDate = new Date(value);
    selectedDate.setDate(selectedDate.getDate() + 1);
    onChange({
      target: { value: dateToDateInputString(selectedDate) },
    } as unknown as ChangeEvent<HTMLInputElement>);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) onKeyDown(e);
  };

  const handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) onKeyUp(e);
  };

  const handleOnBlur = (e: ReactFocusEvent<HTMLInputElement, Element>) => {
    if (onBlur) onBlur(e);
  };

  const handleOnFocus = (e: ReactFocusEvent<HTMLInputElement, Element>) => {
    if (onFocus) onFocus(e);
  };

  return (
    <div
      className={`
    ${styles.inputContainer} 
   
    ${dark ? styles.dark : ""}`}
    >
      {!!label && (
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "end",
            justifyContent: "flex-start",
          }}
        >
          <label
            htmlFor={`${label}_INPUT`}
            className={hideLabel || (compact && hideLabel) ? "sr-only" : ""}
          >
            {label}
            {required && typeof label === "string" && (
              <>
                {" "}
                <strong aria-hidden>*</strong>
                <span className="sr-only">(required)</span>
              </>
            )}
          </label>
        </div>
      )}
      <div
        className={`
        ${className ? className : ""} 
        ${styles.inputField} 
        ${focus ? styles.focus : ""}
        ${large ? styles.large : ""}
        ${danger || (dangerText && dangerText.length > 0) ? styles.danger : ""}
        ${
          success || (successText && successText.length > 0)
            ? styles.success
            : ""
        }
        ${dark ? styles.dark : ""}
        ${disabled ? styles.disabled : ""}`}
        {...(inputBgColor ? { style: { backgroundColor: inputBgColor } } : {})}
      >
        <button
          className={styles.dateInputBtn}
          style={{ transform: "rotate(270deg)" }}
          disabled={!canGoBack()}
          onClick={handleGoBack}
        >
          <Icon name="expand_less" />
          <span className="sr-only">Previous date</span>
        </button>
        {icon && <Icon className={styles.icon} name={icon} />}
        {weekDayOfValue && (
          <span style={{ fontSize: 18 }}>{weekDayOfValue}</span>
        )}
        <input
          id={id || `${label}_INPUT`}
          autoFocus={autoFocus}
          className={styles.input}
          type="date"
          placeholder={placeholder}
          disabled={disabled}
          min={minDate}
          max={maxDate}
          required={required}
          value={value}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onKeyUp={handleOnKeyUp}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          style={{ marginRight: 0 }}
        />
        <button
          className={styles.dateInputBtn}
          style={{ transform: "rotate(90deg)", marginRight: 10 }}
          disabled={!canGoForward()}
          onClick={handleGoForward}
        >
          <Icon name="expand_less" />
          <span className="sr-only">Next date</span>
        </button>
      </div>
      <div className={styles.bottomContainer}>
        {!dangerText && !successText && !infoText && !compact ? (
          <div style={{ height: "24px" }}></div>
        ) : dangerText ? (
          <StatusText text={dangerText} fail dark={dark} />
        ) : successText ? (
          <StatusText text={successText} success dark={dark} />
        ) : (
          !!infoText && <StatusText text={infoText} dark={dark} />
        )}
      </div>
    </div>
  );
};
