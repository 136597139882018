import { Trans, useTranslation } from "react-i18next";
import { useContextStore } from "@/store/zustandStore";
import { WELCOME_VISITORS_URL } from "@/lib/constants";
import i18n from "@/i18n/config";
import { createFileRoute } from "@tanstack/react-router";
import {
  useCompanyVisitors,
  useCompanyPreregistrations,
  useAllEntrances,
  useCompany,
} from "@/api";
import { ReceptionView } from "@/components/ReceptionView";
import { Suspense } from "react";
import { LoadingSpinner } from "@app-components";

export const Route = createFileRoute("/company/visitors/")({
  component: CompanyVisitors,
});

function CompanyVisitors() {
  const { t } = useTranslation();
  const { selectedCompany } = useContextStore();
  const { data: company } = useCompany(selectedCompany?.value || "");
  const { data: companyVisitors } = useCompanyVisitors(
    selectedCompany?.value || "",
  );
  const { data: allEntrances } = useAllEntrances();
  const { data: preregistrations } = useCompanyPreregistrations(
    selectedCompany?.value || "",
  );

  const companyEntrances =
    allEntrances?.filter(
      (entrance) =>
        entrance.entranceId &&
        company.entranceIds.includes(entrance.entranceId),
    ) || [];

  return (
    <>
      <div className="pageHeader">
        <h1>{t("PAGES.COMPANY_VISITORS.HEADER")}</h1>
        <p>
          <Trans i18nKey="PAGES.COMPANY_VISITORS.SUBHEADER">
            Text
            <a href={WELCOME_VISITORS_URL(i18n.language)} target="_blank"></a>.
          </Trans>
        </p>
      </div>
      <Suspense
        fallback={
          <div className="center">
            <LoadingSpinner />
          </div>
        }
      >
        <ReceptionView
          visitors={companyVisitors}
          entrances={companyEntrances}
          preregistrationGroups={preregistrations}
          navigationPath="/company/visitors/preregistration"
          companyReception
        />
      </Suspense>
    </>
  );
}
