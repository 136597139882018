import { DOMAIN_REGEX, genericEmailDomains } from "@/lib/constants";
import { SSOItem } from "@/types";
import {
  Button,
  Dropdown,
  Icon,
  InputField,
  StatusText,
} from "@app-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "@/styles/SSOInput.module.scss";

interface SSOInputProps {
  value: SSOItem[];
  onChange: (value: SSOItem[]) => void;
  className?: string;
  externalError?: string;
  startWithEmptyInput?: boolean;
}

export default function SSOInput({
  className,
  value,
  onChange,
  externalError,
  startWithEmptyInput = false,
}: SSOInputProps) {
  const { t } = useTranslation();
  const [disableAdd, setDisableAdd] = useState(false);

  useEffect(() => {
    if (startWithEmptyInput && value.length === 0) {
      onChange([{ domain: "", provider: "Microsoft Azure" }]);
    }
  }, [startWithEmptyInput, value.length, onChange]);

  const addDomain = (e: Event) => {
    e.preventDefault();
    onChange([...value, { domain: "", provider: "Microsoft Azure" }]);
  };

  const handleChange = (
    index: number,
    newDomain: string,
    newProvider: string,
  ): string => {
    const foundIndex = value.findIndex((sso) => sso.domain === newDomain);
    if (foundIndex !== -1 && foundIndex !== index) {
      setDisableAdd(true);
      return t("ADD_COMPANY.DOMAINS.ALREADY_EXISTS");
    }

    onChange(
      value.map((sso, i) =>
        i === index ? { domain: newDomain, provider: newProvider } : sso,
      ),
    );

    setDisableAdd(false);
    return "";
  };

  const removeDomain = (index: number) => {
    if (index === value.length - 1) setDisableAdd(false);
    onChange(value.filter((_, i) => i !== index));
  };

  return (
    <div className={`${className} ${styles.container}`}>
      <p>{t("ADD_COMPANY.DOMAINS.HEADER")}</p>
      {value.map((sso, index) => (
        <div className={styles.inputsContainer} key={sso.domain + index}>
          <p>{index + 1}</p>
          <InputRow
            value={sso}
            onChange={(val) => handleChange(index, val.domain, val.provider)}
            onError={(hasError = true) => setDisableAdd(hasError)}
          />
          <Button danger dark secondary onClick={() => removeDomain(index)}>
            <Icon name="delete" />
          </Button>
        </div>
      ))}
      <Button onClick={addDomain} disabled={disableAdd}>
        <Icon name="add" />
        {t("ADD_COMPANY.DOMAINS.ADD")}
      </Button>
      {externalError && (
        <div
          style={{
            width: "100%",
          }}
        >
          <StatusText text={externalError} fail dark />
        </div>
      )}
    </div>
  );
}

interface InputRowProps {
  value: SSOItem;
  onChange: (value: SSOItem) => string;
  onError: (hasError?: boolean) => void;
}

const InputRow = ({ value, onChange, onError }: InputRowProps) => {
  const { t } = useTranslation();
  const [domain, setDomain] = useState(value.domain);
  const [error, setError] = useState("");

  const handleBlur = (val: string) => {
    const externalError = onChange({ domain: val, provider: value.provider });

    if (!val) {
      setError(
        t("VALIDATION.REQUIRED", { field: t("ADD_COMPANY.DOMAINS.DOMAIN") }),
      );
      onError();
    } else if (!DOMAIN_REGEX.test(domain)) {
      setError(
        t("VALIDATION.INVALID", { field: t("ADD_COMPANY.DOMAINS.DOMAIN") }),
      );
      onError();
    } else if (genericEmailDomains.includes(domain)) {
      setError(t("ADD_COMPANY.DOMAINS.GENERIC_DOMAIN"));
      onError();
    } else {
      setError(externalError);
    }
  };

  useEffect(() => {
    if (value.domain) {
      handleBlur(value.domain);
    }
  }, []);

  useEffect(() => {
    if (domain) {
      if (DOMAIN_REGEX.test(domain) && !genericEmailDomains.includes(domain)) {
        setError("");
        onError(false);
      }
    }
  }, [domain]);

  return (
    <div>
      <InputField
        autoFocus={!value.domain && !error}
        compact
        dark
        prepend="@"
        hideLabel
        label={t("ADD_COMPANY.DOMAINS.DOMAIN")}
        placeholder="example.com"
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
        danger={!!error}
        dangerText={error}
      />
      <Dropdown
        dark
        compact
        hideLabel
        label={t("ADD_COMPANY.DOMAINS.PROVIDER")}
        options={[
          { label: "Google", value: "Google" },
          { label: "Microsoft Azure", value: "Microsoft Azure" },
        ]}
        value={{ value: value.provider, label: value.provider }}
        onChange={(provider) => {
          onChange({ domain, provider: provider?.value || "Microsoft Azure" });
        }}
      />
    </div>
  );
};
