import {
  COMPANY_REGEX,
  MESSAGE_REGEX,
  NAME_REGEX,
  WELCOME_HELP_URL,
} from "@/lib/constants";
import { useForm, Validator } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "@/styles/BuildingSettings.module.scss";
import { Icon, ImageInput, InputField, SelectRow } from "@app-components";
import { AnimatePresence, motion } from "framer-motion";
import { Building, BuildingForm, NotificationType } from "@/types";
import i18n from "@/i18n/config";
import SaveResetButtons from "@/components/SaveResetButtons";
import { useSnackbarStore } from "@/store/zustandStore";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import EntrancesSection from "@/components/building_admin/building_admin_settings/EntrancesSection";
import AdminsSection from "@/components/building_admin/building_admin_settings/AdminsSection";
import { usePrompt } from "@/hooks/usePrompt";
import { createFileRoute } from "@tanstack/react-router";
import {
  getImageFunction,
  updateBuildingSettings,
  uploadImage,
  useBuilding,
  useImage,
} from "@/api";

export const Route = createFileRoute("/building/settings")({
  component: BuildingSettings,
});

const handleUploadImage = async (encodedImage: string): Promise<string> => {
  try {
    const imgUpload = await uploadImage(encodedImage);
    return imgUpload.url;
  } catch (error) {
    console.error(error);
    return "";
  }
};

function BuildingSettings() {
  const { t } = useTranslation();
  const buildingBannerInnerContainerRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const { data: building, error } = useBuilding();
  const { addNotification } = useSnackbarStore();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [welcomePreview, setWelcomePreview] = useState(false);
  const [bannerWidth, setBannerWidth] = useState(1000);
  const { data: ownerLogoImage } = useImage(building?.ownerLogoImgUrl);
  const { data: buildingBannerImage } = useImage(
    building?.buildingBannerImgUrl,
  );
  const { data: managerProfileImage } = useImage(
    building?.propertyManager.managerProfileImgUrl,
  );

  usePrompt({
    isOpen: hasUnsavedChanges,
  });

  const form = useForm<
    BuildingForm,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Validator<BuildingForm, z.ZodType<any, z.ZodTypeDef, any>>
  >({
    defaultValues: {
      legalName: building?.legalName || "",
      displayName: building?.displayName || "",
      owner: building?.owner || "",
      ownerLogoImgUrl: ownerLogoImage,
      ownerLogoImg: "",
      buildingBannerImgUrl: buildingBannerImage,
      buildingBannerImg: "",
      propertyManager: {
        ...building?.propertyManager,
        managerProfileImgUrl: managerProfileImage,
        managerProfileImg: "",
      },
      defaultAccessCardPickupMsg: building?.defaultAccessCardPickupMsg || {
        no: "",
        en: "",
      },
    },
    onSubmit: async ({ value }) => {
      let anyImgUploadFailed = false;

      if (value.buildingBannerImg) {
        const uploaded = await handleUploadImage(value.buildingBannerImg);
        console.log(uploaded);
        if (!uploaded) anyImgUploadFailed = true;
        const url = await getImageFunction(uploaded);
        value.buildingBannerImgUrl =
          url || building?.buildingBannerImgUrl || "";
      }
      if (value.ownerLogoImg) {
        const uploaded = await handleUploadImage(value.ownerLogoImg);
        if (!uploaded) anyImgUploadFailed = true;
        const url = await getImageFunction(uploaded);
        value.ownerLogoImgUrl = url || building?.ownerLogoImgUrl || "";
      }
      if (value.propertyManager.managerProfileImg) {
        const uploaded = await handleUploadImage(
          value.propertyManager.managerProfileImg,
        );
        if (!uploaded) anyImgUploadFailed = true;
        const url = await getImageFunction(uploaded);
        value.propertyManager.managerProfileImgUrl =
          url || building?.propertyManager.managerProfileImgUrl || "";
      }

      try {
        // Delete all encoded image data which is no longer needed
        const formValues = { ...value } as Partial<typeof value>;
        delete formValues.buildingBannerImg;
        delete formValues.ownerLogoImg;
        if (formValues.propertyManager)
          delete formValues.propertyManager.managerProfileImg;

        await updateBuildingSettings(formValues);
        setHasUnsavedChanges(false);
        if (anyImgUploadFailed) {
          addNotification(
            "One or more images failed to upload, but other settings saved successfully",
            NotificationType.WARNING,
          );
        } else {
          addNotification(
            "Building settings saved successfully",
            NotificationType.SUCCESS,
          );
        }
        queryClient.invalidateQueries({ queryKey: ["building"] });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error);
          addNotification(
            "Failed to save building settings changes. " + error.message,
          );
        } else {
          console.error(error);
          addNotification("An unexpected error occurred.");
        }
      }
    },
    validatorAdapter: zodValidator(),
  });

  useEffect(() => {
    if (buildingBannerInnerContainerRef) {
      setBannerWidth(
        buildingBannerInnerContainerRef.current?.offsetWidth ?? 1000,
      );
    }
  }, [buildingBannerInnerContainerRef]);

  if (!building || error) {
    throw new Error("Unable to load building settings", { cause: error });
  }

  return (
    <>
      <div className="pageHeader">
        <h1>{t("PAGES.BUILDING_SETTINGS.HEADER")}</h1>
        <p>
          <Trans i18nKey="PAGES.BUILDING_SETTINGS.SUBHEADER">
            Text
            <a href={WELCOME_HELP_URL(i18n.language)} target="_blank"></a>.
          </Trans>
        </p>
      </div>
      <form
        onChange={() => setHasUnsavedChanges(true)}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        className={styles.container}
      >
        <form.Field
          name="displayName"
          validators={{
            onChange: z
              .string()
              .min(
                1,
                t("VALIDATION.REQUIRED", {
                  field: t("BUILDING_SETTINGS.DISPLAY_NAME"),
                }),
              )
              .min(
                2,
                t("VALIDATION.MIN_LENGTH", {
                  field: t("BUILDING_SETTINGS.DISPLAY_NAME"),
                  length: 2,
                  type: t("CHARACTERS"),
                }),
              )
              .max(
                20,
                t("VALIDATION.MAX_LENGTH", {
                  field: t("BUILDING_SETTINGS.DISPLAY_NAME"),
                  length: "20",
                  type: t("CHARACTERS"),
                }),
              )
              .trim()
              .refine((value) => COMPANY_REGEX.test(value), {
                message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
              }),
          }}
        >
          {(field) => (
            <InputField
              label={t("BUILDING_SETTINGS.DISPLAY_NAME")}
              maxLength={20}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              dangerText={field.state.meta.errors.toString()}
              infoText={t("BUILDING_SETTINGS.DISPLAY_NAME_RECOMMENDATION")}
            />
          )}
        </form.Field>
        <form.Field
          name="legalName"
          validators={{
            onChange: z
              .string()
              .min(
                1,
                t("VALIDATION.REQUIRED", {
                  field: t("BUILDING_SETTINGS.LEGAL_NAME"),
                }),
              )
              .min(
                2,
                t("VALIDATION.MIN_LENGTH", {
                  field: t("BUILDING_SETTINGS.LEGAL_NAME"),
                  length: 2,
                  type: t("CHARACTERS"),
                }),
              )
              .max(
                100,
                t("VALIDATION.MAX_LENGTH", {
                  field: t("BUILDING_SETTINGS.LEGAL_NAME"),
                  length: "100",
                  type: t("CHARACTERS"),
                }),
              )
              .trim()
              .refine((value) => COMPANY_REGEX.test(value), {
                message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
              }),
          }}
        >
          {(field) => (
            <InputField
              label={t("BUILDING_SETTINGS.LEGAL_NAME")}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              dangerText={field.state.meta.errors.toString()}
            />
          )}
        </form.Field>

        <hr className={styles.sectionDivider} />

        <AdminsSection />

        <hr className={styles.sectionDivider} />

        <section style={{ display: "contents" }}>
          <div className={`${styles.fullWidth} ${styles.sectionWithButton}`}>
            <h2>{t("BUILDING_SETTINGS.WELCOME_SETTINGS")}</h2>
            <p className={styles.description}>
              {t("BUILDING_SETTINGS.WELCOME_DESCRIPTION")}
            </p>
            <div
              className={styles.sectionAction}
              onChange={(e) => e.stopPropagation()}
            >
              <SelectRow
                label="Mode"
                hideLabel
                name="welcomeSectionMode"
                value={welcomePreview}
                options={[
                  { value: false, label: t("BUILDING_SETTINGS.BACK_TO_EDIT") },
                  { value: true, label: t("BUILDING_SETTINGS.SEE_PREVIEW") },
                ]}
                onChange={(val) => setWelcomePreview(!!val)}
              />
            </div>
          </div>
          <div className={styles.welcomeScreenMock}>
            <div className={styles.bannerContainer}>
              {welcomePreview ? (
                <div className={styles.bannerPreviewContainer}>
                  <img
                    src={form.getFieldValue("buildingBannerImgUrl")}
                    alt={t("BUILDING_SETTINGS.BANNER")}
                  />
                </div>
              ) : (
                <div
                  className={styles.bannerInnerContainer}
                  ref={buildingBannerInnerContainerRef}
                >
                  <form.Field name="buildingBannerImgUrl">
                    {(field) => (
                      <ImageInput
                        key={field.state.value}
                        id="building-banner"
                        strategy="cover"
                        placeholder={
                          <div className={styles.buildingBannerPlaceholder}>
                            <Icon name="domain" />
                            <p>{t("IMAGE.PLACEHOLDER.BANNER")}</p>
                          </div>
                        }
                        maxFileSize={10 * 1024 * 1024}
                        allowedFileTypes={[
                          ".jpg",
                          ".jpeg",
                          ".png",
                          ".webp",
                          ".svg",
                        ]}
                        translations={{
                          uploadImageOverlayText: t("IMAGE.UPLOAD"),
                          replaceImageAriaLabel: t("IMAGE.REPLACE.ARIA_LABEL"),
                          replaceImageOverlayText: t("IMAGE.REPLACE.TEXT"),
                          undoChangeButtonText: t("IMAGE.UNDO"),
                          editDoneText: t("IMAGE.DONE"),
                          editCancelText: t("IMAGE.CANCEL"),
                          fileTooLargeText: t("IMAGE.FILE_TOO_LARGE"),
                        }}
                        onChange={(image) => {
                          field.handleChange(image.previewUrl);
                          form.setFieldValue(
                            "buildingBannerImg",
                            image.encoded,
                          );
                          setHasUnsavedChanges(true);
                        }}
                        onError={(error) => addNotification(error)}
                        onUndo={(imgUrl) => {
                          field.handleChange(imgUrl);
                          form.setFieldValue("buildingBannerImg", "");
                        }}
                        originalImageUrl={buildingBannerImage}
                        currImageUrl={field.state.value}
                        menuPlacement="top"
                        cropSize={{
                          height: 300,
                          width: bannerWidth,
                        }}
                        style={{
                          previewStyle: {
                            container: {
                              width: "100%",
                              height: "300px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              overflow: "hidden",
                              borderRadius:
                                "var(--wlcm-border-radius-sm) var(--wlcm-border-radius-sm) 0 0",
                              backgroundColor: "black",
                            },
                            image: {
                              width: "100%",
                              minHeight: 300,
                              objectFit: "cover",
                              opacity: 0.5,
                            },
                          },
                          overlayStyle: {
                            borderRadius: "var(--wlcm-border-radius-sm)",
                            justifyContent: "start",
                            paddingTop: "var(--wlcm-spacing-md)",
                          },
                          containerStyle: {
                            height: 300,
                          },
                        }}
                      />
                    )}
                  </form.Field>
                </div>
              )}
            </div>
            <div className={styles.welcomeScreenContent}>
              <div className={styles.welcomeScreenInputs}>
                <AnimatePresence initial={false} mode="wait">
                  {welcomePreview ? (
                    <motion.div
                      key="preview"
                      initial={{ opacity: 0, y: 30 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 30 }}
                    >
                      <p>
                        <Trans i18nKey="PAGES.WELCOME.HEADER">
                          Welcome to
                          <span>
                            <>{{ buildingName: building.displayName }}</>
                          </span>
                        </Trans>
                      </p>
                      <div className={styles.managerProfileContainer}>
                        <img
                          src={form.getFieldValue(
                            "propertyManager.managerProfileImgUrl",
                          )}
                          alt={`${form.getFieldValue(
                            "propertyManager.firstName",
                          )} ${form.getFieldValue("propertyManager.lastName")}`}
                          height="150"
                          width="150"
                        />
                      </div>
                      <div className={styles.managerGreeting}>
                        <Trans
                          i18nKey="PAGES.WELCOME.GREETING"
                          values={{
                            userName: "Ola",
                            buildingAdminName: `${building.propertyManager.firstName} ${building.propertyManager.lastName}`,
                            buildingAdminPositionLC:
                              building.propertyManager.position[
                                i18n.language as keyof Building["propertyManager"]["position"]
                              ].toLowerCase(),
                            buildingName: building.displayName,
                            ownerName: building.owner,
                            buildingAdminPosition:
                              building.propertyManager.position[
                                i18n.language as keyof Building["propertyManager"]["position"]
                              ],
                            signature: `${Array.from(building.propertyManager.firstName)[0]}. ${building.propertyManager.lastName}`,
                          }}
                          components={{
                            paragraph: <p></p>,
                            bold: <strong></strong>,
                            linebreak: <br />,
                            regards: <div className={styles.regards}></div>,
                            signature: (
                              <p
                                className={`no-select ${styles.signature}`}
                              ></p>
                            ),
                          }}
                        ></Trans>
                      </div>
                      <div className={styles.previewLogoContainer}>
                        <img
                          src={form.getFieldValue("ownerLogoImgUrl")}
                          alt={form.getFieldValue("owner") + " logo"}
                        />
                      </div>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="inputs"
                      initial={{ opacity: 0, y: 30 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 30 }}
                    >
                      <form.Field
                        name="owner"
                        validators={{
                          onChange: z
                            .string()
                            .min(
                              1,
                              t("VALIDATION.REQUIRED", {
                                field: t("BUILDING_SETTINGS.OWNER"),
                              }),
                            )
                            .min(
                              2,
                              t("VALIDATION.MIN_LENGTH", {
                                field: t("BUILDING_SETTINGS.OWNER"),
                                length: 2,
                                type: t("CHARACTERS"),
                              }),
                            )
                            .max(
                              100,
                              t("VALIDATION.MAX_LENGTH", {
                                field: t("BUILDING_SETTINGS.OWNER"),
                                length: "100",
                                type: t("CHARACTERS"),
                              }),
                            )
                            .trim()
                            .refine((value) => COMPANY_REGEX.test(value), {
                              message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                            }),
                        }}
                      >
                        {(field) => (
                          <InputField
                            dark
                            label={t("BUILDING_SETTINGS.OWNER")}
                            value={field.state.value}
                            onBlur={field.handleBlur}
                            onChange={(e) => field.handleChange(e.target.value)}
                            dangerText={field.state.meta.errors.toString()}
                          />
                        )}
                      </form.Field>
                      <div className={styles.managerProfileContainer}>
                        <form.Field name="propertyManager.managerProfileImgUrl">
                          {(field) => (
                            <ImageInput
                              key={field.state.value}
                              id="manager-profile"
                              placeholder={
                                <div
                                  className={styles.managerProfilePlaceholder}
                                >
                                  <Icon name="add_photo" />
                                  <p>{t("IMAGE.PLACEHOLDER.PROFILE")}</p>
                                </div>
                              }
                              allowedFileTypes={[".jpg", ".jpeg", ".png"]}
                              translations={{
                                uploadImageOverlayText: t("IMAGE.UPLOAD"),
                                replaceImageAriaLabel: t(
                                  "IMAGE.REPLACE.ARIA_LABEL",
                                ),
                                replaceImageOverlayText:
                                  t("IMAGE.REPLACE.TEXT"),
                                undoChangeButtonText: t("IMAGE.UNDO"),
                                editDoneText: t("IMAGE.DONE"),
                                editCancelText: t("IMAGE.CANCEL"),
                                fileTooLargeText: t("IMAGE.FILE_TOO_LARGE"),
                              }}
                              onChange={(image) => {
                                field.handleChange(image.previewUrl);
                                form.setFieldValue(
                                  "propertyManager.managerProfileImg",
                                  image.encoded,
                                );
                                setHasUnsavedChanges(true);
                              }}
                              onError={(error) => addNotification(error)}
                              onUndo={(imgUrl) => {
                                field.handleChange(imgUrl);
                                form.setFieldValue(
                                  "propertyManager.managerProfileImg",
                                  "",
                                );
                              }}
                              originalImageUrl={managerProfileImage}
                              currImageUrl={field.state.value}
                              cropSize={{ height: 150, width: 150 }}
                              style={{
                                previewStyle: {
                                  image: {
                                    borderRadius:
                                      "var(--wlcm-border-radius-sm)",
                                    overflow: "hidden",
                                  },
                                },
                                overlayStyle: {
                                  borderRadius: "var(--wlcm-border-radius-sm)",
                                },
                              }}
                            />
                          )}
                        </form.Field>
                      </div>
                      <fieldset>
                        <legend>{t("BUILDING_SETTINGS.MANAGER.LABEL")}</legend>
                        <div className={styles.fullWidth}>
                          <p className={styles.description}>
                            {t("BUILDING_SETTINGS.MANAGER.DESCRIPTION")}
                          </p>
                        </div>
                        <form.Field
                          name="propertyManager.firstName"
                          validators={{
                            onChange: z
                              .string()
                              .min(
                                1,
                                t("VALIDATION.REQUIRED", {
                                  field: t("ADD_USER.FIRST_NAME"),
                                }),
                              )
                              .min(
                                2,
                                t("VALIDATION.MIN_LENGTH", {
                                  field: t("ADD_USER.FIRST_NAME"),
                                  length: 2,
                                  type: t("CHARACTERS"),
                                }),
                              )
                              .max(
                                30,
                                t("VALIDATION.MAX_LENGTH", {
                                  field: t("ADD_USER.FIRST_NAME"),
                                  length: "30",
                                  type: t("CHARACTERS"),
                                }),
                              )
                              .trim()
                              .refine((value) => NAME_REGEX.test(value), {
                                message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                              }),
                          }}
                        >
                          {(field) => (
                            <InputField
                              dark
                              label={t("ADD_USER.FIRST_NAME")}
                              value={field.state.value}
                              onBlur={field.handleBlur}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              dangerText={field.state.meta.errors.toString()}
                            />
                          )}
                        </form.Field>
                        <form.Field
                          name="propertyManager.lastName"
                          validators={{
                            onChange: z
                              .string()
                              .min(
                                1,
                                t("VALIDATION.REQUIRED", {
                                  field: t("ADD_USER.LAST_NAME"),
                                }),
                              )
                              .min(
                                2,
                                t("VALIDATION.MIN_LENGTH", {
                                  field: t("ADD_USER.LAST_NAME"),
                                  length: 2,
                                  type: t("CHARACTERS"),
                                }),
                              )
                              .max(
                                30,
                                t("VALIDATION.MAX_LENGTH", {
                                  field: t("ADD_USER.LAST_NAME"),
                                  length: "30",
                                  type: t("CHARACTERS"),
                                }),
                              )
                              .trim()
                              .refine((value) => NAME_REGEX.test(value), {
                                message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                              }),
                          }}
                        >
                          {(field) => (
                            <InputField
                              dark
                              label={t("ADD_USER.LAST_NAME")}
                              value={field.state.value}
                              onBlur={field.handleBlur}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              dangerText={field.state.meta.errors.toString()}
                            />
                          )}
                        </form.Field>
                        <form.Field
                          name="propertyManager.position.no"
                          validators={{
                            onChange: z
                              .string()
                              .min(
                                1,
                                t("VALIDATION.REQUIRED", {
                                  field: t(
                                    "BUILDING_SETTINGS.MANAGER.POSITION_NB",
                                  ),
                                }),
                              )
                              .min(
                                2,
                                t("VALIDATION.MIN_LENGTH", {
                                  field: t(
                                    "BUILDING_SETTINGS.MANAGER.POSITION_NB",
                                  ),
                                  length: 2,
                                  type: t("CHARACTERS"),
                                }),
                              )
                              .max(
                                30,
                                t("VALIDATION.MAX_LENGTH", {
                                  field: t(
                                    "BUILDING_SETTINGS.MANAGER.POSITION_NB",
                                  ),
                                  length: "30",
                                  type: t("CHARACTERS"),
                                }),
                              )
                              .trim()
                              .refine((value) => NAME_REGEX.test(value), {
                                message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                              }),
                          }}
                        >
                          {(field) => (
                            <InputField
                              dark
                              label={t("BUILDING_SETTINGS.MANAGER.POSITION_NB")}
                              value={field.state.value}
                              onBlur={field.handleBlur}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              dangerText={field.state.meta.errors.toString()}
                            />
                          )}
                        </form.Field>
                        <form.Field
                          name="propertyManager.position.en"
                          validators={{
                            onChange: z
                              .string()
                              .min(
                                1,
                                t("VALIDATION.REQUIRED", {
                                  field: t(
                                    "BUILDING_SETTINGS.MANAGER.POSITION_EN",
                                  ),
                                }),
                              )
                              .min(
                                2,
                                t("VALIDATION.MIN_LENGTH", {
                                  field: t(
                                    "BUILDING_SETTINGS.MANAGER.POSITION_EN",
                                  ),
                                  length: 2,
                                  type: t("CHARACTERS"),
                                }),
                              )
                              .max(
                                30,
                                t("VALIDATION.MAX_LENGTH", {
                                  field: t(
                                    "BUILDING_SETTINGS.MANAGER.POSITION_EN",
                                  ),
                                  length: "30",
                                  type: t("CHARACTERS"),
                                }),
                              )
                              .trim()
                              .refine((value) => NAME_REGEX.test(value), {
                                message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                              }),
                          }}
                        >
                          {(field) => (
                            <InputField
                              dark
                              label={t("BUILDING_SETTINGS.MANAGER.POSITION_EN")}
                              value={field.state.value}
                              onBlur={field.handleBlur}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              dangerText={field.state.meta.errors.toString()}
                            />
                          )}
                        </form.Field>
                      </fieldset>
                      <div className={styles.logoContainer}>
                        <form.Field name="ownerLogoImgUrl">
                          {(field) => (
                            <ImageInput
                              key={field.state.value}
                              id="owner-logo"
                              placeholder={
                                <div className={styles.ownerLogoPlaceholder}>
                                  <Icon name="add_photo" />
                                  <p>{t("IMAGE.PLACEHOLDER.OWNER_LOGO")}</p>
                                </div>
                              }
                              translations={{
                                uploadImageOverlayText: t("IMAGE.UPLOAD"),
                                replaceImageAriaLabel: t(
                                  "IMAGE.REPLACE.ARIA_LABEL",
                                ),
                                replaceImageOverlayText:
                                  t("IMAGE.REPLACE.TEXT"),
                                undoChangeButtonText: t("IMAGE.UNDO"),
                                editDoneText: t("IMAGE.DONE"),
                                editCancelText: t("IMAGE.CANCEL"),
                                fileTooLargeText: t("IMAGE.FILE_TOO_LARGE"),
                              }}
                              onChange={(image) => {
                                field.handleChange(image.previewUrl);
                                form.setFieldValue(
                                  "ownerLogoImg",
                                  image.encoded,
                                );
                                setHasUnsavedChanges(true);
                              }}
                              onError={(error) => addNotification(error)}
                              onUndo={(imgUrl) => {
                                field.handleChange(imgUrl);
                                form.setFieldValue("ownerLogoImg", "");
                              }}
                              originalImageUrl={ownerLogoImage}
                              currImageUrl={field.state.value}
                              strategy="cover"
                              style={{
                                previewStyle: {
                                  image: {
                                    maxHeight: 30,
                                    maxWidth: 150,
                                  },
                                },
                                overlayStyle: {
                                  borderRadius: "var(--wlcm-border-radius-sm)",
                                },
                              }}
                            />
                          )}
                        </form.Field>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </section>

        <hr className={styles.sectionDivider} />

        <section className={styles.fullWidth}>
          <div>
            <h2>{t("BUILDING_SETTINGS.ACCESS_CONTROL.HEADER")}</h2>
            <p className={styles.description}>
              {t("BUILDING_SETTINGS.ACCESS_CONTROL.DESCRIPTION")}
            </p>
          </div>
          <div>
            <fieldset className={styles.accessControlFieldset}>
              <h3>
                {t("BUILDING_SETTINGS.ACCESS_CONTROL.PICKUP_MESSAGE.LABEL")}
              </h3>
              <p>
                {t(
                  "BUILDING_SETTINGS.ACCESS_CONTROL.PICKUP_MESSAGE.DESCRIPTION",
                )}
              </p>
              <form.Field
                name="defaultAccessCardPickupMsg.no"
                validators={{
                  onChange: z
                    .string()
                    .max(
                      150,
                      t("VALIDATION.MAX_LENGTH", {
                        field: t("ACCESS_CARD.MESSAGE"),
                        length: "150",
                        type: t("CHARACTERS"),
                      }),
                    )
                    .trim()
                    .refine((value) => MESSAGE_REGEX.test(value), {
                      message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                    }),
                }}
              >
                {(field) => (
                  <InputField
                    maxLength={150}
                    label={t(
                      "BUILDING_SETTINGS.ACCESS_CONTROL.PICKUP_MESSAGE.MESSAGE_NB",
                    )}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                )}
              </form.Field>
              <form.Field
                name="defaultAccessCardPickupMsg.en"
                validators={{
                  onChange: z
                    .string()
                    .max(
                      150,
                      t("VALIDATION.MAX_LENGTH", {
                        field: t("ACCESS_CARD.MESSAGE"),
                        length: "150",
                        type: t("CHARACTERS"),
                      }),
                    )
                    .trim()
                    .refine((value) => MESSAGE_REGEX.test(value), {
                      message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                    }),
                }}
              >
                {(field) => (
                  <InputField
                    maxLength={150}
                    label={t(
                      "BUILDING_SETTINGS.ACCESS_CONTROL.PICKUP_MESSAGE.MESSAGE_EN",
                    )}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                )}
              </form.Field>
            </fieldset>
          </div>
        </section>

        <hr className={styles.sectionDivider} />

        <EntrancesSection />

        <SaveResetButtons
          form={form}
          showButtons={hasUnsavedChanges}
          onHideButtons={() => setHasUnsavedChanges(false)}
          extraCanSubmit={hasUnsavedChanges}
        />
      </form>
    </>
  );
}
