import { CardStatus, MeetingRoomFeature, TimeString } from "@/types";
import { env } from "./utils";
import { addMonths } from "date-fns";

export const ADDRESS_REGEX = /\w+(\s\w+){1,5}$/u;
export const DOMAIN_REGEX = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
export const NUMERIC_REGEX = /^[0-9+]+$/;
export const MESSAGE_REGEX = /^[\p{L}\p{N}\s!'\-&()?,."]*$/u;
export const NAME_REGEX = /^[\p{L}\s\-&.]*$/u;
export const COMPANY_REGEX = /^[\p{L}\p{N}\s\-&.]*$/u;
export const PHONE_REGEX = /^[+]([0-9]{3,15})$/;
export const ORG_NUMBER_REGEX = /^[0-9]{9}$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const WELCOME_BASE_HELP_URL = "https://help.welcomeworkdays.com/";

export const WELCOME_HELP_URL = (language: string) => {
  const base = "https://help.welcomeworkdays.com/";
  return language === "no" ? `${base}nb/` : `${base}${language}/`;
};

export const WELCOME_USER_ADMINISTRATION_URL = (lang: string) =>
  `${WELCOME_BASE_HELP_URL}${lang}/collections/316566-funksjonsbeskrivelser`;

export const WELCOME_VISITORS_URL = (lang: string) =>
  `${WELCOME_BASE_HELP_URL}${lang}/collections/322346-besoksregistrering`;

export const WELCOME_TERMS_OF_USE_URL = (lang: string) =>
  `${WELCOME_BASE_HELP_URL}${lang}/articles/220297-vilkar-for-bruk-av-welcome-workdays-tjenesteplattform`;

export const WELCOME_PRIVACY_POLICY_URL = (lang: string) =>
  `${WELCOME_BASE_HELP_URL}${lang}/articles/171672-personvernerklaering-for-welcome-workdays`;

export const WELCOME_LOGIN_METHOD_HELP_URL = (lang: string) =>
  `${WELCOME_BASE_HELP_URL}${lang}/articles/240918-choosing-a-login-method-sso-vs-passwordless-email-sign-on`;

const environment = env("VITE_ENVIRONMENT") as "dev" | "prod" | "preview";
const project = env("VITE_FB_PROJECT");
export const REAL_ESTATE = env("VITE_REAL_ESTATE");
if (
  environment !== "dev" &&
  environment !== "prod" &&
  environment !== "preview"
) {
  throw new Error("Invalid environment");
}

export const APP_DOMAIN = () => {
  const domainMap = {
    dev: `https://portal-${project}.web.app`,
    prod: `https://${REAL_ESTATE}.welcomeworkdays.com`,
    preview: `https://${REAL_ESTATE}.welcomeworkdays.dev`,
  };

  return domainMap[environment];
};

export const WELCOME_VISIT_KIOSK_URL = (id: string) => {
  const domainMap = {
    dev: `https://visit-${project}.web.app?entranceId=${id}`,
    prod: `https://visit-${REAL_ESTATE}.welcomeworkdays.com?entranceId=${id}`,
    preview: `https://visit-${REAL_ESTATE}.welcomeworkdays.dev?entranceId=${id}`,
  };

  return domainMap[environment];
};

export const accessCardStatusColorMap = {
  [CardStatus.ORDERED]: "var(--wlcm-color-malling-purple)",
  [CardStatus.ACTIVE]: "var(--wlcm-color-success-green)",
  [CardStatus.INACTIVE]: "var(--wlcm-color-warning-orange)",
  [CardStatus.DEACTIVATED]: "var(--wlcm-color-dark-inactive-grey)",
  [CardStatus.CANCELLED]: "var(--wlcm-color-dark-inactive-grey)",
};

export const MEETING_ROOM_FEATURES = {
  speaker: {
    label: "SPEAKER",
    icon: "speaker",
  },
  tv: {
    label: "TV",
    icon: "desktop",
  },
  whiteboard: {
    label: "WHITEBOARD",
    icon: "draw",
  },
  webcam: {
    label: "WEBCAM",
    icon: "webcam",
  },
  dual_monitors: {
    label: "DUAL_MONITORS",
    icon: "tv_displays",
  },
  wireless_screenshare: {
    label: "WIRELESS_SCREENSHARE",
    icon: "cast",
  },
};

export const MEETING_ROOM_FEATURE_OPTIONS: MeetingRoomFeature[] =
  Object.entries(MEETING_ROOM_FEATURES).map(([key, value]) => ({
    value: key,
    label: value.label,
    icon: value.icon,
  }));

export const DEFAULT_BOOKING_SETTINGS = {
  minTime: "00:00" as TimeString,
  maxTime: "23:30" as TimeString,
  minDate: new Date(),
  maxDate: addMonths(new Date(), 1),
  maxDuration: 8,
  maxBookingsPerPerson: 8,
};

export const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const CALENDAR_TIME_ZONE = "Europe/Oslo";

export const genericEmailDomains = [
  "aol.com",
  "att.net",
  "comcast.net",
  "facebook.com",
  "gmail.com",
  "gmx.com",
  "googlemail.com",
  "google.com",
  "hotmail.com",
  "hotmail.co.uk",
  "mac.com",
  "me.com",
  "mail.com",
  "msn.com",
  "live.com",
  "sbcglobal.net",
  "verizon.net",
  "yahoo.com",
  "yahoo.co.uk",
  "email.com",
  "fastmail.fm",
  "games.com",
  "gmx.net",
  "hush.com",
  "hushmail.com",
  "icloud.com",
  "iname.com",
  "inbox.com",
  "lavabit.com",
  "love.com",
  "outlook.com",
  "pobox.com",
  "protonmail.ch",
  "protonmail.com",
  "tutanota.de",
  "tutanota.com",
  "tutamail.com",
  "tuta.io",
  "keemail.me",
  "rocketmail.com",
  "safe-mail.net",
  "wow.com",
  "ygm.com",
  "ymail.com",
  "zoho.com",
  "yandex.com",
  "bellsouth.net",
  "charter.net",
  "cox.net",
  "earthlink.net",
  "juno.com",
  "btinternet.com",
  "virginmedia.com",
  "blueyonder.co.uk",
  "freeserve.co.uk",
  "live.co.uk",
  "ntlworld.com",
  "o2.co.uk",
  "orange.net",
  "sky.com",
  "talktalk.co.uk",
  "tiscali.co.uk",
  "virgin.net",
  "wanadoo.co.uk",
  "bt.com",
  "sina.com",
  "sina.cn",
  "qq.com",
  "naver.com",
  "hanmail.net",
  "daum.net",
  "nate.com",
  "yahoo.co.jp",
  "yahoo.co.kr",
  "yahoo.co.id",
  "yahoo.co.in",
  "yahoo.com.sg",
  "yahoo.com.ph",
  "163.com",
  "yeah.net",
  "126.com",
  "21cn.com",
  "aliyun.com",
  "foxmail.com",
  "hotmail.fr",
  "live.fr",
  "laposte.net",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "gmx.fr",
  "sfr.fr",
  "neuf.fr",
  "free.fr",
  "gmx.de",
  "hotmail.de",
  "live.de",
  "online.de",
  "t-online.de",
  "web.de",
  "yahoo.de",
  "libero.it",
  "virgilio.it",
  "hotmail.it",
  "aol.it",
  "tiscali.it",
  "alice.it",
  "live.it",
  "yahoo.it",
  "email.it",
  "tin.it",
  "poste.it",
  "teletu.it",
  "mail.ru",
  "rambler.ru",
  "yandex.ru",
  "ya.ru",
  "list.ru",
  "hotmail.be",
  "live.be",
  "skynet.be",
  "voo.be",
  "tvcablenet.be",
  "telenet.be",
  "hotmail.com.ar",
  "live.com.ar",
  "yahoo.com.ar",
  "fibertel.com.ar",
  "speedy.com.ar",
  "arnet.com.ar",
  "yahoo.com.mx",
  "live.com.mx",
  "hotmail.es",
  "hotmail.com.mx",
  "prodigy.net.mx",
  "yahoo.ca",
  "hotmail.ca",
  "bell.net",
  "shaw.ca",
  "sympatico.ca",
  "rogers.com",
  "yahoo.com.br",
  "hotmail.com.br",
  "outlook.com.br",
  "uol.com.br",
  "bol.com.br",
  "terra.com.br",
  "ig.com.br",
  "itelefonica.com.br",
  "r7.com",
  "zipmail.com.br",
  "globo.com",
  "globomail.com",
  "oi.com.br",
];
