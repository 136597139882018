import {
  Button,
  Icon,
  InputField,
  PopupCard,
  StatusText,
  StrictNumberInput,
  TimeInput,
} from "@app-components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "@/styles/Bookings.module.scss";
import { DateString, MeetingRoom, NotificationType, TimeString } from "@/types";
import {
  BOOKING_DATE_LOCALE,
  calculateEndTime,
  dateToTimeInputString,
  formatDateToTimezonedString,
  isSlotOverlapping,
  timeInputStringToDate,
} from "@/lib/utils";
import { useLoggedInUser } from "@/providers/AuthProvider";
import {
  createMeetingRoomBooking,
  useBookedSlots,
  useCompany,
  useUserBookings,
} from "@/api";
import {
  DEFAULT_BOOKING_SETTINGS,
  MEETING_ROOM_FEATURES,
} from "@/lib/constants";
import { Tooltip } from "react-tooltip";
import { useSnackbarStore } from "@/store/zustandStore";
import { differenceInMinutes, isAfter, isBefore, isSameDay } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";

const now = new Date();

type CommonProps = {
  onClose: () => void;
  room: MeetingRoom;
  date: DateString;
  startTime: TimeString;
};
type WithDuration = CommonProps & {
  duration: number;
  endTime?: never;
};
type WithEndTime = CommonProps & {
  endTime: TimeString;
  duration?: never;
};
type EditableTimes = CommonProps & {
  editableTimes?: boolean;
  minTime?: TimeString;
  maxTime?: TimeString;
  maxDuration?: number;
  maxBookingsPerPerson?: number;
};
type BookingConfirmationModalProps = EditableTimes &
  (WithDuration | WithEndTime);

export default function BookingConfirmationModal({
  onClose,
  room,
  date,
  startTime,
  duration,
  endTime,
  editableTimes,
  minTime = DEFAULT_BOOKING_SETTINGS.minTime,
  maxTime = DEFAULT_BOOKING_SETTINGS.maxTime,
  maxDuration = DEFAULT_BOOKING_SETTINGS.maxDuration,
  maxBookingsPerPerson = DEFAULT_BOOKING_SETTINGS.maxBookingsPerPerson,
}: BookingConfirmationModalProps) {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const user = useLoggedInUser();
  const { addNotification } = useSnackbarStore();
  const { data: company } = useCompany(user.buildingTenantId);
  const { data: existingBookings } = useUserBookings({
    companyId: company.buildingTenantName,
    userId: user.email,
  });
  const { data: events } = useBookedSlots({
    companyId: company.buildingTenantName,
    userId: user.email,
    roomName: room.name,
  });
  const mappedEvents = useMemo(
    () =>
      events?.flatMap((ev) =>
        ev.start && isSameDay(new Date(date), new Date(ev.start))
          ? {
              ...ev,
              start: new Date(ev.start || ""),
              end: new Date(ev.end || ""),
            }
          : [],
      ) ?? [],
    [events],
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState("");
  const [editedStartTime, setEditedStartTime] = useState(startTime);
  const [editedDuration, setEditedDuration] = useState(
    differenceInMinutes(
      endTime
        ? timeInputStringToDate(endTime)
        : calculateEndTime(startTime, duration),
      timeInputStringToDate(startTime),
    ) / 60,
  );
  const [timeError, setTimeError] = useState("");

  const handleStartTimeChange = (startTime: TimeString) => {
    const newStartTimeAsDate = timeInputStringToDate(startTime, date);
    const newEndTimeAsDate = calculateEndTime(startTime, editedDuration, date);
    if (isBefore(newStartTimeAsDate, now)) {
      setTimeError(t("BOOKINGS.VALIDATION.STARTING_IN_PAST"));
    } else if (
      isBefore(newStartTimeAsDate, timeInputStringToDate(minTime, date))
    ) {
      setTimeError(
        t("BOOKINGS.VALIDATION.START_BEFORE_MIN_TIME", { time: minTime }),
      );
    } else if (
      isAfter(
        calculateEndTime(startTime, editedDuration, date),
        timeInputStringToDate(maxTime, date),
      )
    ) {
      setTimeError(
        t("BOOKINGS.VALIDATION.END_AFTER_MAX_TIME", { time: maxTime }),
      );
    } else if (
      mappedEvents &&
      isSlotOverlapping(mappedEvents, {
        start: newStartTimeAsDate,
        end: newEndTimeAsDate,
      })
    ) {
      setTimeError(t("BOOKINGS.VALIDATION.OVERLAPPING"));
    } else {
      setTimeError("");
    }
    setEditedStartTime(startTime);
  };

  const handleDurationChange = (duration: number) => {
    const newEndTimeAsDate = calculateEndTime(editedStartTime, duration, date);
    if (duration > maxDuration) {
      setTimeError(
        t("BOOKINGS.VALIDATION.OVER_MAX_DURATION", { duration: maxDuration }),
      );
    } else if (
      isAfter(
        calculateEndTime(editedStartTime, duration, date),
        timeInputStringToDate(maxTime, date),
      )
    ) {
      setTimeError(
        t("BOOKINGS.VALIDATION.END_AFTER_MAX_TIME", { time: maxTime }),
      );
    } else if (
      mappedEvents &&
      isSlotOverlapping(mappedEvents, {
        start: timeInputStringToDate(editedStartTime, date),
        end: newEndTimeAsDate,
      })
    ) {
      setTimeError(t("BOOKINGS.VALIDATION.OVERLAPPING"));
    } else {
      setTimeError("");
    }
    setEditedDuration(duration);
  };

  const formAwareOnClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  const handleSubmit = async () => {
    if (timeError) return;
    if (existingBookings && existingBookings.length === maxBookingsPerPerson) {
      addNotification(
        t("BOOKINGS.VALIDATION.LIMIT_REACHED", { count: maxBookingsPerPerson }),
        NotificationType.WARNING,
      );
      return;
    }
    setIsSubmitting(true);
    try {
      const endTimeAsDate = calculateEndTime(
        editedStartTime,
        editedDuration,
        date,
      );
      await createMeetingRoomBooking({
        userId: user.email,
        userFirstName: user.firstName,
        userLanguage: user.language,
        companyId: company.buildingTenantName,
        roomName: room.name,
        eventDetails: {
          summary: meetingTitle,
          start: formatDateToTimezonedString(
            new Date(`${date}T${editedStartTime}`),
          ),
          end: formatDateToTimezonedString(endTimeAsDate),
        },
      });
      addNotification(
        `Successfully booked "${room.name}"`,
        NotificationType.SUCCESS,
      );
      queryClient.invalidateQueries({ queryKey: ["user-bookings"] });
      queryClient.invalidateQueries({ queryKey: ["booked-slots"] });
      queryClient.invalidateQueries({ queryKey: ["meeting-room-search"] });
      onClose();
    } catch (error) {
      addNotification(`Failed to book meeting room. Try again later!`);
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <PopupCard onClose={formAwareOnClose}>
      <h3>{t("BOOKINGS.MODAL.HEADER")}</h3>
      <div className={styles.modalContent}>
        <div className={styles.headerContent}>
          <div className={styles.roomThumbnail}>
            {room.thumbnail ? (
              <img src={room.thumbnail} alt={room.name + " thumbnail"} />
            ) : (
              <Icon name="meeting_room" />
            )}
          </div>
          <h4>
            {meetingTitle
              ? meetingTitle
              : t("BOOKINGS.MODAL.DEFAULT_MEETING_NAME")}
          </h4>
          <div className={styles.moreInfo} style={{ alignSelf: "start" }}>
            <h5 style={{ fontSize: 18 }}>
              <span>@</span> {room.name}
              <span>
                , {room.floor}. {t("FLOOR").toLowerCase()}
              </span>
            </h5>
            <p>
              <span>
                Max {t("BOOKINGS.FILTERS.CAPACITY.LABEL").toLowerCase()}:{" "}
              </span>
              <strong>
                {room.capacity}{" "}
                {t("PEOPLE", { count: room.capacity }).toLowerCase()}
              </strong>
            </p>
            <div className={styles.featuresContainer}>
              <h4 className="sr-only">Features</h4>
              {room.features.map((feature) => (
                <div style={{ display: "contents" }} key={feature}>
                  <p
                    className={`${styles.featureChip} ${styles.darkChip}`}
                    id={`modal-room-${room.id}-feat-${feature}`}
                  >
                    <Icon name={MEETING_ROOM_FEATURES[feature]?.icon} />
                    <span className="sr-only">
                      {t(
                        `BOOKINGS.ROOM_FEATURES.${MEETING_ROOM_FEATURES[feature]?.label}`,
                      )}
                    </span>
                  </p>
                  <Tooltip
                    anchorSelect={`#modal-room-${room.id}-feat-${feature}`}
                    content={t(
                      `BOOKINGS.ROOM_FEATURES.${MEETING_ROOM_FEATURES[feature]?.label}`,
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.timeAndDate}>
          <p>
            {t("BOOKINGS.FILTERS.DATE")}{" "}
            <strong>
              {BOOKING_DATE_LOCALE(i18n.language).format(new Date(date))}
            </strong>
          </p>
          <p>
            {t("BOOKINGS.MODAL.LABELS.TIME")}{" "}
            <strong>
              {editedStartTime} -{" "}
              {dateToTimeInputString(
                calculateEndTime(editedStartTime, editedDuration),
              )}
            </strong>
          </p>
        </div>
        <InputField
          dark
          label={t("BOOKINGS.MODAL.LABELS.MEETING_TITLE")}
          placeholder={t("BOOKINGS.MODAL.LABELS.MEETING_TITLE_PLACEHOLDER")}
          value={meetingTitle}
          onChange={(e) => setMeetingTitle(e.target.value)}
          onClear={() => setMeetingTitle("")}
          infoText={t("BOOKINGS.MODAL.MEETING_TITLE_WARNING")}
          maxLength={50}
        />
        {editableTimes && (
          <fieldset>
            <legend>{t("BOOKINGS.MODAL.EDIT_TIMES")}</legend>
            <div
              style={{
                display: "flex",
                gap: "var(--wlcm-spacing-xs)",
                flexWrap: "wrap",
              }}
            >
              <TimeInput
                dark
                hideLabel
                label={t("BOOKINGS.FILTERS.START_TIME")}
                value={editedStartTime}
                onChange={(e) =>
                  handleStartTimeChange(e.target.value as TimeString)
                }
                minTime={minTime}
                maxTime={maxTime}
                compact
                step={1800} // only half hours
                style={{ flex: 1 }}
              />
              <StrictNumberInput
                dark
                hideLabel
                label={t("BOOKINGS.FILTERS.DURATION")}
                value={editedDuration}
                onChange={(e) => handleDurationChange(Number(e.target.value))}
                append={
                  <span
                    style={{ marginLeft: -14, marginRight: 12, fontSize: 18 }}
                  >
                    {t("HOURS", {
                      count: editedDuration <= 1 ? 1 : 2,
                    }).toLowerCase()}
                  </span>
                }
                min={0.5}
                max={8}
                compact
                step={0.5}
                style={{ flex: 1, minWidth: 190 }}
              />
            </div>
            {timeError && <StatusText fail dark text={timeError} />}
          </fieldset>
        )}
        <div className={styles.moreInfo}>
          <p>
            <span>{t("BOOKINGS.MODAL.LABELS.BOOKED_BY")}: </span>
            <strong>
              {user.firstName} {user.lastName}
            </strong>
          </p>
          <p>
            <span>{t("BOOKINGS.MODAL.LABELS.COMPANY")}: </span>
            <strong>{company.buildingTenantName}</strong>
          </p>
        </div>
        <div className={styles.buttonRow}>
          <Button dark onClick={formAwareOnClose}>
            {t("CANCEL")}
          </Button>
          <Button
            onClick={handleSubmit}
            isLoading={isSubmitting}
            disabled={!!timeError || isSubmitting}
          >
            {t("BOOKINGS.MODAL.CONFIRM")}
          </Button>
        </div>
      </div>
    </PopupCard>
  );
}
