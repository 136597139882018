import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import styles from "@/styles/CompanySettings.module.scss";
import { Dropdown, ImageInput, InputField, SelectRow } from "@app-components";
import { useForm } from "@tanstack/react-form";
import { useContextStore, useSnackbarStore } from "@/store/zustandStore";
import { useQueryClient } from "@tanstack/react-query";
import { AccessSystem, NotificationType, VisitMode } from "@/types";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";
import {
  COMPANY_REGEX,
  ORG_NUMBER_REGEX,
  WELCOME_HELP_URL,
  WELCOME_VISITORS_URL,
} from "@/lib/constants";
import i18n from "@/i18n/config";
import SaveResetButtons from "@/components/SaveResetButtons";
import AdminsSection from "@/components/company_admin/company_admin_settings/AdminsSection";
import blackLogo from "@/assets/blackLogo.svg";
import { usePrompt } from "@/hooks/usePrompt";
import { createFileRoute } from "@tanstack/react-router";
import SsoSection from "@/components/company_admin/company_sso_settings/SsoSection";
import {
  getImageFunction,
  updateCompanySettings,
  uploadImage,
  useAllEntrances,
  useCompany,
  useImage,
  uploadFile,
  useBuilding,
} from "@/api";

export const Route = createFileRoute("/company/settings")({
  component: CompanySettings,
});

function CompanySettings() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { selectedCompany, setCompany } = useContextStore();
  const { addNotification } = useSnackbarStore();
  const { data: company } = useCompany(selectedCompany?.value);
  const { data: entrances } = useAllEntrances();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { data: companyLogo } = useImage(company?.companyLogoImgUrl ?? "");
  const [largeFile, setLargeFile] = useState<File | null>(null);
  const { data: building } = useBuilding();

  usePrompt({
    isOpen: hasUnsavedChanges,
  });

  const form = useForm({
    defaultValues: {
      buildingTenantName: company?.buildingTenantName ?? "",
      orgNumber: company?.orgNumber ?? "",
      companyLogoImgUrl: companyLogo,
      companyLogoImg: "",
      visitMode: company?.visitMode ?? VisitMode.ENABLED,
      floor: company?.floor ?? "",
      selectedEntrances:
        company?.entranceIds && entrances
          ? company.entranceIds.map((ent) => ({
              value: ent,
              label:
                entrances.find((e) => e.entranceId === ent)?.entranceName[
                  i18n.language === "en" ? "en" : "no"
                ] || `Entrance with ID ${ent}`,
            }))
          : [],
      customLetInMessage: company?.customLetInMessage ?? "",
    },
    onSubmit: async ({ value }) => {
      let imgUploadFailed = false;

      if (value.companyLogoImg) {
        try {
          const imgUpload = await uploadImage(value.companyLogoImg);
          const url = await getImageFunction(imgUpload.url);
          value.companyLogoImgUrl = url;
        } catch (error) {
          value.companyLogoImgUrl = companyLogo;
          imgUploadFailed = true;
          console.error(error);
        }
      }

      if (largeFile) {
        const text = await largeFile.text();
        await uploadFile(text, company.buildingTenantName);
      }

      try {
        // Extract all values except for encoded image data which is no longer needed
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { companyLogoImg, selectedEntrances, ...formValues } = value;
        const companySettings = {
          ...formValues,
          entranceIds: selectedEntrances.map((ent) => ent.value),
        };
        await updateCompanySettings(company.buildingTenantId, companySettings);
        setHasUnsavedChanges(false);
        if (imgUploadFailed) {
          addNotification(
            "Image upload failed, but other settings saved successfully",
            NotificationType.WARNING,
          );
        } else {
          addNotification(
            "Company settings saved successfully",
            NotificationType.SUCCESS,
          );
        }
        queryClient.invalidateQueries({ queryKey: ["company", "companies"] });
        setCompany({
          value: company.buildingTenantId,
          label: value.buildingTenantName,
        });
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          addNotification(
            "Failed to save company settings changes. " + error.message,
          );
        } else {
          addNotification("An unexpected error occurred.");
        }
      }
    },
    validatorAdapter: zodValidator(),
  });

  // Reset form every time company context changes
  useEffect(() => {
    if (company) {
      form.reset();
    }
  }, [company, form]);

  return (
    <>
      <div className="pageHeader">
        <h1>{t("PAGES.COMPANY_SETTINGS.HEADER")}</h1>
        <p>
          <Trans i18nKey="PAGES.COMPANY_SETTINGS.SUBHEADER">
            Text
            <a href={WELCOME_HELP_URL(i18n.language)} target="_blank"></a>.
          </Trans>
        </p>
      </div>
      <form
        onChange={() => setHasUnsavedChanges(true)}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        className={styles.container}
      >
        <form.Field
          name="buildingTenantName"
          validators={{
            onChange: z
              .string()
              .min(
                1,
                t("VALIDATION.REQUIRED", {
                  field: t("COMPANY_SETTINGS.COMPANY_NAME"),
                }),
              )
              .min(
                2,
                t("VALIDATION.MIN_LENGTH", {
                  field: t("COMPANY_SETTINGS.COMPANY_NAME"),
                  length: 2,
                  type: t("CHARACTERS"),
                }),
              )
              .max(
                40,
                t("VALIDATION.MAX_LENGTH", {
                  field: t("COMPANY_SETTINGS.COMPANY_NAME"),
                  length: "40",
                  type: t("CHARACTERS"),
                }),
              )
              .trim()
              .refine((value) => COMPANY_REGEX.test(value), {
                message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
              }),
          }}
        >
          {(field) => (
            <InputField
              large
              label={t("COMPANY_SETTINGS.COMPANY_NAME")}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              dangerText={field.state.meta.errors.toString()}
            />
          )}
        </form.Field>
        <form.Field
          name="orgNumber"
          validators={{
            onChange: z
              .string()
              .trim()
              .refine(
                (value) => !value.length || ORG_NUMBER_REGEX.test(value),
                {
                  message: t("VALIDATION.EXACT_LENGTH", {
                    field: t("COMPANY_SETTINGS.ORG_NUMBER"),
                    length: 9,
                    type: t("NUMBERS"),
                  }),
                },
              ),
          }}
        >
          {(field) => (
            <InputField
              label={t("COMPANY_SETTINGS.ORG_NUMBER")}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              dangerText={field.state.meta.errors.toString()}
            />
          )}
        </form.Field>
        <div className={styles.logoContainer}>
          <form.Field name="companyLogoImgUrl">
            {(field) => (
              <ImageInput
                key={field.state.value}
                id="company-logo"
                placeholder={
                  <div className={styles.logoPlaceholder}>
                    <img aria-hidden src={blackLogo} height="100" />
                    <p>{t("IMAGE.PLACEHOLDER.LOGO")}</p>
                  </div>
                }
                translations={{
                  uploadImageOverlayText: t("IMAGE.UPLOAD"),
                  replaceImageAriaLabel: t("IMAGE.REPLACE.ARIA_LABEL"),
                  replaceImageOverlayText: t("IMAGE.REPLACE.TEXT"),
                  undoChangeButtonText: t("IMAGE.UNDO"),
                  editDoneText: t("IMAGE.DONE"),
                  editCancelText: t("IMAGE.CANCEL"),
                  fileTooLargeText: t("IMAGE.FILE_TOO_LARGE"),
                }}
                onChange={(image) => {
                  field.handleChange(image.previewUrl);
                  form.setFieldValue("companyLogoImg", image.encoded);
                  setHasUnsavedChanges(true);
                }}
                onError={(error) => addNotification(error)}
                originalImageUrl={companyLogo}
                currImageUrl={field.state.value}
                strategy="cover"
                style={{
                  previewStyle: {
                    image: {
                      maxWidth: 250,
                      maxHeight: 150,
                    },
                  },
                  overlayStyle: {
                    borderRadius: "var(--wlcm-border-radius-sm)",
                  },
                  containerStyle: {
                    borderRadius: "var(--wlcm-border-radius-sm)",
                  },
                }}
              />
            )}
          </form.Field>
        </div>

        <hr className={styles.sectionDivider} />

        <AdminsSection />

        <hr className={styles.sectionDivider} />

        <SsoSection company={company} />

        <hr className={styles.sectionDivider} />
        <div className={styles.fullWidth}>
          <h2>{t("COMPANY_SETTINGS.VISIT_SETTINGS")}</h2>
          <p className={styles.description}>
            <Trans
              i18nKey="COMPANY_SETTINGS.VISIT_DESC"
              components={{
                url: (
                  <a
                    href={WELCOME_VISITORS_URL(i18n.language)}
                    target="_blank"
                  ></a>
                ),
              }}
            />
          </p>
        </div>
        <div className={styles.subFlex}>
          <form.Field name="visitMode">
            {(field) => (
              <SelectRow
                label={t("COMPANY_SETTINGS.VISIT_MODE.LABEL")}
                name="visitMode"
                value={field.state.value}
                options={[
                  {
                    value: VisitMode.ENABLED,
                    label: t("COMPANY_SETTINGS.VISIT_MODE.ENABLED.LABEL"),
                  },
                  {
                    value: VisitMode.LIMITED,
                    label: t("COMPANY_SETTINGS.VISIT_MODE.LIMITED.LABEL"),
                  },
                  {
                    value: VisitMode.DISABLED,
                    label: t("COMPANY_SETTINGS.VISIT_MODE.DISABLED.LABEL"),
                  },
                ]}
                onChange={(val) => {
                  field.handleChange(val as VisitMode);
                  if (form.getFieldValue("selectedEntrances").length)
                    form.validateField("selectedEntrances", "change");
                  if (form.getFieldValue("floor"))
                    form.validateField("floor", "submit");
                }}
              />
            )}
          </form.Field>
          <form.Subscribe
            selector={(state) => state.values.visitMode}
            children={(visitMode) => (
              <>
                {visitMode === VisitMode.LIMITED && (
                  <form.Field
                    name="floor"
                    validators={{
                      onSubmit: ({ value }) => {
                        if (
                          form.getFieldValue("visitMode") === VisitMode.LIMITED
                        ) {
                          if (!value || Number(value) <= 0)
                            return t("VALIDATION.REQUIRED", {
                              field: t("COMPANY_SETTINGS.FLOOR"),
                            });
                          if (value.length > 3)
                            return t("VALIDATION.MAX_LENGTH", {
                              field: t("COMPANY_SETTINGS.FLOOR"),
                              length: 3,
                              type: t("NUMBERS"),
                            });
                        }
                        return undefined;
                      },
                    }}
                  >
                    {(field) => (
                      <InputField
                        autoFocus
                        compact
                        label={t("COMPANY_SETTINGS.FLOOR")}
                        placeholder="3"
                        type="number"
                        value={field.state.value}
                        onBlur={field.handleBlur}
                        onChange={(e) => field.handleChange(e.target.value)}
                        dangerText={field.state.meta.errors.toString()}
                      />
                    )}
                  </form.Field>
                )}
                <p style={{ width: "100%" }}>
                  <Trans
                    i18nKey={`COMPANY_SETTINGS.VISIT_MODE.${visitMode.toUpperCase()}.DESCRIPTION`}
                    components={{
                      bold: <strong></strong>,
                    }}
                  />
                </p>
              </>
            )}
          />
        </div>
        <form.Subscribe
          selector={(state) => state.values.visitMode}
          children={(visitMode) =>
            visitMode !== VisitMode.DISABLED && (
              <form.Field
                name="selectedEntrances"
                validators={{
                  onChangeListenTo: ["visitMode"],
                  onChange: ({ value }) =>
                    form.getFieldValue("visitMode") === VisitMode.DISABLED ||
                    value.length
                      ? ""
                      : t("VALIDATION.REQUIRED", {
                          field: t("ADD_COMPANY.ENTRANCES"),
                        }),
                }}
              >
                {(field) => (
                  <Dropdown
                    isMulti
                    label={t("ADD_COMPANY.ENTRANCES")}
                    placeholder={t("DEFAULT_SELECT.PLACEHOLDER")}
                    options={
                      entrances
                        ? entrances.map((ent) => ({
                            value: ent.entranceId!,
                            label:
                              ent.entranceName[
                                i18n.language === "en" ? "en" : "no"
                              ],
                          }))
                        : []
                    }
                    noResultsText={t("DEFAULT_SELECT.NO_RESULTS")}
                    maxMenuHeight={500}
                    menuPlacement="top"
                    value={field.state.value}
                    onChange={(newVal) => {
                      field.handleChange(newVal || []);
                      setHasUnsavedChanges(true);
                    }}
                    dangerText={field.state.meta.errors.toString()}
                  />
                )}
              </form.Field>
            )
          }
        />
        {building.accessSystem === AccessSystem.STARWATCH && (
          <form.Subscribe
            selector={(state) => state.values.visitMode}
            children={(visitMode) =>
              visitMode === VisitMode.ENABLED && (
                <form.Field name="customLetInMessage">
                  {(field) => (
                    <InputField
                      label={t("COMPANY_SETTINGS.CUSTOM_LET_IN_MESSAGE")}
                      value={field.state.value}
                      onChange={(e) => field.handleChange(e.target.value)}
                      placeholder={t(
                        "COMPANY_SETTINGS.CUSTOM_LET_IN_MESSAGE_PLACEHOLDER",
                      )}
                      infoText={t(
                        "COMPANY_SETTINGS.CUSTOM_LET_IN_MESSAGE_DESCRIPTION",
                      )}
                    />
                  )}
                </form.Field>
              )
            }
          />
        )}

        <hr className={styles.sectionDivider} />

        <h2>{t("COMPANY_SETTINGS.BULK_FILE_UPLOAD")}</h2>
        <div className={styles.fullWidth}>
          <InputField
            label={t("COMPANY_SETTINGS.LARGE_FILE_UPLOAD")}
            id="large-file-upload"
            className={styles.largeFileUpload}
            type="file"
            accept=".csv"
            onChange={(ev) => {
              setLargeFile(ev.target.files?.[0] ?? null);
            }}
          />
        </div>
        <SaveResetButtons
          form={form}
          showButtons={hasUnsavedChanges}
          onHideButtons={() => setHasUnsavedChanges(false)}
          extraCanSubmit={hasUnsavedChanges}
        />
      </form>
    </>
  );
}
