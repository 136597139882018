import styles from "@/styles/Users.module.scss";
import Table from "@/components/table/Table.tsx";
import type { Dispatch, SetStateAction } from "react";
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import { Mode, User, VisitMode } from "@/types";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { Checkbox, Icon } from "@app-components";
import SortableTableHeader from "@/components/table/SortableTableHeader";
import { Tooltip } from "react-tooltip";
import { useBuilding, useCompany } from "@/api";
import { useContextStore } from "@/store/zustandStore";

type UsersTableProps = {
  isLoading: boolean;
  filteredUsers: User[];
  companyId: string;
  rowSelection: RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>;
};

type selectType = {
  selectCol?: string;
};
const columnHelper = createColumnHelper<User & selectType>();

export default function UsersTable({
  isLoading,
  filteredUsers,
  companyId,
  rowSelection,
  setRowSelection,
}: UsersTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedCompany } = useContextStore();
  const { data: building } = useBuilding();
  const { data: company } = useCompany(selectedCompany?.value);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "userName",
      desc: false,
    },
  ]);

  const columns = useMemo(
    // memoize so columns don't need to be "re-rendered" when component changes
    () => [
      columnHelper.accessor("selectCol", {
        cell: ({ row }) => {
          const handler = row.getToggleSelectedHandler();
          return (
            <Checkbox
              label="Select row"
              hideLabel
              checked={row.getIsSelected()}
              onChange={handler}
              style={{
                container: { display: "unset" },
                checkbox: { top: "unset" },
              }}
            />
          );
        },
        header: ({ table }) => {
          return (
            <>
              <Checkbox
                label="Select all"
                hideLabel
                checked={table.getIsAllPageRowsSelected()}
                nativeOnChange={table.getToggleAllPageRowsSelectedHandler()}
                style={{
                  container: { display: "unset" },
                  checkbox: { top: "unset" },
                }}
              />
            </>
          );
        },
      }),
      columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
        id: "userName",
        cell: (info) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "var(--wlcm-spacing-xs)",
            }}
          >
            {info.row.original.adminInCompanies.includes(companyId) && (
              <>
                <Icon className="user-admin-icon" name="user_cogwheel" />
                <Tooltip
                  anchorSelect=".user-admin-icon"
                  content="Administrator"
                />
              </>
            )}
            {info.getValue()}
          </div>
        ),
        header: (info) => (
          <SortableTableHeader
            info={info}
            tableHeaderId="userName"
            label={t("USERS.TABLE_HEADERS.NAME")}
          />
        ),
      }),
      columnHelper.accessor("email", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.EMAIL")}</div>,
      }),
      columnHelper.accessor("phone", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.PHONE")}</div>,
      }),
      ...(building.accessMode === Mode.ENABLED
        ? [
            columnHelper.accessor("activeAccessCardNumber", {
              cell: (info) => {
                const cardId = info.getValue();
                return (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate({
                        to: "/company/users/$userId/access",
                        params: { userId: info.row.original.userId },
                      });
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "var(--wlcm-spacing-xs)",
                    }}
                  >
                    {cardId ? (
                      <>
                        <Icon name="badge" /> {cardId}
                      </>
                    ) : (
                      <p>-</p>
                    )}
                  </span>
                );
              },
              header: (info) => (
                <SortableTableHeader
                  info={info}
                  tableHeaderId="accessCards"
                  label={t("USERS.TABLE_HEADERS.ACCESS_CARDS")}
                />
              ),
            }),
          ]
        : []),
      ...(company.visitMode === VisitMode.ENABLED
        ? [
            columnHelper.accessor("visibleInVisit", {
              cell: (info) => (
                <div className={styles.iconContainer}>
                  {info.getValue() ? (
                    <>
                      <Icon
                        name="visibility"
                        className={`user-visible-icon ${styles.greenIcon}`}
                      />
                      <Tooltip
                        anchorSelect=".user-visible-icon"
                        content={t("VISIBLE")}
                      />
                      <span className="sr-only">{t("VISIBLE")}</span>
                    </>
                  ) : (
                    <>
                      <Icon
                        name="visibility_off"
                        className="user-hidden-icon"
                      />
                      <Tooltip
                        anchorSelect=".user-hidden-icon"
                        content={t("HIDDEN")}
                      />
                      <span className="sr-only">{t("HIDDEN")}</span>
                    </>
                  )}
                </div>
              ),
              header: (info) => (
                <SortableTableHeader
                  info={info}
                  tableHeaderId="visibleInVisit"
                  label={t("USERS.TABLE_HEADERS.VISIBLE")}
                />
              ),
            }),
          ]
        : []),
      columnHelper.accessor("userActive", {
        cell: (info) => (
          <div className={`${styles.iconContainer} ${styles.noPadding}`}>
            {info.getValue() ? (
              <>
                <Icon
                  name="done"
                  className={`user-active-icon ${styles.greenIcon}`}
                />
                <Tooltip
                  anchorSelect=".user-active-icon"
                  content={t("USERS.TABLE_HEADERS.ACTIVATED")}
                  place="left"
                />
                <span className="sr-only">
                  {t("USERS.TABLE_HEADERS.ACTIVATED")}
                </span>
              </>
            ) : (
              <>
                <Icon
                  name="emergency"
                  className={`user-inactive-icon ${styles.orangeIcon}`}
                />
                <Tooltip
                  anchorSelect=".user-inactive-icon"
                  content={t("NOT_ACTIVATED")}
                  place="left"
                />
                <span className="sr-only">{t("NOT_ACTIVATED")}</span>
              </>
            )}
          </div>
        ),
        header: (info) => (
          <SortableTableHeader
            info={info}
            tableHeaderId="userActive"
            label={t("USERS.TABLE_HEADERS.ACTIVATED")}
            columnInfo={t("USERS.TABLE_HEADERS.ACTIVATED_INFO")}
          />
        ),
      }),
      columnHelper.accessor("userProfileImgUrl", {
        cell: (info) => (
          <div className={styles.iconContainer}>
            {info.getValue() ? (
              <>
                <Icon
                  name="add_photo"
                  className={`${info.row.original.userId}-profile-pic`}
                />
                <Tooltip
                  anchorSelect={`.${info.row.original.userId}-profile-pic`}
                >
                  <img
                    src={info.getValue()}
                    alt={`${info.row.getValue("userName")} ${t("USERS.TABLE_HEADERS.PROFILE_PIC")}`}
                    width="60"
                    height="80"
                  />
                </Tooltip>
              </>
            ) : (
              "-"
            )}
          </div>
        ),
        header: (info) => (
          <SortableTableHeader
            info={info}
            tableHeaderId="userProfileImgUrl"
            label={t("USERS.TABLE_HEADERS.PROFILE_PIC")}
          />
        ),
      }),
    ],
    [company, building],
  );

  const table = useReactTable({
    data: filteredUsers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiRowSelection: true,
    enableRowSelection: true,
    state: {
      sorting,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: Number(localStorage.getItem("usersPerPage") || 15),
      },
    },
  });
  return (
    <Table
      table={table}
      isLoading={isLoading}
      noDataMessage={t("USERS.NO_USERS")}
      onRowClick={(user: User) => {
        navigate({
          to: "/company/users/$userId/settings",
          params: { userId: user.userId },
        });
      }}
      totalItems={filteredUsers.length}
      paginationKey="usersPerPage"
    />
  );
}
