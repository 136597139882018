import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { MeetingRoom, MeetingRoomBooking, MeetingRoomFeature } from "@/types";
import { debounceAsync } from "@/lib/utils";
import { REAL_ESTATE } from "@/lib/constants";
import { Event } from "react-big-calendar";
import client from "../client";

export const useMeetingRooms = (): UseQueryResult<MeetingRoom[]> => {
  return useQuery({
    queryKey: ["meeting-rooms"],
    queryFn: async () => {
      try {
        const response = await client.get("/getAllMeetingRooms");
        if (!Array.isArray(response.data))
          throw new Error("Response is not an array");
        return response.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });
};

interface MeetingRoomSearchParams {
  /**
   * Timezoned datetime string in the format yyyy-MM-dd'T'HH:mm:ssXXX
   */
  dateTime: string;
  /**
   * Duration should be provided in minutes
   */
  duration: number;
  capacity?: number;
  features?: MeetingRoomFeature[];
}
const fetchMeetingRooms = async ({
  dateTime,
  duration,
  capacity,
  features,
}: MeetingRoomSearchParams): Promise<MeetingRoom[]> => {
  try {
    const response = await client.get(
      `/getAvailableMeetingRooms?dateTime=${encodeURIComponent(dateTime)}&duration=${duration}${capacity ? "&capacity=" + capacity : ""}${features?.length ? "&features=" + features.join(",") : ""}`,
    );
    if (!Array.isArray(response.data))
      throw new Error("Response is not an array");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const debouncedFetchMeetingRooms = debounceAsync(fetchMeetingRooms, 300);

export const useMeetingRoomSearch = ({
  dateTime,
  duration,
  capacity,
  features,
}: MeetingRoomSearchParams): UseQueryResult<MeetingRoom[]> => {
  return useQuery({
    queryKey: ["meeting-room-search", dateTime, duration, capacity, features],
    queryFn: () =>
      debouncedFetchMeetingRooms({ dateTime, duration, capacity, features }),
  });
};

interface UseBookedSlotsProps {
  companyId: string;
  userId: string;
  roomName: string;
}
export const useBookedSlots = ({
  companyId,
  userId,
  roomName,
}: UseBookedSlotsProps): UseQueryResult<Event[]> => {
  return useQuery({
    queryKey: ["booked-slots", companyId, userId, roomName],
    queryFn: async () => {
      try {
        const response = await client.get(
          `/getBookedSlots?companyId=${companyId}&userId=${userId}&roomName=${roomName}`,
        );
        if (!Array.isArray(response.data))
          throw new Error("Response is not an array");
        return response.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });
};

interface UseUserBookingsProps {
  companyId: string;
  userId: string;
}
export const useUserBookings = ({
  companyId,
  userId,
}: UseUserBookingsProps): UseQueryResult<MeetingRoomBooking[]> => {
  return useQuery({
    queryKey: ["user-bookings", companyId, userId],
    queryFn: async () => {
      try {
        const response = await client.get(
          `/getUserBookings?companyId=${companyId}&userId=${userId}`,
        );
        if (!Array.isArray(response.data.bookingDetails))
          throw new Error("Response is not an array");
        return response.data.bookingDetails;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });
};

interface CreateMeetingRoomBookingBody {
  userId: string;
  userFirstName: string;
  userLanguage: string;
  companyId: string;
  roomName: string;
  eventDetails: {
    /**
     * Same as "meeting title"
     */
    summary: string;
    start: Date | string;
    end: Date | string;
  };
}
export const createMeetingRoomBooking = async (
  props: CreateMeetingRoomBookingBody,
) => {
  const response = await client.post("/createMeetingRoomBooking", {
    ...props,
    buildingId: REAL_ESTATE,
  });
  return response.data;
};

interface CancelMeetingRoomBookingBody {
  id: string;
  userId: string;
  userFirstName: string;
  userLanguage: string;
}
export const cancelMeetingRoomBooking = async (
  props: CancelMeetingRoomBookingBody,
) => {
  const response = await client.post("/cancelMeetingRoomBooking", {
    ...props,
    buildingId: REAL_ESTATE,
  });
  return response.data;
};
