import styles from "@/styles/App.module.scss";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import Sidebar from "@/components/app/Sidebar";
import Snackbar from "@/components/app/Snackbar";
import { Suspense } from "react";
import ErrorBoundary from "@/components/app/ErrorBoundary";
import { Company, User } from "@/types";
import ConfirmProvider from "@/providers/ConfirmProvider";
import type { QueryClient } from "@tanstack/react-query";
import React from "react";
import { useLoggedInUser } from "@/providers/AuthProvider";

const TanStackRouterDevtools =
  process.env.NODE_ENV !== "development"
    ? () => null // Render nothing in other envs
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

type RouterContextType = {
  user: User;
  company: Company;
  handbookLink: string;
  queryClient: QueryClient;
};

const SidebarLoading = () => {
  return <div style={{ height: "100%", background: "black" }}></div>;
};

export const Route = createRootRouteWithContext<RouterContextType>()({
  component: () => {
    const user = useLoggedInUser();
    return (
      <>
        <div className={styles.mainLayout}>
          <Suspense fallback={<SidebarLoading />}>
            <Sidebar />
          </Suspense>
          <main className={styles.mainContent}>
            <ErrorBoundary insideRouter>
              <ConfirmProvider>
                <Outlet />
              </ConfirmProvider>
            </ErrorBoundary>
          </main>
        </div>
        <Snackbar completeLeftAlign={!user?.userActive} />
        <Suspense>
          <TanStackRouterDevtools />
        </Suspense>
      </>
    );
  },
});
