import styles from "@/styles/VisitorCard.module.scss";
import { Icon } from "@app-components";
import { CompanyVisit, Entrance, Visit } from "@/types";
import { useTranslation } from "react-i18next";
import AnswerToVisitor from "@/components/AnswerToVisitor";
import { formatTimestampToTimeAgo } from "@/lib/utils";
import i18n from "@/i18n/config";
import { useEntrance } from "@/api";
interface IVisitorCardProps {
  data: Visit | CompanyVisit;
  company?: boolean;
}

export default function VisitorCard({ data, company }: IVisitorCardProps) {
  const { t } = useTranslation();
  const {
    id,
    visitorName,
    messageFromVisitor,
    visitorPhone,
    visitorCompanyName,
    createdAt,
    entranceId,
    whoReplied,
    visitorLanguage,
    letInAt,
    messageHistory,
    buildingTenantId,
  } = data;
  const { data: entrance } = useEntrance(entranceId);
  return (
    <div className={styles.visitorCard}>
      <div className={styles.infoContainer}>
        <div className={styles.infoInnerContainer}>
          <h3>
            {visitorName}
            {visitorCompanyName && (
              <span>
                {" "}
                {t("FROM")} {visitorCompanyName}
              </span>
            )}
          </h3>
          <div className={styles.fullWidth}>
            <Icon name="phone_android" />
            <a href={`tel:${visitorPhone}`}>{visitorPhone}</a>
          </div>
          {!!messageFromVisitor && (
            <div className={styles.fullWidth}>
              <Icon name="comment" />
              <p>"{messageFromVisitor}"</p>
            </div>
          )}
          <div>
            <Icon name="schedule" />
            <p>{formatTimestampToTimeAgo(t, createdAt, i18n.language)}</p>
          </div>
          {entrance && (
            <div>
              <Icon name="location_on" />
              <p>
                {
                  (entrance as Entrance).entranceName[
                    i18n.language === "en" ? "en" : "no"
                  ]
                }
              </p>
            </div>
          )}
        </div>
      </div>
      <AnswerToVisitor
        visitorName={visitorName}
        visitorLanguage={visitorLanguage || "no"}
        visitorId={id}
        visitorPhone={visitorPhone}
        createdAt={createdAt}
        whoReplied={whoReplied}
        company={company}
        letInAt={letInAt}
        messageHistory={messageHistory}
        buildingTenantId={buildingTenantId}
      />
    </div>
  );
}
