import styles from "@/styles/Visitors.module.scss";
import { Icon } from "@app-components";

interface IPageCardsPlaceholderProps {
  company?: boolean;
  icon: string | string[];
  placeholder: string;
}

export default function PageCardsPlaceholder({
  icon,
  placeholder,
}: IPageCardsPlaceholderProps) {
  const multiIcons = Array.isArray(icon);
  return (
    <div className={`${styles.cards} ${styles.placeholderContainer}`}>
      <div
        className={styles.visitorPlaceholder}
        id={styles["p-p-1"]}
        aria-hidden
      >
        <Icon name={multiIcons ? icon[0] : icon} />
      </div>
      <div
        className={styles.visitorPlaceholder}
        id={styles["p-p-2"]}
        aria-hidden
      >
        <Icon name={multiIcons ? icon[1] : icon} />
      </div>
      <div
        className={styles.visitorPlaceholder}
        id={styles["p-p-3"]}
        aria-hidden
      >
        <Icon name={multiIcons ? icon[2] : icon} />
      </div>
      <div className={styles.overlay} aria-hidden></div>
      <h2>{placeholder}</h2>
    </div>
  );
}
