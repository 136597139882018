import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { getAuth } from "firebase/auth";
import {
  useContextStore,
  useUserStore,
  useSnackbarStore,
} from "@/store/zustandStore";
import { AddUserType, NotificationType } from "@/types";
import { useTranslation } from "react-i18next";
import { createUsers, useBuilding, useCompany } from "@/api";

export const useUploadUsers = (onClose: () => void) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useSnackbarStore();
  const queryClient = useQueryClient();
  const { selectedCompany } = useContextStore();
  const { userName } = useUserStore();
  const { data: building } = useBuilding();
  const { data: company, error: companyError } = useCompany(
    selectedCompany?.value,
  );
  const auth = getAuth();
  const user = auth.currentUser;

  const submitUsers = async (
    usersToAdd: AddUserType[],
    companyNameOverride?: string,
  ) => {
    if (!user) {
      throw new Error("User is not authenticated");
    }
    if (!company || !building) {
      throw new Error("Company or building not found", { cause: companyError });
    }

    const userArrays = [];

    for (let i = 0; i < usersToAdd.length; i += 50) {
      userArrays.push(usersToAdd.slice(i, i + 50));
    }
    try {
      setIsSubmitting(true);
      let amountUploaded = 0;
      await Promise.all(
        userArrays.map(async (array) => {
          await createUsers(
            array,
            userName,
            companyNameOverride || company?.buildingTenantName,
            building.displayName,
            company?.visitMode === "enabled",
          );
          amountUploaded += array.length;
        }),
      );
      queryClient.invalidateQueries({ queryKey: ["users"] });
      addNotification(
        `${amountUploaded} ${t("ADD_USER.SUCCESS")}`,
        NotificationType.SUCCESS,
      );
      onClose();
    } catch (error) {
      console.error(error);
      addNotification(t("ADD_USER.ERROR"));
    } finally {
      setIsSubmitting(false);
    }
  };

  return { submitUsers, isSubmitting };
};
