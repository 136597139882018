import styles from "@/styles/BuildingVisitors.module.scss";
import { useTranslation } from "react-i18next";
import { Button, Icon, InputField } from "@app-components";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  AccessSystem,
  Entrance,
  ExtendedPreRegistrationAttendee,
  NotificationType,
  PreRegistrationGroup,
  Visit,
  Visitor,
} from "@/types";
import { useEffect, useMemo, useState } from "react";
import Table from "@/components/table/Table.tsx";
import { useNavigate } from "@tanstack/react-router";
import { checkInPreregisteredAttendee, getCustomLetInMessage, letInVisitor, useBuilding } from "@/api";
import { Tooltip } from "react-tooltip";
import {
  filterTodayPreregistrations,
  formatTimestampToHoursAndMinutes,
  timestampSortingFn,
} from "@/lib/utils";
import { useSnackbarStore } from "@/store/zustandStore";
import { useQueryClient } from "@tanstack/react-query";
import SortableTableHeader from "@/components/table/SortableTableHeader";
import VisitorDetails from "@/components/building_admin/building_visitors/VisitorDetails";

type SearchableColumns = keyof Visit | keyof ExtendedPreRegistrationAttendee;

const columnHelper = createColumnHelper<Visitor>();
const searchableColumns: Array<SearchableColumns> = [
  "visitorName",
  "hostName",
  "visitorCompanyName",
  "visitorPhone",
  "buildingTenantName",
  "phone",
  "name",
  "eventName",
  "companyName",
];

const columnHelperPrereg =
  createColumnHelper<ExtendedPreRegistrationAttendee>();
const searchableColumnsPrereg: Array<keyof ExtendedPreRegistrationAttendee> = [
  "name",
  "hostName",
  "eventName",
  "companyName",
];

interface ReceptionViewProps {
  entrances: Entrance[];
  visitors: Visit[];
  preregistrationGroups: PreRegistrationGroup[];
  navigationPath: string;
  companyReception?: boolean;
}

export const ReceptionView = ({
  entrances,
  visitors,
  preregistrationGroups,
  navigationPath,
  companyReception,
}: ReceptionViewProps) => {
  const [filteredVisitors, setFilteredVisitors] = useState<Visitor[]>([]);
  const [selectedVisitor, setSelectedVisitor] = useState<Visitor>();
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useSnackbarStore();
  const queryClient = useQueryClient();
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const [preregLoadingIndex, setPreregLoadingIndex] = useState<number | null>(
    null,
  );
  const [visitorDetailsOpen, setVisitorDetailsOpen] = useState(false);
  const [preregSearchTerm, setPreregSearchTerm] = useState("");
  const [filteredPreregVisitors, setFilteredPreregVisitors] = useState<
    ExtendedPreRegistrationAttendee[]
  >([]);
  const { data: building } = useBuilding();

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "createdAt",
      desc: true,
    },
  ]);

  const todayPreregistrations = filterTodayPreregistrations(
    preregistrationGroups,
  );

  const extendedPreregistrationAttendees: ExtendedPreRegistrationAttendee[] =
    todayPreregistrations.flatMap((group) =>
      group.attendees.map((attendee) => ({
        ...attendee,
        startTime: group.startTime,
        hostName: group.hostName,
        eventName: group.eventName,
        companyName: group.companyName,
        companyId: group.companyId,
        eventId: group.id,
        eventDescription: group.eventDescription,
        hostPhone: group.hostPhone,
        hostEmail: group.hostEmail,
      })),
    );

  const arrivedAttendees = useMemo(() => {
    return extendedPreregistrationAttendees.filter(
      (attendee) => !!attendee.arrivedAt,
    );
  }, [extendedPreregistrationAttendees]);

  const upcomingAttendees = useMemo(() => {
    return extendedPreregistrationAttendees.filter(
      (attendee) => !attendee.arrivedAt,
    );
  }, [extendedPreregistrationAttendees]);

  const combinedData = useMemo(
    () => [
      ...(visitors ?? []),
      ...(arrivedAttendees ?? []), //Only render arrived attendees in the top table
    ],
    [visitors, arrivedAttendees],
  );

  const isVisit = (
    data: Visit | ExtendedPreRegistrationAttendee,
  ): data is Visit => {
    return "visitorName" in data;
  };

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor(
          (row) => {
            if (isVisit(row)) {
              return row.createdAt;
            } else {
              return row.arrivedAt;
            }
          },
          {
            id: "createdAt",
            cell: (info) => {
              const value = info.getValue();
              if (!value) return null;

              const date = new Date(value._seconds * 1000);
              const hours = String(date.getHours()).padStart(2, "0");
              const minutes = String(date.getMinutes()).padStart(2, "0");
              const formattedTime = `${hours}:${minutes}`;

              return formattedTime; // Regular time rendering
            },
            header: (info) => (
              <SortableTableHeader
                info={info}
                tableHeaderId="createdAt"
                label={t("BUILDING_VISITORS.TIME")}
              />
            ),
            sortingFn: timestampSortingFn,
          },
        ),
        (entrances?.length ?? 0) > 1
          ? columnHelper.accessor("entranceName", {
              cell: (info) => info.getValue(),
              header: () => <div>{t("BUILDING_VISITORS.LOCATION")}</div>,
            })
          : null,
        columnHelper.accessor(
          (row) => {
            if (isVisit(row)) {
              return row.visitorName;
            } else {
              return row.name;
            }
          },
          {
            id: "visitorOrAttendeeName",
            cell: (info) => info.getValue(),
            header: () => <div>{t("BUILDING_VISITORS.VISITOR")}</div>,
          },
        ),
        columnHelper.accessor("hostName", {
          cell: (info) => {
            const hostName = info.getValue();
            return hostName == undefined ? "\u2014" : hostName;
          },
          header: () => <div>{t("BUILDING_VISITORS.HOST")}</div>,
        }),
        ...(!companyReception
          ? [
              columnHelper.accessor(
                (row) => {
                  if (isVisit(row)) {
                    return row.buildingTenantName;
                  } else {
                    return row.companyName;
                  }
                },
                {
                  id: "buildingTenantOrCompanyName",
                  cell: (info) => info.getValue(),
                  header: () => (
                    <div>{t("BUILDING_VISITORS.HOST_COMPANY")}</div>
                  ),
                },
              ),
            ]
          : []),
        columnHelper.accessor(
          (row) => {
            if (isVisit(row)) {
              return "\u2014";
            } else {
              return row.eventName;
            }
          },
          {
            id: "eventName",
            cell: (info) => info.getValue() || "",
            header: () => <div>{t("PREREGISTRATION.EVENT_NAME")}</div>,
          },
        ),
        columnHelper.accessor(
          (row) => {
            if (isVisit(row)) {
              return row.messageHistory;
            }
            return null;
          },
          {
            id: "messageHistory",
            cell: (info) => {
              return info.getValue() ? (
                <>
                  <Icon name="mail" className="visitor-sms-from-host-icon" />
                  <span className="sr-only">
                    {t("BUILDING_VISITORS.HOST_REPLIED")}
                  </span>
                  <Tooltip
                    anchorSelect=".visitor-sms-from-host-icon"
                    content={t("BUILDING_VISITORS.SMS_FROM_HOST")}
                  />
                </>
              ) : (
                ""
              );
            },
            header: () => (
              <div className="sr-only">
                {t("BUILDING_VISITORS.HOST_REPLIED")}
              </div>
            ),
          },
        ),
        // TODO: refine the condition when other access systems are integrated
        ...(building.accessSystem === AccessSystem.STARWATCH
          ? [
              columnHelper.accessor(
                (row) => (isVisit(row) ? row.letInAt : row.arrivedAt),
                {
                  id: "letInAtOrCheckIn",
                  cell: (info) => {
                    const row = info.row.original;
                    const letInAt = isVisit(row) ? row.letInAt : row.arrivedAt;

                    const isLoading = loadingIndex === info.row.index;
                    if (letInAt) {
                      const timestamp =
                        formatTimestampToHoursAndMinutes(letInAt);
                      return (
                        <>
                          <Icon
                            name="key"
                            className={`${styles.greenIcon} visitor-let-in-key-icon-${info.row.index}`}
                          />
                          <span className="sr-only">
                            {t("BUILDING_VISITORS.LET_IN")}
                          </span>
                          <Tooltip
                            anchorSelect={`.visitor-let-in-key-icon-${info.row.index}`}
                            content={t("BUILDING_VISITORS.ACCESS_GRANTED", {
                              timestamp,
                            })}
                          />
                        </>
                      );
                    }
                    if (isVisit(row)) {
                      return (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            letVisitorIn(row, info.row.index);
                          }}
                          isLoading={isLoading}
                        >
                          {t("BUILDING_VISITORS.LET_IN")}
                        </Button>
                      );
                    }
                  },
                  header: () => (
                    <div className="sr-only">
                      {t("BUILDING_VISITORS.ACTIONS")}
                    </div>
                  ),
                },
              ),
            ]
          : []),
      ].filter(Boolean) as ColumnDef<
        Visit | ExtendedPreRegistrationAttendee,
        unknown
      >[],
    [entrances?.length, t, loadingIndex],
  );

  const preregColumns = useMemo(
    () =>
      [
        columnHelperPrereg.accessor((row) => row.startTime, {
          id: "startTime",
          cell: (info) => {
            const value = info.getValue();
            if (!value) return null;

            const date = new Date(value._seconds * 1000);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const formattedTime = `${hours}:${minutes}`;

            return formattedTime;
          },
          header: (info) => (
            <SortableTableHeader
              info={info}
              tableHeaderId="startTime"
              label={t("BUILDING_VISITORS.TIME")}
            />
          ),
          sortingFn: timestampSortingFn,
        }),
        columnHelperPrereg.accessor("name", {
          cell: (info) => info.getValue(),
          header: () => <div>{t("BUILDING_VISITORS.VISITOR")}</div>,
        }),
        columnHelperPrereg.accessor("hostName", {
          cell: (info) => info.getValue(),
          header: () => <div>{t("BUILDING_VISITORS.HOST")}</div>,
        }),
        ...(!companyReception
          ? [
              columnHelperPrereg.accessor("companyName", {
                cell: (info) => info.getValue(),
                header: () => <div>{t("BUILDING_VISITORS.HOST_COMPANY")}</div>,
              }),
            ]
          : []),
        columnHelperPrereg.accessor("eventName", {
          cell: (info) => info.getValue() || "",
          header: () => <div>{t("PREREGISTRATION.EVENT_NAME")}</div>,
        }),
        // TODO: refine the condition when other access systems are integrated
        ...(building.accessSystem === AccessSystem.STARWATCH
          ? [
              columnHelperPrereg.accessor((row) => row, {
                id: "letInAttendee",
                cell: (info) => {
                  const isLoading = preregLoadingIndex === info.row.index;
                  return (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        checkInAttendee(info.row.original, info.row.index);
                      }}
                      isLoading={isLoading}
                    >
                      {t("BUILDING_VISITORS.LET_IN")}
                    </Button>
                  );
                },
                header: () => (
                  <div className="sr-only">{t("BUILDING_VISITORS.LET_IN")}</div>
                ),
              }),
            ]
          : []),
      ].filter(Boolean) as ColumnDef<
        ExtendedPreRegistrationAttendee,
        unknown
      >[],
    [preregLoadingIndex],
  );

  const table = useReactTable<Visitor>({
    data: filteredVisitors,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: Number(localStorage.getItem("visitorsPerPage") || 15),
      },
    },
  });

  const preregTable = useReactTable<ExtendedPreRegistrationAttendee>({
    data: filteredPreregVisitors,
    columns: preregColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: Number(localStorage.getItem("attendeesPerPage") || 15),
      },
    },
  });

  useEffect(() => {
    if (combinedData) {
      const reversedData = [...combinedData].reverse();
      let filteredData = reversedData;

      if (searchTerm) {
        filteredData = reversedData.filter((visitor) => {
          if (isVisit(visitor)) {
            return searchableColumns.some((column) =>
              (
                visitor[column as keyof Visit]?.toString().toLowerCase() || ""
              ).includes(searchTerm.toLowerCase()),
            );
          } else {
            return searchableColumns.some((column) =>
              (
                visitor[column as keyof ExtendedPreRegistrationAttendee]
                  ?.toString()
                  .toLowerCase() || ""
              ).includes(searchTerm.toLowerCase()),
            );
          }
        });
      }

      // Update state only when filtered data has changed
      setFilteredVisitors((prevFilteredVisitors) =>
        JSON.stringify(prevFilteredVisitors) !== JSON.stringify(filteredData)
          ? filteredData
          : prevFilteredVisitors,
      );
    }
  }, [searchTerm, combinedData]);

  useEffect(() => {
    if (upcomingAttendees) {
      let filteredData = upcomingAttendees;

      if (preregSearchTerm) {
        filteredData = upcomingAttendees.filter((attendee) =>
          searchableColumnsPrereg.some((column) =>
            (attendee[column]?.toString().toLowerCase() || "").includes(
              preregSearchTerm.toLowerCase(),
            ),
          ),
        );
      }

      // Update state only when filtered data has changed
      setFilteredPreregVisitors((prevFilteredPreregVisitors) =>
        JSON.stringify(prevFilteredPreregVisitors) !==
        JSON.stringify(filteredData)
          ? filteredData
          : prevFilteredPreregVisitors,
      );
    }
  }, [preregSearchTerm, upcomingAttendees]);

  const letVisitorIn = async (visitor: Visit, index: number) => {
    setLoadingIndex(index);
    try {
      const company = !visitor.hostName;
      const customMessage = await getCustomLetInMessage(visitor.buildingTenantId);
      await letInVisitor(
        visitor.id,
        company,
        visitor.visitorName,
        visitor.visitorPhone,
        visitor.visitorLanguage || "no",
        customMessage,
      );

      addNotification(
        t("BUILDING_VISITORS.ACCESS_CODE_SENT"),
        NotificationType.SUCCESS,
      );
      queryClient.invalidateQueries({
        queryKey: [companyReception ? "company-visitors" : "visitors-building"],
      });
    } catch (error) {
      console.error(error);
      addNotification("An unexpected error occurred.");
    } finally {
      setLoadingIndex(null);
    }
  };

  const checkInAttendee = async (
    attendee: ExtendedPreRegistrationAttendee,
    index: number,
  ) => {
    setPreregLoadingIndex(index);

    try {
      await checkInPreregisteredAttendee(
        attendee.name,
        attendee.phone,
        attendee.eventId,
      );
      addNotification(
        t("BUILDING_VISITORS.ACCESS_CODE_SENT"),
        NotificationType.SUCCESS,
      );
      queryClient.invalidateQueries({
        queryKey: ["building-preregistration-group"],
      });
    } catch (error) {
      console.error(error);
      addNotification("An unexpected error occurred.");
    } finally {
      setPreregLoadingIndex(null);
    }
  };

  return (
    <>
      <div className={styles.upperButtons}>
        <div className={styles.searchContainer}>
          <InputField
            label={t("BUILDING_VISITORS.SEARCH_PLACEHOLDER")}
            placeholder={t("BUILDING_VISITORS.SEARCH_PLACEHOLDER") + "..."}
            icon="search"
            value={searchTerm}
            compact
            hideLabel
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <Button icon="add" onClick={() => navigate({ to: navigationPath })}>
          {t("PAGES.VISITORS.CREATE_PREREGISTRATION")}
        </Button>
      </div>
      <Table
        table={table}
        noDataMessage={t("BUILDING_VISITORS.NO_VISITORS")}
        onRowClick={(visit) => {
          setSelectedVisitor(visit);
          setVisitorDetailsOpen(true);
        }}
        totalItems={filteredVisitors.length}
        paginationKey="visitorsPerPage"
      />
      <div className={styles.preregContainer}>
        <div className="pageHeader">
          <h2>{t("BUILDING_VISITORS.PREREGISTRATIONS")}</h2>
          <p>{t("BUILDING_VISITORS.PREREGISTRATIONS_DESCRIPTION")}</p>
        </div>
        <div className={styles.preregSearchContainer}>
          <InputField
            label={t("BUILDING_VISITORS.PREREG_SEARCH_PLACEHOLDER")}
            placeholder={
              t("BUILDING_VISITORS.PREREG_SEARCH_PLACEHOLDER") + "..."
            }
            icon="search"
            value={preregSearchTerm}
            compact
            hideLabel
            onChange={(e) => setPreregSearchTerm(e.target.value)}
          />
        </div>
        <Table
          table={preregTable}
          noDataMessage={t("BUILDING_VISITORS.NO_ATTENDEES")}
          onRowClick={(visit) => {
            setSelectedVisitor(visit);
            setVisitorDetailsOpen(true);
          }}
          totalItems={filteredPreregVisitors.length}
          paginationKey="attendeesPerPage"
        />
      </div>

      {visitorDetailsOpen && selectedVisitor && (
        <VisitorDetails
          visitor={selectedVisitor}
          onClose={() => setVisitorDetailsOpen(false)}
          companyReception={companyReception}
        />
      )}
    </>
  );
};
