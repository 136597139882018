import { PreRegistrationAttendee, PreRegistrationGroup } from "@/types";
import client, { throwCaughtError } from "../client";
import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";

const PersonalPreregistrationGroupQueryFn = async (hostId: string) => {
  try {
    const response = await client.get(
      `/portal/getPreRegistrations?hostId=${hostId}`,
    );
    return response.data.groups;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const personPreregistrationOptions = (hostId: string) => ({
  queryKey: ["personal-preregistration-group", hostId],
  queryFn: async () => await PersonalPreregistrationGroupQueryFn(hostId),
});

export const usePersonalPreregistrations = (
  userId: string | undefined = "",
): UseSuspenseQueryResult<PreRegistrationGroup[]> => {
  return useSuspenseQuery(personPreregistrationOptions(userId));
};

export const companyPreregistrationOptions = (companyId: string) => ({
  queryKey: ["company-preregistration-group", companyId],
  queryFn: async () => {
    try {
      const response = await client.get(
        `/portal/getPreRegistrations?companyId=${companyId}`,
      );
      return response.data.groups;
    } catch (error) {
      throwCaughtError(error);
    }
  },
});

export const useCompanyPreregistrations = (
  companyId: string,
): UseSuspenseQueryResult<PreRegistrationGroup[]> => {
  return useSuspenseQuery(companyPreregistrationOptions(companyId));
};

export const useBuildingPreregistrations = (): UseSuspenseQueryResult<
  PreRegistrationGroup[]
> => {
  return useSuspenseQuery({
    queryKey: ["building-preregistration-group"],
    queryFn: async () => {
      try {
        const response = await client.get(
          `/portal/getPreRegistrations?building=true`,
        );
        return response.data.groups;
      } catch (error) {
        throwCaughtError(error);
      }
    },
  });
};

export const createPreregistrationGroup = async (
  hostId: string,
  hostEmail: string,
  companyId: string,
  companyName: string,
  eventName: string,
  eventDescription: string,
  startTime: Date,
  attendees: PreRegistrationAttendee[],
  hostName: string,
  hostPhone?: string,
  hostLanguage?: string,
  hostNotificationPreferences?: {email: boolean, sms: boolean},
) => {
  try {
    const response = await client.post("/createPreRegistration", {
      hostId,
      hostEmail,
      hostPhone,
      hostName,
      hostLanguage,
      hostNotificationPreferences,
      companyId,
      companyName,
      eventName,
      eventDescription,
      startTime: startTime.toISOString(),
      attendees,
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const deletePreregistrationGroup = async (groupId: string) => {
  try {
    await client.delete(`/deletePreRegistration?groupId=${groupId}`);
  } catch (error) {
    throwCaughtError(error);
  }
};
