import type { NavigateAction, View, ViewsProps } from "react-big-calendar";
import styles from "@/styles/Calendar.module.scss";
import { Button, Icon } from "@app-components";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef } from "react";
import { differenceInDays, differenceInWeeks } from "date-fns";

export interface BuiltInToolbarProps {
  label: string;
  onNavigate: (action: NavigateAction) => void;
  date: Date;
  view: View;
  views: ViewsProps<never, object>;
  onView: (view: View) => void;
}
interface CalendarToolbarProps extends BuiltInToolbarProps {
  minDate?: Date;
  maxDate?: Date;
}

export function CalendarToolbar({
  label,
  onNavigate,
  view,
  date,
  minDate,
  maxDate,
}: CalendarToolbarProps) {
  const { t } = useTranslation();
  const prevView = useRef(view);
  const today = new Date(new Date().setHours(0, 0, 0, 0));

  const calculatedStep = useMemo(
    () =>
      view === "day"
        ? differenceInDays(date, today)
        : differenceInWeeks(date, today),
    [view, date, today],
  );

  const maxStep = useMemo(
    () =>
      maxDate
        ? view === "day"
          ? differenceInDays(maxDate, today) - 1
          : differenceInWeeks(maxDate, today)
        : 0,
    [view, maxDate, today],
  );

  const handlePrev = () => {
    if (!minDate || calculatedStep > 0) {
      onNavigate("PREV");
    }
  };

  const handleNext = () => {
    if (!maxDate || calculatedStep < maxStep) {
      onNavigate("NEXT");
    }
  };

  const handleToday = () => {
    onNavigate("TODAY");
  };

  useEffect(() => {
    // Reset to "today" only when the view changes
    if (prevView.current !== view) {
      prevView.current = view;
      handleToday();
    }
  }, [view, handleToday]);

  return (
    <div className={styles.toolbarContainer}>
      <p>
        <Icon name="date_range" /> <strong>{label}</strong>
      </p>
      <div>
        <Button
          size="sm"
          dark
          onClick={handlePrev}
          disabled={minDate && calculatedStep === 0}
        >
          <Icon name="arrow_back" /> {t("PREVIOUS")}
        </Button>
        <Button size="sm" dark onClick={handleToday}>
          {t("TODAY")}
        </Button>
        <Button
          size="sm"
          dark
          onClick={handleNext}
          disabled={maxDate && calculatedStep === maxStep}
        >
          {t("NEXT")} <Icon name="arrow_forward" />
        </Button>
      </div>
    </div>
  );
}
