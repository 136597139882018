import {
  useBuilding,
  useCompany,
  useMeetingRooms,
  useUserBookings,
} from "@/api";
import PageCardsPlaceholder from "@/components/PageCardsPlaceholder";
import {
  createFileRoute,
  useLocation,
  useNavigate,
  useRouterState,
} from "@tanstack/react-router";
import { Trans, useTranslation } from "react-i18next";
import styles from "@/styles/Bookings.module.scss";
import { LoadingSpinner, SelectRow } from "@app-components";
import { useEffect, useState } from "react";
import AllRooms from "@/components/bookings/AllRooms";
import SearchForRooms from "@/components/bookings/SearchForRooms";
import { useLoggedInUser } from "@/providers/AuthProvider";
import RoomBookingCard from "@/components/bookings/RoomBookingCard";

export const Route = createFileRoute("/bookings/")({
  component: Bookings,
});

function Bookings() {
  const { t } = useTranslation();
  const router = useRouterState();
  const location = useLocation();
  const user = useLoggedInUser();
  const navigate = useNavigate();
  const { data: company } = useCompany(user?.buildingTenantId);
  const { data: building } = useBuilding();
  const { data: rooms, isLoading: isRoomsLoading } = useMeetingRooms();
  const { data: bookings, isLoading } = useUserBookings({
    userId: user?.email,
    companyId: company.buildingTenantName,
  });
  const [tab, setTab] = useState<string>(router.location.pathname);

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      setTab(hash);
    } else {
      setTab("all");
    }
  }, [location.hash]);

  const tabs = [
    {
      value: `all`,
      label: t("PAGES.BOOKING.SUB_ROUTES.ALL_ROOMS"),
      default: true,
    },
    {
      value: `search`,
      label: t("PAGES.BOOKING.SUB_ROUTES.SEARCH"),
    },
  ].filter((tab): tab is { value: string; label: string; default?: boolean } =>
    Boolean(tab),
  );

  return (
    <div className={`pageWithoutBottomSpacing ${styles.container}`}>
      <div className="pageHeader">
        <h1>{t("PAGES.BOOKING.HEADER")}</h1>
        <p>
          <Trans
            i18nKey="PAGES.BOOKING.SUBHEADER"
            values={{ buildingName: building.displayName }}
          >
            Text
            <a href="" target="_blank">
              link
            </a>
            .
          </Trans>
        </p>
      </div>
      {isLoading || isRoomsLoading ? (
        <div
          style={{
            height: 250,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : bookings?.length && rooms ? (
        <div className={styles.bookingsCardsGrid}>
          {bookings.map((booking) => (
            <RoomBookingCard key={booking.id} booking={booking} />
          ))}
        </div>
      ) : (
        <PageCardsPlaceholder
          icon={["date_range", "meeting_room", "domain"]}
          placeholder={t("PAGES.BOOKING.PLACEHOLDER")}
        />
      )}
      <div className={styles.bookingContainer}>
        <h2>{t("PAGES.BOOKING.BOOK")}</h2>
        <SelectRow
          label="Tabs"
          hideLabel
          name="bookings-tabs"
          options={tabs}
          value={tab}
          onChange={(tab) => {
            navigate({
              hash: tab as string,
            });
          }}
        />
        <div className={styles.bookingContent}>
          {tab === "all" ? <AllRooms /> : <SearchForRooms />}
        </div>
      </div>
    </div>
  );
}
