/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DateString,
  PreRegistrationGroup,
  Timestamp,
  TimeString,
} from "@/types";
import { SortingFn } from "@tanstack/react-table";
import { addMinutes, isAfter, isBefore } from "date-fns";
import { format } from "date-fns-tz";
import { Event } from "react-big-calendar";

const FULL_DATE_LOCALE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};
const FULL_DATE_LOCALE = (lang: string) =>
  new Intl.DateTimeFormat(
    lang === "en" ? "en-GB" : "nb-NO",
    FULL_DATE_LOCALE_OPTIONS,
  );

const DATE_TIME_LOCALE_OPTIONS: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};
const DATE_TIME_LOCALE = (lang: string) =>
  new Intl.DateTimeFormat(
    lang === "en" ? "en-GB" : "nb-NO",
    DATE_TIME_LOCALE_OPTIONS,
  );

const BOOKING_DATE_LOCALE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "long",
};
export const BOOKING_DATE_LOCALE = (lang: string) =>
  new Intl.DateTimeFormat(
    lang === "en" ? "en-GB" : "nb-NO",
    BOOKING_DATE_LOCALE_OPTIONS,
  );

export const formatTimestampToTimeAgo = (
  t: (key: any, values?: any) => any,
  timestamp: Timestamp,
  lang: string = "no",
  withYear?: boolean,
): string => {
  if (!timestamp._seconds) {
    return "Invalid timestamp";
  }

  const currTime = new Date();
  const date = new Date(timestamp._seconds * 1000);
  const diffInMinutes = Math.floor(
    (currTime.getTime() - date.getTime()) / 60000,
  );

  if (diffInMinutes <= 0) {
    return t("JUST_NOW");
  } else if (diffInMinutes < 60) {
    return t("MINUTES_AGO", { count: diffInMinutes });
  } else if (withYear) {
    if (currTime.getFullYear() !== date.getFullYear()) {
      return FULL_DATE_LOCALE(lang).format(date);
    } else {
      return DATE_TIME_LOCALE(lang).format(date);
    }
  } else {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
};

export const timestampSortingFn: SortingFn<any> = (rowA, rowB, columnId) => {
  const getSortableTuple = (value: any): [number, number] => {
    if (typeof value === "object" && value?._seconds !== undefined) {
      return [1, value._seconds]; // [validity flag, actual seconds]
    }
    return [0, 0]; // Invalid timestamps: [invalid flag, zero or low priority value]
  };

  const [aValidFlag, aSeconds] = getSortableTuple(rowA.getValue(columnId));
  const [bValidFlag, bSeconds] = getSortableTuple(rowB.getValue(columnId));

  // Sort by validity first, then by the timestamp value
  if (aValidFlag !== bValidFlag) {
    return bValidFlag - aValidFlag; // Invalid (0) should come before valid (1)
  }
  return aSeconds - bSeconds;
};

export const env = (key: string) => {
  const value = import.meta.env[key];
  if (!value) throw new Error(`Env ${key} not found`);
  return value;
};

export const validatePhoneNumber = (number: string) => {
  if (isNorwegianNumber(number)) {
    const cleanedNumber = cleanPhoneNumber(number);
    if (
      (cleanedNumber.includes("+47") && cleanedNumber.length === 11) ||
      (!cleanedNumber.includes("+47") && cleanedNumber.length === 8)
    ) {
      return true;
    } else {
      return false;
    }
  }

  if (number.length >= 8 && number.includes("+")) {
    return true;
  }

  return false;
};

export const cleanPhoneNumber = (number: string) => {
  if (!number) {
    return "";
  }
  return number.replace(/[^\d+]/g, "");
};

export const isNumberMissingCountryCode = (number: string) => {
  //this is only needed for foreign numbers. We can only assume that numbers with more than 8 digits are foreign.
  return number.length > 8 && !number.includes("+");
};

export const isNorwegianNumber = (number: string) => {
  return (
    number.substring(0, 3) === "+47" ||
    (!number.includes("+") && number.length == 8)
  );
};

export const addNorwegianCountryCodeToNorwegianNumbers = (number: string) => {
  if (isNorwegianNumber(number)) {
    if (!number.includes("+47")) {
      return "+47" + number;
    }
  }
  return number;
};

export const downloadFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const formatTimestampPreregistration = (
  timestamp: Timestamp,
  t: (key: any, values?: any) => any,
  lang: string = "no",
) => {
  if (!timestamp._seconds) {
    return "Invalid timestamp";
  }

  const currTime = new Date();
  const date = new Date(timestamp._seconds * 1000);

  const currYear = currTime.getFullYear();
  const currDay = currTime.getDate();
  const currMonth = currTime.getMonth();

  const targetYear = date.getFullYear();
  const targetDay = date.getDate();
  const targetMonth = date.getMonth();

  const isToday =
    targetYear === currYear &&
    targetMonth === currMonth &&
    targetDay === currDay;

  const isTomorrow =
    targetYear === currYear &&
    targetMonth === currMonth &&
    targetDay === currDay + 1;

  const timeString = date.toLocaleTimeString(
    lang === "en" ? "en-GB" : "nb-NO",
    { timeStyle: "short" },
  );

  if (isToday) {
    return `${t("TODAY")}, ${t("AT")} ${timeString}`;
  } else if (isTomorrow) {
    return `${t("TOMORROW")}, ${t("AT")} ${timeString}`;
  } else if (targetYear === currYear) {
    return DATE_TIME_LOCALE(lang).format(date);
  } else {
    return FULL_DATE_LOCALE(lang).format(date);
  }
};

export const formatTimestampToHoursAndMinutes = (timestamp: Timestamp) => {
  if (!timestamp._seconds) {
    return "Invalid timestamp";
  }
  const date = new Date(timestamp._seconds * 1000);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const filterTodayPreregistrations = (
  preregistrations: PreRegistrationGroup[],
) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return preregistrations.filter((preregistration) => {
    const preregistrationDate = new Date(
      preregistration.startTime._seconds * 1000,
    );
    preregistrationDate.setHours(0, 0, 0, 0);
    return preregistrationDate.getTime() === today.getTime();
  });
};

export const totalUsersSortFn: SortingFn<any> = (rowA, rowB, columnId) => {
  // Helper function to extract totalUsers with a fallback for invalid data
  const getSortableTuple = (value: any): [number, number] => {
    if (Array.isArray(value) && typeof value[1] === "number") {
      return [1, value[1]]; // [validity flag, totalUsers value]
    }
    return [0, 0]; // Invalid data: [invalid flag, zero priority value]
  };

  const [aValidFlag, totalUsersA] = getSortableTuple(rowA.getValue(columnId));
  const [bValidFlag, totalUsersB] = getSortableTuple(rowB.getValue(columnId));

  // Sort by validity first, then by the totalUsers value
  if (aValidFlag !== bValidFlag) {
    return bValidFlag - aValidFlag; // Invalid (0) comes before valid (1)
  }
  return totalUsersA - totalUsersB;
};

export const formatDateToTimezonedString = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssXXX", {
    timeZone: "Europe/Oslo",
  });
};

export const isDateString = (value: string): value is DateString => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(value);
};

export const dateToDateInputString = (date: Date): DateString => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}` as DateString;
};

export const dateInputStringToDate = (date: DateString): Date => {
  const [year, month, day] = date.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const dateToTimeInputString = (date: Date): TimeString => {
  return date.toLocaleTimeString("nb-NO", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }) as TimeString;
};

export const timeInputStringToDate = (
  time: TimeString,
  onDate?: Date | DateString,
): Date => {
  const [hours, minutes] = time.split(":").map(Number);
  const date = onDate
    ? onDate instanceof Date
      ? new Date(onDate)
      : dateInputStringToDate(onDate)
    : new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
};

export const calculateEndTime = (
  startTime: TimeString,
  duration: number,
  onDate?: Date | DateString,
): Date => {
  const [hours, minutes] = startTime.split(":");

  const startTimeAsDate = onDate ? new Date(onDate) : new Date();
  startTimeAsDate.setHours(Number(hours));
  startTimeAsDate.setMinutes(Number(minutes));

  return addMinutes(startTimeAsDate, duration * 60);
};

export const isSlotOverlapping = (
  bookedSlots: Event[],
  newSlot: { start: Date; end: Date },
): boolean => {
  return bookedSlots.some((slot) => {
    return (
      !!slot.end &&
      !!slot.start &&
      // New slot starts before the existing slot ends, but not exactly at the end
      isBefore(newSlot.start, slot.end) &&
      // New slot ends after the existing slot starts, but not exactly at the start
      isAfter(newSlot.end, slot.start)
    );
  });
};

export const debounceAsync = <T extends (...args: any[]) => Promise<any>>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => Promise<ReturnType<T>>) => {
  let timeoutId: NodeJS.Timeout;
  let resolveList: ((value: ReturnType<T>) => void)[] = [];

  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    clearTimeout(timeoutId);

    return new Promise<ReturnType<T>>((resolve) => {
      resolveList.push(resolve);

      timeoutId = setTimeout(async () => {
        const result = await func(...args);
        resolveList.forEach((res) => res(result));
        resolveList = [];
      }, delay);
    });
  };
};
