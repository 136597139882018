/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "@/styles/Calendar.module.scss";
import {
  Calendar as ReactBigCalendar,
  dateFnsLocalizer,
  SlotInfo,
  View,
  Event,
} from "react-big-calendar";
import { enGB, nb } from "date-fns/locale";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  differenceInMinutes,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  isWeekend,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbarStore } from "@/store/zustandStore";
import { NotificationType, TimeString } from "@/types";
import { BuiltInToolbarProps, CalendarToolbar } from "./CalendarToolbar";
import { isSlotOverlapping, timeInputStringToDate } from "@/lib/utils";
import { CALENDAR_TIME_ZONE, DEFAULT_BOOKING_SETTINGS } from "@/lib/constants";
import { toZonedTime, fromZonedTime } from "date-fns-tz";

function roundToNearestHalfHour(date: Date): Date {
  const msInHalfHour = 30 * 60 * 1000;
  const roundedDate = new Date(
    Math.round(date.getTime() / msInHalfHour) * msInHalfHour,
  );
  return roundedDate;
}

const locales = {
  en: enGB,
  no: nb,
};

const customFormat = (date: Date, formatString: string, options?: object) => {
  const zonedDate = toZonedTime(date, CALENDAR_TIME_ZONE);
  return format(zonedDate, formatString, options);
};

const customParse = (
  value: string,
  formatString: string,
  backupDate: Date,
  options?: object,
) => {
  const parsedDate = parse(value, formatString, backupDate, options);
  return fromZonedTime(parsedDate, CALENDAR_TIME_ZONE);
};

const localizer = dateFnsLocalizer({
  format: customFormat,
  parse: customParse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

const formats = {
  timeGutterFormat: "HH:mm", // Format for the time gutter (left side)
  eventTimeRangeFormat: ({ start, end }: any, culture: any, localizer: any) =>
    `${localizer.format(start, "HH:mm", culture)} - ${localizer.format(end, "HH:mm", culture)}`,
  dayRangeHeaderFormat: ({ start, end }: any, culture: any, localizer: any) =>
    `${localizer.format(start, "MMM dd", culture)} - ${localizer.format(end, "MMM dd", culture)}`,
  agendaTimeRangeFormat: ({ start, end }: any, culture: any, localizer: any) =>
    `${localizer.format(start, "HH:mm", culture)} - ${localizer.format(end, "HH:mm", culture)}`,
};

const now = new Date();

interface CalendarProps {
  events: Event[];
  onSlotSelected?: (slot: SlotInfo) => void;
  minDate?: Date;
  maxDate?: Date;
  minTime?: TimeString;
  maxTime?: TimeString;
  maxDuration?: number;
}

export default function Calendar({
  events,
  onSlotSelected,
  minDate = DEFAULT_BOOKING_SETTINGS.minDate,
  maxDate = DEFAULT_BOOKING_SETTINGS.maxDate,
  minTime = DEFAULT_BOOKING_SETTINGS.minTime,
  maxTime = DEFAULT_BOOKING_SETTINGS.maxTime,
  maxDuration = DEFAULT_BOOKING_SETTINGS.maxDuration,
}: CalendarProps) {
  const { t, i18n } = useTranslation();
  const { addNotification } = useSnackbarStore();
  const minTimeAsDate = useMemo(() => {
    const [hours, minutes] = minTime.split(":").map(Number);
    return new Date(1972, 0, 1, hours, minutes, 0);
  }, []);
  const maxTimeAsDate = useMemo(() => {
    const [hours, minutes] = maxTime.split(":").map(Number);
    return new Date(1972, 0, 1, hours, minutes, 0);
  }, []);
  const mappedEvents = useMemo(
    () =>
      events.map((ev) => ({
        ...ev,
        start: new Date(ev.start || ""),
        end: new Date(ev.end || ""),
      })),
    [events],
  );
  const [view, setView] = useState<View>("week");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 1000) {
      setView("day"); // Use day view for small screens
    } else {
      setView("week"); // Use week view for medium and larger screens
    }
  }, [windowWidth]);

  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: now,
      views: {
        week: true,
        day: true,
      },
    }),
    [],
  );

  const dayPropGetter = (date: Date) => {
    if (isToday(date)) {
      return {
        style: {
          color: "black",
        },
      };
    }
    if (isBefore(date, minDate) || isBefore(maxDate, date)) {
      return {
        className: styles.disabledCalendarArea,
      };
    }
    if (isWeekend(date)) {
      return {
        style: {
          backgroundColor: "#cf3a3a08",
          color: "var(--wlcm-color-danger-red)",
        },
      };
    }
    return {};
  };

  const slotPropGetter = (date: Date) => {
    if (isToday(date) && isBefore(date, now)) {
      return {
        className: styles.disabledCalendarArea,
      };
    }
    return {};
  };

  const eventPropGetter = (event: Event) => {
    if (event.title !== "BOOKED") {
      // User's own bookings
      return {
        style: {
          backgroundColor: "var(--wlcm-color-success-green)",
          borderColor: "var(--wlcm-color-success-green)",
          color: "black",
        },
      };
    }
    return {};
  };

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    if (!onSlotSelected) return;

    slotInfo.start = roundToNearestHalfHour(slotInfo.start);
    slotInfo.end = roundToNearestHalfHour(slotInfo.end);

    if (!isSameDay(slotInfo.start, slotInfo.end)) {
      addNotification(
        t("BOOKINGS.VALIDATION.NO_MULTI_DAY"),
        NotificationType.WARNING,
      );
      return;
    }
    if (differenceInMinutes(slotInfo.end, slotInfo.start) / 60 >= 24) {
      addNotification(
        t("BOOKINGS.VALIDATION.NO_ALL_DAY"),
        NotificationType.WARNING,
      );
      return;
    }
    if (differenceInMinutes(slotInfo.end, slotInfo.start) / 60 > maxDuration) {
      addNotification(
        t("BOOKINGS.VALIDATION.OVER_MAX_DURATION", { duration: maxDuration }),
        NotificationType.WARNING,
      );
      return;
    }
    if (isBefore(slotInfo.start, now)) {
      addNotification(
        t("BOOKINGS.VALIDATION.STARTING_IN_PAST"),
        NotificationType.WARNING,
      );
      return;
    }
    if (
      isBefore(slotInfo.start, timeInputStringToDate(minTime, slotInfo.start))
    ) {
      addNotification(
        t("BOOKINGS.VALIDATION.START_BEFORE_MIN_TIME", { time: minTime }),
        NotificationType.WARNING,
      );
      return;
    }
    if (isAfter(slotInfo.end, timeInputStringToDate(maxTime, slotInfo.start))) {
      addNotification(
        t("BOOKINGS.VALIDATION.END_AFTER_MAX_TIME", { time: maxTime }),
        NotificationType.WARNING,
      );
      return;
    }
    if (isSlotOverlapping(mappedEvents, slotInfo)) {
      addNotification(
        t("BOOKINGS.VALIDATION.OVERLAPPING"),
        NotificationType.WARNING,
      );
      return;
    }
    onSlotSelected(slotInfo);
  };

  return (
    <ReactBigCalendar
      key={view}
      culture={i18n.language}
      defaultDate={defaultDate}
      defaultView={view}
      min={minTimeAsDate}
      max={maxTimeAsDate}
      events={mappedEvents}
      localizer={localizer}
      formats={formats}
      views={views}
      step={30}
      timeslots={1}
      scrollToTime={new Date(now.getTime() - 30 * 60 * 1000)} // half an hour ago
      selectable={!!onSlotSelected}
      style={{ height: "50vh", minHeight: "30rem" }}
      onSelectSlot={handleSelectSlot}
      dayPropGetter={dayPropGetter}
      slotPropGetter={slotPropGetter}
      eventPropGetter={eventPropGetter}
      components={{
        toolbar: (props: BuiltInToolbarProps) => (
          <CalendarToolbar {...props} minDate={minDate} maxDate={maxDate} />
        ),
      }}
    />
  );
}
