import styles from "@/styles/Auth.module.scss";
import { useTranslation } from "react-i18next";

import whiteLogo from "@/assets/whiteLogo.svg";
import { Button } from "@app-components";
import { auth } from "@/lib/firebase";

const signOut = () => {
  auth.signOut();
  const baseUrl = window.location.origin + window.location.pathname;
  window.location.replace(baseUrl);
};

const LoadingScreen = () => {
  const { t } = useTranslation();

  return (
    <div className={`center ${styles.loadingScreen}`}>
      <img width={75} height={75} src={whiteLogo} alt="Welcome Workdays" />
      <h1>
        {t("PAGE_LOADING.LOADING")}
        <span className={styles.dot}>.</span>
        <span className={styles.dot}>.</span>
        <span className={styles.dot}>.</span>
      </h1>
      <p>{t("PAGE_LOADING.TAKING_TOO_LONG")}</p>
      <Button secondary onClick={signOut}>
        {t("SIDEBAR.LOG_OUT")}
      </Button>
    </div>
  );
};

export default LoadingScreen;
