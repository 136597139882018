import React, { useState, useEffect, useRef, CSSProperties } from "react";
import { InputField, InputFieldProps } from "../InputField/InputField";
import { Icon } from "../Icon/icon";
import styles from "./Dropdown.module.scss";
import { Chip } from "../Chip";
import { Checkbox } from "../Checkbox";

interface Option {
  label: string;
  value: string;
}

type BaseDropdownProps = Omit<
  InputFieldProps,
  "value" | "append" | "type" | "onChange"
> & {
  options?: Option[];
  menuPlacement?: "top" | "bottom";
  maxMenuHeight?: number;
  clearable?: boolean;
  fetchOptions?: (input: string) => Promise<Option[] | undefined>;
  onFetchFailed?: (error: unknown) => void;
  startSearchText?: string;
  noResultsText?: string;
  searchingText?: string;
  selectChip?: {
    style?: CSSProperties;
    replaceLabelsWithIcons?: boolean;
  };
};

type SingleSelectProps = BaseDropdownProps & {
  isMulti?: false;
  value?: Option;
  onChange: (option: Option | undefined) => void;
};

type MultiSelectProps = BaseDropdownProps & {
  isMulti: true;
  value?: Option[];
  onChange: (option: Option[] | undefined) => void;
};

type DropdownProps = SingleSelectProps | MultiSelectProps;

export function Dropdown({
  value,
  options,
  isMulti,
  placeholder = "Select...",
  menuPlacement = "bottom",
  maxMenuHeight,
  clearable = false,
  fetchOptions,
  onFetchFailed,
  onChange,
  startSearchText = "Start typing to search",
  noResultsText = "No results",
  searchingText = "Searching...",
  selectChip,
  ...inputProps
}: DropdownProps) {
  const [inputValue, setInputValue] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>(
    options || [],
  );
  const [menuStyles, setMenuStyles] = useState<React.CSSProperties>({});
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(
    menuPlacement === "bottom" ? -1 : dropdownOptions.length,
  );
  const [isSearching, setIsSearching] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const listboxRef = useRef<HTMLUListElement>(null);
  const mouseDownRef = useRef(false);
  const optionSelectedRef = useRef(false);
  const { dark, inputBgColor } = inputProps;

  useEffect(() => {
    // Filter on static options
    if (options) {
      if (inputValue) {
        const filteredOptions = options.filter((o) =>
          o.label.toLowerCase().includes(inputValue.toLowerCase()),
        );
        setDropdownOptions(filteredOptions);
      } else {
        setDropdownOptions(options);
      }
    }
    // Async search to fetch options from backend
    else {
      if (!fetchOptions) return;
      if (!inputValue) {
        if (!optionSelectedRef.current) {
          setDropdownOptions([]);
          optionSelectedRef.current = false;
        }
        setIsSearching(false);
        return;
      }

      setIsSearching(true);
      const delayDebounceFn = setTimeout(async () => {
        try {
          const results = await fetchOptions(inputValue);
          setDropdownOptions(results || []);
        } catch (error) {
          console.error(error);
          if (onFetchFailed) onFetchFailed(error);
        } finally {
          setIsSearching(false);
        }
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [inputValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setInputValue(e.target.value);
  };

  const handleOptionClick = (option: Option) => {
    if (isMulti) {
      const optionAlreadySelected =
        value && value.some((o) => o.value === option.value);
      const newSelectedOptions = optionAlreadySelected
        ? value.filter((o) => o.value !== option.value)
        : [...(value ? value : []), option];
      onChange(newSelectedOptions);
      const inputElement = inputRef.current?.querySelector("input");
      inputElement?.focus();
    } else {
      optionSelectedRef.current = true;
      setIsOpen(false);
      onChange(option);
    }
    setInputValue("");
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex(
          (prevIndex) => (prevIndex + 1) % dropdownOptions.length,
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex(
          (prevIndex) =>
            (prevIndex - 1 + dropdownOptions.length) % dropdownOptions.length,
        );
        break;
      case "Enter":
        if (
          highlightedIndex >= 0 &&
          highlightedIndex < dropdownOptions.length
        ) {
          handleOptionClick(dropdownOptions[highlightedIndex]);
        }
        break;
      case "Escape":
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    if (mouseDownRef.current) return;
    setIsOpen(false);
    setInputValue("");
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setInputValue("");
    setDropdownOptions(options || []);
    onChange(undefined);
  };

  const handleMouseDown = () => {
    mouseDownRef.current = true;
  };

  const handleMouseUp = () => {
    mouseDownRef.current = false;
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (highlightedIndex >= 0 && highlightedIndex < dropdownOptions.length) {
      const listbox = listboxRef.current;
      const option = listbox?.children[highlightedIndex] as HTMLElement;
      option?.scrollIntoView({ block: "nearest" });
    }
  }, [highlightedIndex, dropdownOptions]);

  useEffect(() => {
    if (isOpen && inputRef.current && containerRef.current) {
      containerRef.current.style.zIndex = "101";
      inputRef.current.style.zIndex = "102";
      const inputRect = inputRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const placement = `${inputRect.bottom - containerRect.top - inputRect.height / 2}px`;
      const padding = `calc(${inputRect.height / 2}px + var(--wlcm-spacing-xs))`;
      setMenuStyles({
        ...(menuPlacement === "bottom"
          ? {
              top: placement,
              paddingTop: padding,
              visibility: "visible",
            }
          : {
              bottom: placement,
              paddingBottom: padding,
              visibility: "visible",
            }),
        left: `${inputRect.left - containerRect.left}px`,
        width: `${containerRect.width}px`,
      });
      if (menuPlacement === "top") {
        setTimeout(() => {
          menuRef.current?.scrollTo(0, menuRef.current.scrollHeight);
        }, 10);
      }
    } else if (!isOpen && containerRef.current && inputRef.current) {
      containerRef.current.style.zIndex = "99";
      inputRef.current.style.zIndex = "99";
    }
  }, [isOpen, value, menuPlacement]);

  return (
    <div className={styles.container} ref={containerRef}>
      <InputField
        {...inputProps}
        ref={inputRef}
        placeholder={isMulti && value?.length ? "" : placeholder}
        prepend={
          isMulti &&
          value?.map((op) => (
            <Chip
              active
              key={op.value}
              label={op.label}
              {...(selectChip?.replaceLabelsWithIcons &&
              op["icon" as keyof Option]
                ? { icon: op["icon" as keyof Option] }
                : {})}
              onClick={(e) => {
                e.stopPropagation();
                onChange(value.filter((o) => o.value !== op.value));
              }}
              style={{ textWrap: "nowrap", ...selectChip?.style }}
            ></Chip>
          ))
        }
        value={isOpen || isMulti ? inputValue : value?.label}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsOpen(true)}
        onBlur={handleBlur}
        {...(clearable ? { onClear: handleClear } : {})}
        aria-autocomplete="list"
        aria-controls="dropdown-listbox"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        append={
          <Icon
            name="expand_more"
            style={{
              marginRight: "var(--wlcm-spacing-xs)",
            }}
          />
        }
        inputBgColor={
          inputBgColor
            ? inputBgColor
            : dark
              ? "var(--wlcm-color-black)"
              : "var(--wlcm-color-white)"
        }
        customStyles={{
          scrollContainer: { paddingRight: 24 },
        }}
      />
      {isOpen && (
        <div
          ref={menuRef}
          id="dropdown-listbox"
          role="listbox"
          aria-labelledby="dropdown-input"
          onMouseDown={handleMouseDown}
          className={`${styles.dropdownMenu} ${dark && styles.dark} ${styles[menuPlacement]}`}
          style={{
            ...menuStyles,
            ...(maxMenuHeight ? { maxHeight: maxMenuHeight } : {}),
          }}
        >
          {isSearching ? (
            <p>{searchingText}</p>
          ) : fetchOptions && !dropdownOptions.length && !inputValue ? (
            <p>{startSearchText}</p>
          ) : dropdownOptions.length ? (
            <ul ref={listboxRef}>
              {dropdownOptions.map((option, index) => (
                <li
                  key={option.value}
                  role="option"
                  aria-selected={highlightedIndex === index}
                  className={`
                    ${styles.dropdownOption} 
                    ${highlightedIndex === index ? styles.highlighted : ""} 
                    ${!isMulti && value?.value === option.value ? styles.selected : ""}
                `}
                  style={
                    isMulti
                      ? {
                          paddingLeft:
                            "calc(var(--wlcm-spacing-xs) + var(--wlcm-spacing-md))",
                        }
                      : {}
                  }
                  onClick={() => handleOptionClick(option)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                >
                  {isMulti && (
                    <Checkbox
                      label={option.label}
                      hideLabel
                      dark={dark}
                      checked={!!value?.find((op) => op.value === option.value)}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOptionClick(option);
                      }}
                    />
                  )}
                  <span aria-hidden={isMulti}>{option.label}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p>{noResultsText}</p>
          )}
        </div>
      )}
    </div>
  );
}
