import { CompanyVisit, PreRegistrationGroup, Visit } from "@/types";
import { useTranslation } from "react-i18next";
import styles from "@/styles/Visitors.module.scss";
import PageCardsPlaceholder from "@/components/PageCardsPlaceholder";
import VisitorCard from "@/components/VisitorCard";
import PreregistrationGroupCard from "./PreregistredVisitorCard";
import { filterTodayPreregistrations } from "@/lib/utils";

interface CombinedVisitorsAndPreregistrationsProps {
  visitors: Visit[] | CompanyVisit[];
  preregistrations: PreRegistrationGroup[];
  isCompany?: boolean;
}

export const CombinedVisitorsAndPreregistrations = ({
  visitors,
  preregistrations,
  isCompany,
}: CombinedVisitorsAndPreregistrationsProps) => {
  const { t } = useTranslation();

  const todayPreregistrations = filterTodayPreregistrations(preregistrations);

  // Combine visitors and preregistrations
  const combinedToday = [
    ...visitors.map((visit) => ({ ...visit, type: "visitor" })),
    ...todayPreregistrations.map((preregistration) => ({
      ...preregistration,
      type: "preregistration",
    })),
  ];

  if (combinedToday.length === 0) {
    return (
      <PageCardsPlaceholder
        icon="person"
        placeholder={t("PAGES.VISITORS.PLACEHOLDER", {
          entity: isCompany ? t("COMPANY") : t("YOU"),
        })}
      />
    );
  }

  return (
    <div className={styles.cards}>
      {combinedToday.map((entry) =>
        entry.type === "visitor" ? (
          <VisitorCard
            key={entry.id}
            data={entry as Visit | CompanyVisit}
            company={isCompany}
          />
        ) : (
          <PreregistrationGroupCard
            key={entry.id}
            data={entry as PreRegistrationGroup}
            today
            company={isCompany}
          />
        ),
      )}
    </div>
  );
};
