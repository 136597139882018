import { usePrompt } from "@/hooks/usePrompt";
import { useLoggedInUser } from "@/providers/AuthProvider";
import { useSnackbarStore } from "@/store/zustandStore";
import { NotificationType, PreRegistrationAttendee, User } from "@/types";
import { Button, Breadcrumbs, InputField } from "@app-components";
import { useForm } from "@tanstack/react-form";
import { useNavigate } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { AttendeeInputRow } from "./AttendeeInputRow";
import { PreregistrationAttendeesTable } from "./PreregistrationAttendeesTable";
import styles from "@/styles/Preregistration.module.scss";
import { nb } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { getCompanyFunction, getUser, createPreregistrationGroup } from "@/api";
import { PersonSearch } from "../PersonSearch";

const customNorwegianLocale = {
  ...nb,
  options: {
    ...nb.options,
    weekStartsOn: 1 as const,
  },
};
registerLocale("custom-nb", customNorwegianLocale);

interface GroupPreregistrationProps {
  companyId?: string;
  noResultsText?: string;
  showAddHost?: boolean;
  breadcrumbs: {
    label: string;
    path: string;
  }[];
  showCompany?: boolean;
}

export const GroupPreregistration = ({
  companyId,
  noResultsText,
  showAddHost,
  breadcrumbs,
  showCompany,
}: GroupPreregistrationProps) => {
  const { t } = useTranslation();
  const user = useLoggedInUser();
  const navigate = useNavigate();
  const { addNotification } = useSnackbarStore();
  const [attendees, setAttendees] = useState<PreRegistrationAttendee[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [foundUser, setFoundUser] = useState<User | null | undefined>(); // undefined before search, null if no user is found
  const [isSearching, setIsSearching] = useState(false);
  const [host, setHost] = useState<User | undefined>(
    showAddHost ? undefined : user,
  );
  const [hostAdded, setHostAdded] = useState(false);
  const hostCompanyId =
    foundUser?.buildingTenantId || companyId || user.buildingTenantId;

  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    const fetchCompany = async () => {
      if (hostCompanyId) {
        const company = await getCompanyFunction(hostCompanyId);
        if (company) {
          setCompanyName(company.buildingTenantName);
        }
      }
    };
    fetchCompany();
  }, [hostCompanyId]);

  const [currentAttendee, setCurrentAttendee] =
    useState<PreRegistrationAttendee>({
      name: "",
      phone: "",
      email: "",
    });

  usePrompt({
    isOpen: hasUnsavedChanges,
  });

  const handleAddAttendee = (attendee: PreRegistrationAttendee) => {
    if (attendee.name.trim() && attendee.phone.trim()) {
      setAttendees([...attendees, attendee]);
      setCurrentAttendee({ name: "", phone: "", email: "" });
    }
  };

  const handleAttendeeChange = (value: PreRegistrationAttendee) => {
    setCurrentAttendee(value);
  };

  const handleDeleteAttendee = (index: number) => {
    setAttendees((prevAttendees) =>
      prevAttendees.filter((_, i) => i !== index),
    );
  };

  const searchForUser = async () => {
    if (!searchQuery) {
      setFoundUser(undefined);
      return;
    }
    setIsSearching(true);
    try {
      const user = await getUser(
        "email",
        searchQuery.toLocaleLowerCase(),
        companyId,
      );
      setFoundUser(user);
    } catch (error) {
      console.error(error);
      setFoundUser(null);
    } finally {
      setIsSearching(false);
    }
  };

  const addHost = () => {
    setHost(foundUser ?? undefined);
    setHostAdded(true);
  };

  const deleteHost = () => {
    setHost(undefined);
    setHostAdded(false);
    setSearchQuery("");
    setFoundUser(undefined);
  };

  const form = useForm({
    defaultValues: {
      eventName: "",
      eventDescription: "",
      startTime: new Date(),
      attendees: [] as PreRegistrationAttendee[],
    },
    onSubmit: async ({ value }) => {
      try {
        await createPreregistrationGroup(
          host?.userId || user.userId,
          host?.email || user.email,
          hostCompanyId,
          companyName,
          value.eventName,
          value.eventDescription,
          value.startTime,
          attendees,
          `${host?.firstName} ${host?.lastName}`,
          host?.phone || user.phone || "",
          host?.language || "no",
          host?.notificationPreferences,
        );

        setHasUnsavedChanges(false);
        addNotification(
          "Group preregistration created successfully.",
          NotificationType.SUCCESS,
        );
        navigate({ to: breadcrumbs[0].path });
      } catch (error) {
        console.error(error);
        addNotification("Failed to create group preregistration.");
      }
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <div className={styles.preregistrationContainer}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px var(--wlcm-spacing-sm)",
          flexWrap: "wrap",
          marginBottom: "var(--wlcm-spacing-xs)",
        }}
      >
        <Button
          secondary
          size="sm"
          icon="arrow_back"
          onClick={() => navigate({ to: breadcrumbs[0].path })}
        >
          {t("BACK")}
        </Button>
        <Breadcrumbs
          levels={breadcrumbs}
          onNavigate={(path) => navigate({ to: path })}
        />
      </div>
      <div className="pageHeader">
        <h1>{t("PREREGISTRATION.HEADER")}</h1>
        <p>{t("PREREGISTRATION.DESCRIPTION")}</p>
      </div>
      <form
        onChange={() => setHasUnsavedChanges(true)}
        onSubmit={(e) => {
          e.preventDefault();
          form.handleSubmit();
        }}
      >
        <div>
          <div className={styles.preregistrationUpperInputRow}>
            <form.Field
              name="eventName"
              validators={{
                onBlur: z
                  .string()
                  .min(
                    1,
                    t("VALIDATION.REQUIRED", {
                      field: t("PREREGISTRATION.EVENT_NAME"),
                    }),
                  )
                  .min(
                    5,
                    t("VALIDATION.MIN_LENGTH", {
                      field: t("PREREGISTRATION.EVENT_NAME"),
                      length: 5,
                      type: t("CHARACTERS"),
                    }),
                  )
                  .max(
                    40,
                    t("VALIDATION.MAX_LENGTH", {
                      field: t("PREREGISTRATION.EVENT_NAME"),
                      length: "40",
                      type: t("CHARACTERS"),
                    }),
                  )
                  .trim(),
              }}
            >
              {(field) => (
                <>
                  <InputField
                    autoFocus
                    required
                    maxLength={40}
                    label={t("PREREGISTRATION.EVENT_NAME")}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    dangerText={field.state.meta.errors.toString()}
                  />
                </>
              )}
            </form.Field>
            <form.Field name="startTime">
              {(field) => {
                const now = new Date();

                // Compute minTime dynamically based on whether the selected date is today
                const minTime =
                  field.state.value &&
                  new Date(field.state.value).toDateString() === now.toDateString()
                    ? now 
                    : new Date(0, 0, 0, 0, 0);
                return (
                  <>
                    <DatePicker
                      required
                      selected={field.state.value}
                      onChange={(date: Date | null) =>
                        date && field.handleChange(date)
                      }
                      showTimeSelect
                      minDate={now}
                      minTime={minTime} 
                      maxTime={new Date(0, 0, 0, 23, 59)} 
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      className="form-control"
                      locale={user.language === "en" ? "en" : "custom-nb"}
                      customInput={
                        <InputField
                          label={t("PREREGISTRATION.START_TIME")}
                          onChange={(e) =>
                            field.handleChange(new Date(e.target.value))
                          }
                          icon="date_range"
                          dangerText={field.state.meta.errors.toString()}
                        />
                      }
                    />
                  </>
                );
              }}
            </form.Field>
            <form.Field name="eventDescription">
              {(field) => (
                <div className={styles.eventDescription}>
                  <InputField
                    label={t("PREREGISTRATION.EVENT_DESCRIPTION")}
                    placeholder={t(
                      "PREREGISTRATION.EVENT_DESCRIPTION_PLACEHOLDER",
                    )}
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.target.value)}
                    infoText={t("PREREGISTRATION.EVENT_DESCRIPTION_INFO")}
                  />
                </div>
              )}
            </form.Field>

            <div className={styles.personSearchConatiner}>
              {showAddHost && (
                <PersonSearch
                  required
                  label={t("PREREGISTRATION.HOST")}
                  noResultsText={noResultsText ?? ""}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  isSearching={isSearching}
                  searchForUser={searchForUser}
                  foundUser={foundUser}
                  addUser={addHost}
                  userAdded={hostAdded}
                  deleteHost={deleteHost}
                  showCompany={showCompany}
                  infoText={t("PREREGISTRATION.ADD_HOST")}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.attendeeInputSection}>
          <AttendeeInputRow
            value={currentAttendee}
            onChange={handleAttendeeChange}
            onAdd={handleAddAttendee}
          />

          <hr className={styles.sectionDivider} />

          <PreregistrationAttendeesTable
            futureVisitors={attendees}
            deleteAttendee={handleDeleteAttendee}
          />
        </div>
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button
              type="submit"
              disabled={
                isSubmitting ||
                !canSubmit ||
                attendees.length < 1 ||
                (showAddHost && !hostAdded)
              }
              isLoading={isSubmitting}
            >
              {t("PREREGISTRATION.INVITE", { number: attendees.length })}
            </Button>
          )}
        />
      </form>
    </div>
  );
};
