import styles from "@/styles/VisitorCard.module.scss";
import { Accordion, Button, Icon, InputField } from "@app-components";
import { NotificationType, PreRegistrationGroup } from "@/types";
import { useTranslation } from "react-i18next";
import {
  formatTimestampPreregistration,
  formatTimestampToHoursAndMinutes,
} from "@/lib/utils";
import i18n from "@/i18n/config";
import { useEffect, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import { usePrompt } from "@/hooks/usePrompt";
import { useSnackbarStore } from "@/store/zustandStore";
import { useQueryClient } from "@tanstack/react-query";
import { deletePreregistrationGroup } from "@/api";

interface PreregistrationGroupdProps {
  data: PreRegistrationGroup;
  company?: boolean;
  today?: boolean;
}

export default function PreregistrationGroupCard({
  data,
  company,
  today,
}: PreregistrationGroupdProps) {
  const { t } = useTranslation();
  const { hostName, eventName, eventDescription, startTime, attendees } = data;
  const queryClient = useQueryClient();
  const { addNotification } = useSnackbarStore();
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileterAttendees, setFilteredAttendees] = useState(attendees);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  usePrompt({
    promptOnNavigate: false,
    isOpen: cancelModalOpen,
    title: t("PREREGISTRATION.CANCEL.HEADER", { eventName }),
    subtitle: t("PREREGISTRATION.CANCEL.BODY"),
    cancelText: t("PREREGISTRATION.CANCEL.DISMISS"),
    confirmText: t("PREREGISTRATION.CANCEL.CONFIRM"),
    onConfirm: async () => {
      setCancelModalOpen(false);
      try {
        await deletePreregistrationGroup(data.id);
        addNotification(
          `${eventName} was successfully cancelled. All attendees have been notified.`,
          NotificationType.SUCCESS,
        );
        queryClient.invalidateQueries({
          queryKey: ["company-preregistration-group"],
        });
        queryClient.invalidateQueries({
          queryKey: ["personal-preregistration-group"],
        });
        queryClient.invalidateQueries({
          queryKey: ["building-preregistration-group"],
        });
      } catch (error) {
        console.error(error);
        addNotification(
          `Failed to cancel ${eventName}. Try again later or notify your attendees manually.`,
        );
      }
    },
    onCancel: () => setCancelModalOpen(false),
  });

  const arrivedAttendees = useMemo(
    () => attendees.filter((attendee) => attendee.arrivedAt).length,
    [attendees],
  );

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const sortedAttendees = attendees
      .sort((a, b) => {
        if (!today) return 0;
        if (!a.arrivedAt && b.arrivedAt) return -1;
        if (a.arrivedAt && !b.arrivedAt) return 1;
        return 0;
      })
      .filter((attendee) =>
        [attendee.name, attendee.email, attendee.phone]
          .filter(Boolean)
          .some((field) => field?.toLowerCase().includes(lowerCaseQuery)),
      );
    setFilteredAttendees(sortedAttendees);
  }, [searchQuery, attendees, today]);

  return (
    <div className={styles.visitorCard}>
      <div className={styles.infoContainer}>
        <div className={styles.infoInnerContainer}>
          <div className={styles.flexBetween}>
            <h3>{eventName}</h3>
            {!arrivedAttendees && (
              <Button
                danger
                secondary
                size="sm"
                onClick={() => setCancelModalOpen(true)}
              >
                {t("CANCEL")}
              </Button>
            )}
          </div>
          {eventDescription && (
            <div className={styles.fullWidth}>
              <p>{eventDescription}</p>
            </div>
          )}
          <div>
            <Icon name="schedule" />
            <p>{formatTimestampPreregistration(startTime, t, i18n.language)}</p>
          </div>
          {company && hostName && (
            <div className={styles.fullWidth}>
              <Icon name="person" />
              <p>
                {t("PREREGISTRATION.HOST")}: {hostName}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.preregistrationAccordion}>
        <Accordion
          id="preregistration-attendees"
          expanded={expanded}
          onToggle={setExpanded}
          header={
            <p>
              {t("PREREGISTRATION.ATTENDEES")}{" "}
              {today ? (
                <strong
                  style={{
                    marginLeft: "var(--wlcm-spacing-xs)",
                    ...(arrivedAttendees === attendees.length
                      ? { color: "var(--wlcm-color-success-green)" }
                      : arrivedAttendees && {
                          color: "var(--wlcm-color-danger-red)",
                        }),
                  }}
                >
                  {`(${arrivedAttendees}/${attendees.length} ${t("ARRIVED", { count: 2 }).toLowerCase()})`}
                </strong>
              ) : (
                `(${attendees.length})`
              )}
            </p>
          }
          body={
            <>
              {attendees.length > 10 && (
                <div className={styles.attendeesSearch}>
                  <InputField
                    icon="search"
                    compact
                    hideLabel
                    label={t("PREREGISTRATION.SEARCH_ATTENDEES")}
                    placeholder={t("PREREGISTRATION.SEARCH_ATTENDEES")}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                  />
                </div>
              )}
              <div className={styles.attendeesContainer}>
                {fileterAttendees.map((attendee) => (
                  <article key={attendee.phone} className={styles.attendee}>
                    <div style={{ display: "flex" }}>
                      {today && attendee.arrivedAt && (
                        <>
                          <Icon
                            className={`${attendee.name.split(" ").join("-") + attendee.phone.substring(1)}-arrived-icon ${styles.greenIcon}`}
                            name="done"
                          />
                          <Tooltip
                            anchorSelect={`.${attendee.name.split(" ").join("-") + attendee.phone.substring(1)}-arrived-icon`}
                            content={`${t("ARRIVED", { count: 1 })} ${t("AT")} ${formatTimestampToHoursAndMinutes(attendee.arrivedAt)}`}
                          />
                        </>
                      )}
                      <h4
                        className={`${today && !attendee.arrivedAt ? styles.withPadding : ""} `}
                      >
                        {attendee.name}
                      </h4>
                    </div>
                    <p>{attendee.phone}</p>
                    {/* <p>{attendee.email}</p> */}
                  </article>
                ))}
              </div>
            </>
          }
        ></Accordion>
      </div>
    </div>
  );
}
