import { useEffect, useState } from "react";
import styles from "./TextEditor.module.scss";

type TextEditorProps = {
  label: string;
  placeholder?: string;
  dark?: boolean;
  value?: string;
  mobile?: boolean;
  onChange: (e?: any) => void;
  hideLabel?: boolean;
};

export function TextEditor({
  label,
  placeholder,
  dark,
  value,
  mobile,
  onChange,
  hideLabel,
}: TextEditorProps) {
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    if (value) setInputValue(value);
  }, [value]);

  const handleEditorChange = (event: any) => {
    setInputValue(event.target.value);
    onChange(event);
  };
  return (
    <div
      className={`${styles.editorContainer} ${dark ? styles.dark : ""} ${mobile ? styles.mobile : ""} `}
    >
      <label
        htmlFor={`${label}_TEXTFIELD`}
        className={hideLabel ? "sr-only" : ""}
      >
        {label}
      </label>
      <textarea
        id={`${label}_TEXTFIELD`}
        value={inputValue}
        className={`${styles.simpleEditor} ${dark ? styles.dark : ""} ${mobile ? styles.mobile : ""}`}
        placeholder={placeholder}
        onChange={(value) => handleEditorChange(value)}
      ></textarea>
    </div>
  );
}
