import styles from "@/styles/Welcome.module.scss";
import whiteLogoFull from "@/assets/whiteLogoFull.svg";
import { getAuthUser } from "@/lib/firebase";
import { Button, Checkbox, Icon, PopupCard } from "@app-components";
import { useRef, useState } from "react";
import { useIntersection } from "@/hooks/useIntersection";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation, Trans } from "react-i18next";
import { Building, ConsentType, NotificationType } from "@/types";
import { useLoggedInUser, useRefetchUser } from "@/providers/AuthProvider";
import { useSnackbarStore } from "@/store/zustandStore";
import {
  WELCOME_HELP_URL,
  WELCOME_PRIVACY_POLICY_URL,
  WELCOME_TERMS_OF_USE_URL,
} from "@/lib/constants";
import { updateConsent, updateUser, useBuilding } from "@/api";

export default function Welcome() {
  const { t, i18n } = useTranslation();
  const consentsRef = useRef<HTMLDivElement>(null);
  const authUser = getAuthUser();
  const user = useLoggedInUser();
  const refetchUser = useRefetchUser();
  const { data: building } = useBuilding();
  const { addNotification } = useSnackbarStore();
  const isVisible = useIntersection(consentsRef, "0px");
  const [generalConsent, setGeneralConsent] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [loading, setLoading] = useState("");
  const [imageError, setImageError] = useState(false);
  const [logoError, setLogoError] = useState(false);

  const handleRejectConsents = () => {
    setGeneralConsent(false);
    setDeclineModal(true);
  };

  const handleSetConsents = async (quickAccept?: boolean) => {
    setDeclineModal(false);
    setLoading(quickAccept || generalConsent ? "accepting" : "declining");

    if (quickAccept) setGeneralConsent(true);

    try {
      await updateUser(authUser.uid, { userActive: true });
      await updateConsent(authUser.uid, [
        { type: ConsentType.GENERAL, consented: quickAccept || generalConsent },
      ]);
      refetchUser();
      addNotification(
        "Account has been activated. Welcome!",
        NotificationType.SUCCESS,
      );
    } catch (e) {
      console.log(e);
      addNotification("Something went wrong! Try again later.");
    } finally {
      setLoading("");
    }
  };

  return (
    <>
      {building.buildingBannerImgUrl && (
        <div className={styles.bannerContainer} aria-hidden>
          <div className={styles.bannerInnerContainer}>
            <img src={building.buildingBannerImgUrl} alt="Building banner" />
          </div>
        </div>
      )}
      <div className={styles.langContainer}>
        <Button
          dark
          onClick={async () => {
            const language = i18n.language === "no" ? "en" : "no";
            updateUser(authUser.uid, { language });
            i18n.changeLanguage(language);
          }}
          icon="globe"
        >
          {t("SIDEBAR.SWITCH_TO", {
            language: i18n.language === "no" ? "English" : "Norsk",
          })}
        </Button>
      </div>
      <div className={styles.content}>
        <div className={styles.relativeContainer}>
          <h1>
            <Trans i18nKey="PAGES.WELCOME.HEADER">
              Welcome to
              <span>
                <>{{ buildingName: building.displayName }}</>
              </span>
              !
            </Trans>
          </h1>
          <div className={styles.intro}>
            {imageError ? (
              <div>
                <div className={styles.imgFallback}>
                  <Icon name="person" />
                </div>
              </div>
            ) : (
              <img
                src={building.propertyManager.managerProfileImgUrl}
                alt={`${building.propertyManager.firstName} ${building.propertyManager.lastName} profile`}
                height="150"
                width="150"
                onError={(e) => {
                  setImageError(true);
                  console.log(e);
                }}
              />
            )}
            <div>
              <Trans
                i18nKey="PAGES.WELCOME.GREETING"
                values={{
                  userName: user.firstName,
                  buildingAdminName: `${building.propertyManager.firstName} ${building.propertyManager.lastName}`,
                  buildingAdminPositionLC:
                    building.propertyManager.position[
                      i18n.language as keyof Building["propertyManager"]["position"]
                    ].toLowerCase(),
                  buildingName: building.displayName,
                  ownerName: building.owner,
                  buildingAdminPosition:
                    building.propertyManager.position[
                      i18n.language as keyof Building["propertyManager"]["position"]
                    ],
                  signature: `${Array.from(building.propertyManager.firstName)[0]}. ${building.propertyManager.lastName}`,
                }}
                components={{
                  paragraph: <p></p>,
                  bold: <strong></strong>,
                  linebreak: <br />,
                  regards: <div className={styles.regards}></div>,
                  signature: (
                    <p className={`no-select ${styles.signature}`}></p>
                  ),
                }}
              ></Trans>
            </div>
          </div>
          <div ref={consentsRef} className={styles.consents}>
            <h2>{t("PAGES.WELCOME.CONSENTS.HEADER")}</h2>
            <div className={styles.consent}>
              <Checkbox
                checked={generalConsent}
                onChange={setGeneralConsent}
                disabled={!!loading}
                dark
                label={
                  <div className={styles.consentLabel}>
                    <Trans
                      i18nKey="PAGES.WELCOME.CONSENTS.GENERAL"
                      components={{
                        bold: <strong></strong>,
                        paragraph: <p></p>,
                      }}
                    ></Trans>
                  </div>
                }
              />
            </div>
            <p className={styles.disclaimerText}>
              <Trans
                i18nKey="PAGES.WELCOME.CONSENTS.DISCLAIMER"
                components={{
                  terms: (
                    <a
                      href={WELCOME_TERMS_OF_USE_URL(i18n.language)}
                      target="_blank"
                    ></a>
                  ),
                  policy: (
                    <a
                      href={WELCOME_PRIVACY_POLICY_URL(i18n.language)}
                      target="_blank"
                    ></a>
                  ),
                }}
              ></Trans>
            </p>
          </div>
          <div className={styles.declineButtonContainer}>
            <Button
              dark
              onClick={handleRejectConsents}
              isLoading={loading === "declining"}
              disabled={!!loading}
            >
              {t("PAGES.WELCOME.DECLINE")}
            </Button>
          </div>
          <AnimatePresence>
            {isVisible && (
              <motion.div
                className={styles.acceptButtonContainer}
                initial={{ y: 30, opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: { duration: 0.3, delay: 1 },
                }}
                exit={{
                  y: 30,
                  opacity: 0,
                  transition: { duration: 0.3, delay: 0 },
                }}
              >
                <Button
                  large
                  onClick={() => handleSetConsents(false)}
                  isLoading={loading === "accepting"}
                  disabled={!!loading || !generalConsent}
                >
                  {t("PAGES.WELCOME.ACCEPT")}
                  <Icon name="arrow_forward" />
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
          <div className={styles.logos}>
            <img src={whiteLogoFull} alt="Welcome Workdays logo" height="30" />
            {!logoError && (
              <>
                <p className="no-select" aria-hidden>
                  +
                </p>
                <img
                  className={styles.buildingOwnerLogo}
                  src={building.ownerLogoImgUrl}
                  alt={`${building.owner} logo`}
                  onError={(e) => {
                    setLogoError(true);
                    console.log(e);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {declineModal && !loading && (
        <PopupCard onClose={() => setDeclineModal(false)}>
          <h3>{t("PAGES.WELCOME.MODAL.HEADER")}</h3>
          <p className={styles.modalText}>
            {t("PAGES.WELCOME.MODAL.BODY")},{" "}
            <Trans
              i18nKey="PAGES.WELCOME.YOU_LOSE"
              components={{
                help: (
                  <a
                    href={`${WELCOME_HELP_URL}${i18n.language}`}
                    target="_blank"
                  ></a>
                ),
              }}
            ></Trans>
          </p>
          <div className={styles.modalButtons}>
            <Button danger onClick={() => handleSetConsents()}>
              {t("PAGES.WELCOME.MODAL.DECLINE")}
            </Button>
            <Button onClick={() => handleSetConsents(true)}>
              {t("PAGES.WELCOME.ACCEPT")}
            </Button>
          </div>
        </PopupCard>
      )}
    </>
  );
}
