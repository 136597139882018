import whiteLogo from "@/assets/whiteLogo.svg";
import { Button } from "@app-components";
import { auth } from "@/lib/firebase";
import styles from "@/styles/Auth.module.scss";
import { useTranslation } from "react-i18next";

const signOut = () => {
  auth.signOut();
  const baseUrl = window.location.origin + window.location.pathname;
  window.location.replace(baseUrl);
};

export const SignInError = () => {
  const { t } = useTranslation();

  return (
    <div className={`center ${styles.loadingScreen}`}>
      <img width={75} height={75} src={whiteLogo} alt="Welcome Workdays" />
      <h1>
        {t("SIGN_IN_ERROR.HEADER")}
      </h1>
      <p>{t("SIGN_IN_ERROR.BODY")}</p>
      <Button secondary onClick={signOut}>
        {t("SIDEBAR.LOG_OUT")}
      </Button>
    </div>
  );
};

