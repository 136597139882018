import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import client, { throwCaughtError } from "../client";
import { AddUserType, ConsentType, User } from "@/types";
import { downloadFile, env } from "@/lib/utils";

export const useUserOptions = (userId: string) => ({
  queryKey: ["user", userId],
  queryFn: async () => {
    try {
      const response = await client.get(
        `/getUser?field=userId&value=${userId}`,
      );
      return response.data.user;
    } catch (error) {
      throwCaughtError(error);
    }
  },
});

export const useUser = (userId: string): UseSuspenseQueryResult<User> => {
  return useSuspenseQuery(useUserOptions(userId));
};

export const checkUserLogin = async (email: string) => {
  const response = await fetch(
    `${env("VITE_API_BASE")}/api/getUserLogin?email=${email}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
  return response;
};

export const companyUsersOptions = (buildingTenantId?: string) => ({
  queryKey: ["users", buildingTenantId],
  queryFn: async () => {
    try {
      if (!buildingTenantId) throw new Error("buildingTenantId is required");
      const response = await client.get(
        `/getUsersByBuildingTenantId?buildingTenantId=${buildingTenantId}`,
      );
      return response.data.users;
    } catch (error) {
      throwCaughtError(error);
    }
  },
});

export const useCompanyUsers = (
  buildingTenantId?: string,
): UseSuspenseQueryResult<User[]> => {
  return useSuspenseQuery(companyUsersOptions(buildingTenantId));
};

export const getExportedCompanyUsers = async (
  companyId: string,
  companyName?: string,
): Promise<File | undefined> => {
  try {
    const response = await client.get(
      `/exportUsersCsv?buildingTenantId=${companyId}`,
      { responseType: "blob" },
    );
    const blob = new Blob([response.data], { type: "text/csv" });
    const filename = `${(companyName || companyId).split(" ").join("_")}_users_export.csv`;
    downloadFile(blob, filename);
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const getExportedBuildingUsers = async (
  buildingName: string | undefined,
): Promise<File | undefined> => {
  try {
    const response = await client.get("/exportAllUsersCsv", {
      responseType: "blob",
    });
    const blob = new Blob([response.data], { type: "text/csv" });
    const filename = buildingName
      ? `${buildingName.split(" ").join("_")}_all_users_export.csv`
      : "all_users_export.csv";
    downloadFile(blob, filename);
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const getUser = async (
  field?: string,
  value?: string,
  companyId?: string,
): Promise<User | undefined> => {
  try {
    const response = await client.get(
      `/getUser?field=${field}&value=${value}` +
        (companyId ? `&companyId=${companyId}` : ""),
    );
    return response.data.user;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const createUsers = async (
  users: AddUserType[],
  tenantAdminName: string,
  tenantName: string,
  buildingName: string,
  visitorRegistrationEnabled: boolean,
) => {
  try {
    const response = await client.post("/createUser", {
      users,
      tenantAdminName,
      tenantName,
      buildingName,
      visitorRegistrationEnabled,
    });
    return response;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const updateUser = async (userId: string, updateData: Partial<User>) => {
  try {
    const response = await client.patch("/updateUser", {
      userId,
      updateData,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throwCaughtError(error);
  }
};

export const updateConsent = async (
  userId: string,
  consents: { type: ConsentType; consented: boolean }[],
) => {
  try {
    const response = await client.patch("/updateConsent", {
      userId,
      consents,
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const deleteUser = async (userId: string, email: string) => {
  try {
    const response = await client.delete("/deleteUser", {
      data: { userId, email },
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const sendMagicLink = async (email: string, language: string) => {
  const body = JSON.stringify({ userEmail: email, language });
  try {
    const response = await fetch(
      `${env("VITE_API_BASE")}/api/sendMagicLinkEmail`,
      {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
    return response;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const logoutFromKeycloak = async (email: string) => {
  try {
    const response = await client.post("/logoutFromKeycloak", { email });
    return response;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const checkExistingUser = async (email: string) => {
  const encodedEmail = encodeURIComponent(email);
  try {
    const response = await client.get(
      `/checkIfUserExists?email=${encodedEmail}`,
    );
    return response.data.userExist;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const checkExistingEmails = async (newEmails: string[]) => {
  try {
    const response = await client.post("/checkExistingEmails", { newEmails });
    return response.data.repeatingEmails;
  } catch (error) {
    throwCaughtError(error);
  }
};
