import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { env } from "@/lib/utils";
import { APP_DOMAIN } from "@/lib/constants";

const config = {
  apiKey: env("VITE_FB_API_KEY"),
  authDomain: APP_DOMAIN().slice(8),
  projectId: env("VITE_FB_PROJECT"),
  storageBucket: env("VITE_FB_STORAGE_BUCKET"),
  messagingSenderId: env("VITE_FB_MESSAGING_SENDER_ID"),
  appId: env("VITE_FB_WEB_APP_ID"),
};

const app = initializeApp(config);
const auth = getAuth(app);
const analytics = getAnalytics(app);

type eventNames = "login_with_magic_link" | "login_with_sso";

const logAnalyticsEvent = (eventName: eventNames) => {
  logEvent(analytics, eventName);
};

const getAuthUser = () => {
  if (!auth.currentUser) throw new Error("User not logged in");
  return auth.currentUser;
};

export { auth, getAuthUser, logAnalyticsEvent };
