import {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  KeyboardEvent,
  FocusEvent as ReactFocusEvent,
} from "react";
import { Icon } from "../Icon";
import { styles } from "../InputField";
import { StatusText } from "../StatusText";

type TimeString = string & { __brand: "TimeString" };

const isTimeString = (value: string): value is TimeString => {
  const dateRegex = /^\d{2}:\d{2}$/;
  return dateRegex.test(value);
};

const dateToTimeInputString = (date: Date): TimeString => {
  return date.toLocaleTimeString("nb-NO", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }) as TimeString;
};

const getClosestFullStep = (value: string, step: number): TimeString => {
  const stepInMinutes = step / 60;
  const [valHours, valMins] = value.split(":").map((str) => Number(str));
  const valueAsDate = new Date(
    new Date(new Date().setHours(valHours)).setMinutes(valMins),
  );
  const roundedStepValue = Math.round(valMins / stepInMinutes) * stepInMinutes;
  const roundedMinutes = roundedStepValue > 60 ? 60 : roundedStepValue; // If the step exceeds 60 minutes, set it to 60
  const closestStep = new Date(valueAsDate.setMinutes(roundedMinutes, 0, 0));
  return dateToTimeInputString(closestStep);
};

const timeIsSmallerThanMin = (value: TimeString, min: TimeString): boolean => {
  const [valHours, valMins] = value.split(":").map((str) => Number(str));
  const [minHours, minMins] = min.split(":").map((str) => Number(str));
  return valHours < minHours || (valHours === minHours && valMins < minMins);
};

const timeIsLargerThanMax = (value: TimeString, max: TimeString): boolean => {
  const [valHours, valMins] = value.split(":").map((str) => Number(str));
  const [maxHours, maxMins] = max.split(":").map((str) => Number(str));
  return valHours > maxHours || (valHours === maxHours && valMins > maxMins);
};

export interface TimeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode;
  hideLabel?: boolean;
  value?: TimeString;
  className?: string;
  /**
   * A time string in the format "HH:mm".
   * Example: "09:00"
   */
  minTime?: TimeString;
  /**
   * A time string in the format "HH:mm".
   * Example: "09:00"
   */
  maxTime?: TimeString;
  /**
   * A number specifying the granularity of the options in seconds
   * Max: 3600 (one hour)
   */
  step?: number;
  icon?: string;
  placeholder?: string;
  disabled?: boolean;
  focus?: boolean;
  autoFocus?: boolean;
  large?: boolean;
  danger?: boolean;
  success?: boolean;
  dark?: boolean;
  required?: boolean;
  compact?: boolean;
  inputBgColor?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: ReactFocusEvent<HTMLInputElement, Element>) => void;
  onFocus?: (e: ReactFocusEvent<HTMLInputElement, Element>) => void;
  dangerText?: string;
  successText?: string;
  infoText?: string;
}

export const TimeInput = ({
  id,
  label,
  hideLabel,
  value,
  className,
  minTime,
  maxTime,
  step,
  icon = "schedule",
  placeholder,
  disabled,
  focus,
  autoFocus,
  large,
  danger,
  success,
  dark,
  required,
  compact,
  inputBgColor,
  onChange,
  onKeyDown,
  onKeyUp,
  onBlur,
  onFocus,
  dangerText,
  successText,
  infoText,
  ...rest
}: TimeInputProps) => {
  if (step && step > 3600) {
    throw new Error("step cannot be greater than 3600 (one hour)");
  }

  if (minTime && !isTimeString(minTime)) {
    throw new Error(
      "minTime must be empty or a time string in the format 'HH:mm'",
    );
  }
  if (maxTime && !isTimeString(maxTime)) {
    throw new Error(
      "maxTime must be empty or a time string in the format 'HH:mm'",
    );
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      let val = e.target.value;

      if (minTime && timeIsSmallerThanMin(val as TimeString, minTime)) {
        val = minTime;
      } else if (maxTime && timeIsLargerThanMax(val as TimeString, maxTime)) {
        val = maxTime;
      }

      if (step) {
        val = getClosestFullStep(val, step);
      }

      onChange({ ...e, target: { ...e.target, value: val } });
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) onKeyDown(e);
  };

  const handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) onKeyUp(e);
  };

  const handleOnBlur = (e: ReactFocusEvent<HTMLInputElement, Element>) => {
    if (onBlur) onBlur(e);
  };

  const handleOnFocus = (e: ReactFocusEvent<HTMLInputElement, Element>) => {
    if (onFocus) onFocus(e);
  };

  return (
    <div
      className={`
    ${styles.inputContainer} 
   
    ${dark ? styles.dark : ""}`}
      {...rest}
    >
      {!!label && (
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "end",
            justifyContent: "flex-start",
          }}
        >
          <label
            htmlFor={`${label}_INPUT`}
            className={hideLabel || (compact && hideLabel) ? "sr-only" : ""}
          >
            {label}
            {required && typeof label === "string" && (
              <>
                {" "}
                <strong aria-hidden>*</strong>
                <span className="sr-only">(required)</span>
              </>
            )}
          </label>
        </div>
      )}
      <div
        className={`
        ${className ? className : ""} 
        ${styles.inputField} 
        ${focus ? styles.focus : ""}
        ${large ? styles.large : ""}
        ${danger || (dangerText && dangerText.length > 0) ? styles.danger : ""}
        ${
          success || (successText && successText.length > 0)
            ? styles.success
            : ""
        }
        ${dark ? styles.dark : ""}
        ${disabled ? styles.disabled : ""}`}
        {...(inputBgColor ? { style: { backgroundColor: inputBgColor } } : {})}
      >
        {icon && <Icon className={styles.icon} name={icon} />}
        <input
          id={id || `${label}_INPUT`}
          autoFocus={autoFocus}
          className={styles.input}
          type="time"
          placeholder={placeholder}
          disabled={disabled}
          min={minTime}
          max={maxTime}
          step={step}
          required={required}
          value={value}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onKeyUp={handleOnKeyUp}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />
      </div>
      <div className={styles.bottomContainer}>
        {!dangerText && !successText && !infoText && !compact ? (
          <div style={{ height: "24px" }}></div>
        ) : dangerText ? (
          <StatusText text={dangerText} fail dark={dark} />
        ) : successText ? (
          <StatusText text={successText} success dark={dark} />
        ) : (
          !!infoText && <StatusText text={infoText} dark={dark} />
        )}
      </div>
    </div>
  );
};
