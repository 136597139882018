import { NotificationType, SSOItem } from "@/types";
import {
  Button,
  Dropdown,
  InputField,
  PopupCard,
  SelectRow,
} from "@app-components";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "@/styles/Companies.module.scss";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import {
  COMPANY_REGEX,
  EMAIL_REGEX,
  NAME_REGEX,
  PHONE_REGEX,
  WELCOME_LOGIN_METHOD_HELP_URL,
} from "@/lib/constants";
import { AxiosError } from "axios";
import { z } from "zod";
import SSOInput from "@/components/SSOInput";
import { useSnackbarStore } from "@/store/zustandStore";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "@/i18n/config";
import { useUploadUsers } from "@/hooks/uploadUsers";
import {
  createCompanyInBuilding,
  getCompaniesFromBronnoysund,
  getUser,
  updateUser,
  useBuilding,
} from "@/api";
import { useLoggedInUser, useRefetchUser } from "@/providers/AuthProvider";

export default function AddCompanyModal({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const currentUser = useLoggedInUser();
  const refetchUser = useRefetchUser();
  const { data: building } = useBuilding();
  const { addNotification } = useSnackbarStore();
  const { submitUsers } = useUploadUsers(onClose);
  const [manualCompanyName, setManualCompanyName] = useState(false);

  const formAwareOnClose = () => {
    if (form.state.isSubmitting) return;
    onClose();
  };

  const getFormattedCompanyOptions = async (query: string) => {
    const result = await getCompaniesFromBronnoysund(query);
    return result?.map((res) => ({
      value: res.organisasjonsnummer,
      label: res.navn
        .split(" ")
        .map((word) =>
          [
            "AS",
            "ANS",
            "KS",
            "ASA",
            "SA",
            "ST",
            "ENK",
            "EPF",
            "DA",
            "FO",
            "FF",
            "SE",
            "AB",
          ].includes(word)
            ? word
            : word[0].toUpperCase() + word.substring(1).toLowerCase(),
        )
        .join(" "),
    }));
  };

  const form = useForm({
    defaultValues: {
      foundCompany: undefined as undefined | { value: string; label: string },
      sso: true,
      companyData: {
        buildingTenantName: "",
        orgNumber: "",
        ssoSettings: [] as SSOItem[],
      },
      adminData: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
    },
    onSubmit: async ({ value }) => {
      if (!building) {
        addNotification("The current building was not found");
        return;
      }

      try {
        // CREATE COMPANY
        const companyData = {
          ...value.companyData,
          ...(!manualCompanyName && value.foundCompany
            ? {
                buildingTenantName: value.foundCompany.label,
                orgNumber: value.foundCompany.value,
              }
            : {}),
        };
        const createdCompanyId = await createCompanyInBuilding(companyData);
        if (!createdCompanyId)
          throw new Error("Create function returned undefined ID");

        // ADD CURRENT BUILDING ADMIN AS COMPANY ADMIN
        try {
          await updateUser(currentUser.userId, {
            adminInCompanies: [
              ...currentUser.adminInCompanies,
              createdCompanyId,
            ],
          });
        } catch (e) {
          console.error(e);
          addNotification("Failed to add you as administrator in new company.");
        }

        // CHECK IF TARGET ADMIN USER ALREADY EXISTS
        try {
          const existingUser = await getUser("email", value.adminData.email);
          if (!existingUser) throw new Error("No user found");
          await updateUser(existingUser.userId, {
            adminInCompanies: [
              ...existingUser.adminInCompanies,
              createdCompanyId,
            ],
            companyContact: true,
          });
          const googleSso = value.companyData.ssoSettings.filter(
            (value) => value.provider === "Google",
          );
          if (googleSso.length > 0) {
            addNotification(
              t("ADD_COMPANY.SUCCESS_WITH_GOOGLE_SSO_HELP"),
              NotificationType.SUCCESS,
            );
          } else {
            addNotification(t("ADD_COMPANY.SUCCESS"), NotificationType.SUCCESS);
          }
        } catch (e) {
          // USER DOES NOT EXIST - CREATE USER
          console.error(e);

          try {
            await submitUsers(
              [
                {
                  ...value.adminData,
                  adminInCompanies: [createdCompanyId],
                  buildingTenantId: createdCompanyId,
                  visibleInVisit: true,
                  companyContact: true,
                  sendEmail: true, //Send email to new user by default
                },
              ],
              value.companyData.buildingTenantName,
            );
            addNotification(
              "Company successfully invited",
              NotificationType.SUCCESS,
            );
            return;
          } catch (e) {
            console.error(e);
          }

          addNotification(
            "Company was created but the administrator could not be invited.",
            NotificationType.WARNING,
          );
        } finally {
          onClose();
          refetchUser();
          queryClient.invalidateQueries({ queryKey: ["extended-companies"] });
        }
      } catch (e) {
        const error = e as unknown as AxiosError<{
          code: string;
          message: string;
        }>;
        const message = error?.response?.data.message;
        if (message) {
          addNotification(`Failed to invite company. ${message}`);
        } else {
          addNotification(`Failed to invite company. Please try again later!`);
        }
      }
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <PopupCard onClose={formAwareOnClose}>
      <h3>
        {t("ADD_COMPANY.HEADER", { buildingName: building?.displayName })}
      </h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        className={styles.addCompanyContainer}
      >
        <div className={styles.fullWidth}>
          {manualCompanyName ? (
            <form.Field
              key="MANUAL_COMPANY"
              name="companyData.buildingTenantName"
              validators={{
                onBlur: z
                  .string()
                  .min(
                    1,
                    t("VALIDATION.REQUIRED", {
                      field: t("COMPANY_SETTINGS.COMPANY_NAME"),
                    }),
                  )
                  .min(
                    2,
                    t("VALIDATION.MIN_LENGTH", {
                      field: t("COMPANY_SETTINGS.COMPANY_NAME"),
                      length: 2,
                      type: t("CHARACTERS"),
                    }),
                  )
                  .max(
                    40,
                    t("VALIDATION.MAX_LENGTH", {
                      field: t("COMPANY_SETTINGS.COMPANY_NAME"),
                      length: "40",
                      type: t("CHARACTERS"),
                    }),
                  )
                  .trim()
                  .refine((value) => COMPANY_REGEX.test(value), {
                    message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                  }),
              }}
            >
              {(field) => (
                <InputField
                  dark
                  label={t("COMPANY_SETTINGS.COMPANY_NAME")}
                  appendLabel={
                    <button
                      type="button"
                      className={styles.labelButton}
                      onClick={() => setManualCompanyName(false)}
                    >
                      {t("ADD_COMPANY.COMPANY_SEARCH_BUTTON")}
                    </button>
                  }
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                  dangerText={field.state.meta.errors.toString()}
                />
              )}
            </form.Field>
          ) : (
            <form.Field
              key="SEARCH_COMPANY"
              name="foundCompany"
              validators={{
                onChange: z.any().refine(
                  (value) => typeof value === "object" && value !== null,
                  t("VALIDATION.REQUIRED", {
                    field: t("COMPANY_SETTINGS.COMPANY_NAME"),
                  }),
                ),
                onBlur: z.any().refine(
                  (value) => typeof value === "object" && value !== null,
                  t("VALIDATION.REQUIRED", {
                    field: t("COMPANY_SETTINGS.COMPANY_NAME"),
                  }),
                ),
              }}
            >
              {(field) => (
                <Dropdown
                  dark
                  clearable
                  icon="search"
                  label={t("ADD_COMPANY.COMPANY_SEARCH.LABEL")}
                  appendLabel={
                    <button
                      className={styles.labelButton}
                      onClick={() => setManualCompanyName(true)}
                    >
                      {t("ADD_COMPANY.COMPANY_MANUAL_BUTTON")}
                    </button>
                  }
                  placeholder={t("ADD_COMPANY.COMPANY_SEARCH.PLACEHOLDER")}
                  value={field.state.value}
                  onChange={field.handleChange}
                  fetchOptions={getFormattedCompanyOptions}
                  onFetchFailed={() =>
                    addNotification(
                      "Failed to fetch companies. Try again later.",
                      NotificationType.ERROR,
                    )
                  }
                  startSearchText={t("DEFAULT_SELECT.START_SEARCH")}
                  searchingText={t("DEFAULT_SELECT.SEARCHING")}
                  noResultsText={t("DEFAULT_SELECT.NO_RESULTS")}
                  infoText={
                    field.state.value
                      ? `Org.nr.: ${field.state.value?.value || ""}`
                      : ""
                  }
                  dangerText={field.state.meta.errors.toString()}
                />
              )}
            </form.Field>
          )}
        </div>
        <div className={styles.fullWidth}>
          <form.Field name="sso">
            {(field) => (
              <>
                <p style={{ marginBottom: "var(--wlcm-spacing-xs)" }}>
                  <strong>{t("ADD_COMPANY.LOGIN_METHOD.LABEL")}</strong>
                </p>
                <SelectRow
                  dark
                  hideLabel
                  name="LOGIN_METHOD"
                  label={t("ADD_COMPANY.LOGIN_METHOD.LABEL")}
                  value={field.state.value}
                  onChange={(newVal) => {
                    field.handleChange(newVal as boolean);
                    form.validateField("companyData.ssoSettings", "submit");
                    if (form.getFieldValue("adminData.email"))
                      form.validateField("adminData.email", "blur");
                  }}
                  options={[
                    { value: true, label: "SSO" },
                    {
                      value: false,
                      label: t("ADD_COMPANY.LOGIN_METHOD.EMAIL"),
                    },
                  ]}
                />
                <Trans
                  i18nKey="ADD_COMPANY.LOGIN_METHOD.DESCRIPTION"
                  components={{
                    ul: (
                      <ul style={{ marginTop: "var(--wlcm-spacing-xs)" }}></ul>
                    ),
                    li: (
                      <li
                        style={{
                          color: "var(--wlcm-color-darker-grey)",
                        }}
                      ></li>
                    ),
                    blue: (
                      <span
                        style={{
                          color: "var(--wlcm-color-superbrightgreyorblue)",
                        }}
                      ></span>
                    ),
                  }}
                />
                <a
                  href={WELCOME_LOGIN_METHOD_HELP_URL(
                    i18n.language === "en" ? "en" : "no",
                  )}
                  target="_blank"
                  style={{ color: "var(--wlcm-color-blue)" }}
                >
                  {t("ADD_COMPANY.LOGIN_METHOD.LEARN_MORE")}
                </a>
              </>
            )}
          </form.Field>
        </div>
        <form.Subscribe
          selector={(state) => state.values.sso}
          children={(showSettings) =>
            showSettings && (
              <form.Field
                name="companyData.ssoSettings"
                validators={{
                  onChangeListenTo: ["sso"],
                  onSubmit: z
                    .any()
                    .refine(
                      (domains) => {
                        const sso = form.getFieldValue("sso");
                        if (sso && domains.length === 0) {
                          return false;
                        }
                        return true;
                      },
                      {
                        message: t("ADD_COMPANY.DOMAINS.REQUIRED"),
                      },
                    )
                    .refine(
                      (domains) => {
                        const sso = form.getFieldValue("sso");
                        if (sso) {
                          if (domains.some((dom: SSOItem) => !dom.domain)) {
                            return false;
                          }
                          if (
                            domains.length !==
                            new Set(domains.map((dom: SSOItem) => dom.domain))
                              .size
                          ) {
                            return false;
                          }
                        }
                        return true;
                      },
                      {
                        message: t("ADD_COMPANY.DOMAINS.INVALID"),
                      },
                    ),
                }}
              >
                {(field) => (
                  <SSOInput
                    className={styles.fullWidth}
                    value={field.state.value}
                    onChange={(newVal) => {
                      field.handleChange(newVal);
                      if (form.getFieldValue("adminData.email"))
                        form.validateField("adminData.email", "blur");
                    }}
                    externalError={field.state.meta.errors.toString()}
                  />
                )}
              </form.Field>
            )
          }
        />
        <fieldset>
          <legend>{t("ADD_COMPANY.ADMIN.HEADER")}</legend>
          <p>{t("ADD_COMPANY.ADMIN.BODY")}</p>
          <form.Field
            name="adminData.firstName"
            validators={{
              onBlur: z
                .string()
                .min(
                  1,
                  t("VALIDATION.REQUIRED", { field: t("ADD_USER.FIRST_NAME") }),
                )
                .min(
                  2,
                  t("VALIDATION.MIN_LENGTH", {
                    field: t("ADD_USER.FIRST_NAME"),
                    length: 2,
                    type: t("CHARACTERS"),
                  }),
                )
                .max(
                  30,
                  t("VALIDATION.MAX_LENGTH", {
                    field: t("ADD_USER.FIRST_NAME"),
                    length: "30",
                    type: t("CHARACTERS"),
                  }),
                )
                .trim()
                .refine((value) => NAME_REGEX.test(value), {
                  message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                }),
            }}
          >
            {(field) => (
              <InputField
                required
                dark
                label={t("ADD_USER.FIRST_NAME")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <form.Field
            name="adminData.lastName"
            validators={{
              onBlur: z
                .string()
                .min(
                  1,
                  t("VALIDATION.REQUIRED", { field: t("ADD_USER.LAST_NAME") }),
                )
                .min(
                  2,
                  t("VALIDATION.MIN_LENGTH", {
                    field: t("ADD_USER.LAST_NAME"),
                    length: 2,
                    type: t("CHARACTERS"),
                  }),
                )
                .max(
                  30,
                  t("VALIDATION.MAX_LENGTH", {
                    field: t("ADD_USER.LAST_NAME"),
                    length: "30",
                    type: t("CHARACTERS"),
                  }),
                )
                .trim()
                .refine((value) => NAME_REGEX.test(value), {
                  message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                }),
            }}
          >
            {(field) => (
              <InputField
                required
                dark
                label={t("ADD_USER.LAST_NAME")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <form.Field
            name="adminData.email"
            validators={{
              onChangeListenTo: ["sso", "companyData.ssoSettings"],
              // ZOD wasn't playing nice with onBlur function, and we need access to fieldApi, so had to use regular if's for this field
              onBlur: ({ value }) => {
                if (!value) {
                  return t("VALIDATION.REQUIRED", {
                    field: t("ADD_USER.EMAIL"),
                  });
                }

                if (!EMAIL_REGEX.test(value)) {
                  return t("VALIDATION.INVALID", {
                    field: t("ADD_USER.EMAIL"),
                  });
                }
                return undefined;
              },
            }}
          >
            {(field) => (
              <InputField
                required
                dark
                label={t("ADD_USER.EMAIL")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <form.Field
            name="adminData.phone"
            validators={{
              onBlur: z
                .string()
                .trim()
                .refine(
                  (value) => !value || (value && PHONE_REGEX.test(value)),
                  {
                    message: t("VALIDATION.INVALID_PHONE"),
                  },
                ),
            }}
          >
            {(field) => (
              <InputField
                dark
                type="tel"
                label={t("ADD_USER.PHONE")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onFocus={() => {
                  if (!field.state.value) field.handleChange("+47");
                }}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
        </fieldset>
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button
              type="submit"
              disabled={isSubmitting || !canSubmit}
              isLoading={isSubmitting}
            >
              {t("ADD_COMPANY.SUBMIT")}
            </Button>
          )}
        />
      </form>
    </PopupCard>
  );
}
