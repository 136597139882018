import { MEETING_ROOM_FEATURES } from "@/lib/constants";
import styles from "@/styles/Bookings.module.scss";
import { MeetingRoom, MeetingRoomFeatures } from "@/types";
import { Button, Icon } from "@app-components";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

interface MeetingRoomCardProps {
  room: MeetingRoom;
  onBookRoom?: (room: MeetingRoom) => void;
  onCancelBooking?: () => void;
}

export default function MeetingRoomCard({
  room,
  onBookRoom,
  onCancelBooking,
}: MeetingRoomCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleFeatures, setVisibleFeatures] = useState<MeetingRoomFeatures>(
    [],
  );
  const [overflowedFeatures, setOverflowedFeatures] =
    useState<MeetingRoomFeatures>([]);

  useEffect(() => {
    const updateVisibleFeatures = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const itemWidth = 40; // Width of each item
        const gap = 10; // Gap between items
        const totalItemWidth = itemWidth + gap;

        // Calculate space needed for all items plus the "+N" indicator
        const maxVisibleFeatures =
          Math.floor((containerWidth + gap) / totalItemWidth) - 1; // Reserve space for the "+N"
        const visibleCount = Math.max(
          0,
          Math.min(room.features.length, maxVisibleFeatures),
        );
        const overflow = room.features.length - visibleCount;

        if (overflow > 1) {
          setVisibleFeatures(room.features.slice(0, visibleCount));
          setOverflowedFeatures(room.features.slice(visibleCount));
        } else {
          setVisibleFeatures(room.features);
          setOverflowedFeatures([]);
        }
      }
    };

    updateVisibleFeatures();
    window.addEventListener("resize", updateVisibleFeatures);

    return () => {
      window.removeEventListener("resize", updateVisibleFeatures);
    };
  }, [room.features]);

  const overflowedFeaturesAsText = overflowedFeatures
    .map((feat) =>
      t(`BOOKINGS.ROOM_FEATURES.${MEETING_ROOM_FEATURES[feat].label}`),
    )
    .join(", ");

  return (
    <article
      className={styles.cardContainer}
      onClick={() =>
        navigate({
          to: "/bookings/room/$roomId",
          params: { roomId: room.id },
        })
      }
    >
      <div className={styles.cardInfoContainer}>
        <h3>{room.name}</h3>
        <div>
          <p>
            <Icon name="location_on" /> {room.floor}. {t("FLOOR").toLowerCase()}
          </p>
          <p>
            <Icon name="group" /> {room.capacity}{" "}
            {t("PEOPLE", { count: room.capacity }).toLowerCase()}
          </p>
          {/* UNCOMMENT WHEN PRICE FUNCTIONALITY IS ADDED */}
          {/* {room.hourly_price && (
            <p>
              <Icon name="money" /> {room.hourly_price},- kr /{" "}
              {t("HOURS", { count: 1 }).toLowerCase()}
            </p>
          )} */}
        </div>
        {room.features && (
          <div ref={containerRef} className={styles.featuresContainer}>
            <h4 className="sr-only">Features</h4>
            {visibleFeatures.map((feature) => (
              <div style={{ display: "contents" }} key={feature}>
                <p
                  className={styles.featureChip}
                  id={`room-${room.id}-feat-${feature}`}
                >
                  <Icon name={MEETING_ROOM_FEATURES[feature]?.icon} />
                  <span className="sr-only">
                    {t(
                      `BOOKINGS.ROOM_FEATURES.${MEETING_ROOM_FEATURES[feature]?.label}`,
                    )}
                  </span>
                </p>
                <Tooltip
                  anchorSelect={`#room-${room.id}-feat-${feature}`}
                  content={t(
                    `BOOKINGS.ROOM_FEATURES.${MEETING_ROOM_FEATURES[feature]?.label}`,
                  )}
                />
              </div>
            ))}
            {overflowedFeatures.length > 0 && (
              <>
                <p className="sr-only">{overflowedFeaturesAsText}</p>
                <p
                  aria-hidden
                  id={`room-${room.id}-overflow`}
                  style={{
                    width: "40px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  +{overflowedFeatures.length}
                </p>
                <Tooltip
                  anchorSelect={`#room-${room.id}-overflow`}
                  content={overflowedFeaturesAsText}
                  style={{ maxWidth: 250 }}
                />
              </>
            )}
          </div>
        )}
        {onCancelBooking ? (
          <Button
            dark
            onClick={(e) => {
              e.stopPropagation();
              onCancelBooking();
            }}
          >
            {t("BOOKINGS.CANCEL")}
          </Button>
        ) : onBookRoom ? (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onBookRoom(room);
            }}
          >
            {t("BOOKINGS.BOOK")}
          </Button>
        ) : (
          <Button
            secondary
            onClick={(e) => {
              e.stopPropagation();
              navigate({
                to: "/bookings/room/$roomId",
                params: { roomId: room.id },
                hash: "availability",
              });
            }}
          >
            {t("BOOKINGS.CHECK_AVAILABILITY")}
          </Button>
        )}
      </div>
      <div aria-hidden className={styles.cardThumbnailContainer}>
        {room.thumbnail ? (
          <img src={room.thumbnail} alt={room.name} />
        ) : (
          <Icon name="meeting_room" />
        )}
      </div>
    </article>
  );
}
