import { ChangeEvent, HTMLAttributes, useState } from "react";
import { Icon } from "../Icon";
import "./../../globals.scss";
import styles from "./chip.module.scss";

interface ChipProps extends Omit<HTMLAttributes<HTMLSpanElement>, "onClick"> {
  label: string;
  active?: boolean;
  dark?: boolean;
  icon?: string;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>, id: string) => void;
  inputField?: boolean;
  inputFieldDefaultNumber?: number;
  inputFieldConnectedNumber?: number;
  onNumberInputChange?: (value: number) => void;
}

export function Chip({
  label,
  id,
  active,
  dark,
  icon,
  className,
  onClick,
  inputField,
  inputFieldDefaultNumber,
  inputFieldConnectedNumber,
  onNumberInputChange,
  ...props
}: ChipProps) {
  const [inputValue, setInputValue] = useState<number | string | undefined>(
    inputFieldDefaultNumber,
  );
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      setInputValue(newValue);
      if (onNumberInputChange) {
        onNumberInputChange(newValue);
      }
    } else if (event.target.value === "") {
      setInputValue("");
    }
  };

  if (onClick) {
    return (
      <span
        {...props}
        onClick={(e) => onClick(e, id as string)}
        className={`${styles.chip} ${dark ? styles.dark : ""} ${
          styles.toggle
        } ${active ? styles.active : ""} ${className ? className : ""}`}
      >
        {icon && <Icon className={styles.leftIcon} name={icon} />}
        <span>{label}</span>
        {inputField && (
          <div className={styles.numberInputFiledContainer}>
            {inputFieldConnectedNumber && (
              <span>{`${inputFieldConnectedNumber} / `}</span>
            )}
            <input
              className={styles.numberInputField}
              type="number"
              min={inputFieldConnectedNumber ? inputFieldConnectedNumber : 0}
              value={inputValue}
              step={1}
              onChange={(e) => handleOnChange(e)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        )}
        {active && <Icon className={styles.icon} name="close" />}
        {!active && (
          <Icon className={`${styles.icon} ${styles.add}`} name="add" />
        )}
      </span>
    );
  } else {
    return (
      <span
        {...props}
        className={`${styles.chip} ${dark ? styles.dark : ""} ${
          active ? styles.active : ""
        } ${className ? className : ""}`}
      >
        {icon && <Icon className={styles.leftIcon} name={icon} />}
        <span>{label}</span>
        {inputField && (
          <div className={styles.numberInputFiledContainer}>
            {(inputFieldConnectedNumber || inputFieldConnectedNumber === 0) && (
              <span>{`${inputFieldConnectedNumber} / `}</span>
            )}
            <input
              className={styles.numberInputField}
              type="number"
              min={inputFieldConnectedNumber ? inputFieldConnectedNumber : 1}
              value={inputValue}
              step={1}
              onChange={(e) => handleOnChange(e)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        )}
      </span>
    );
  }
}
